import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types'

const HomeHeader = ({homeHeaderText, homeHeaderSubtext, allText}) => {

    return (
        <Box sx={{width:'100%', display: "flex", textAlign: {xs: "flex-start", md: 'center'}, marginBottom: '24px', justifyContent: "center", flexDirection: "column"}}>
            <Typography 
                variant="h2" 
                color="title" 
                marginBottom="16px" 
                sx={{
                    fontWeight:'600', 
                    fontSize:{xs:'32px', md: "56px"},
                    // marginBlockStart: '0.83em',
                    // marginBlockEnd: '0.83em',
                    // marginInlineStart: '0px',
                    // marginInlineEnd: '0px'
            }} 
                dangerouslySetInnerHTML={{ __html: homeHeaderText }}
            >
                {/* {homeHeaderText} */}
            </Typography>
            <Typography 
                color="title"  
                sx={{fontWeight:'400', fontSize: "16px"}}
            >
                {homeHeaderSubtext}
            </Typography>
        </Box>
    )
}

HomeHeader.propTypes = {
    homeHeaderText : PropTypes.string,
    homeHeaderSubtext : PropTypes.string,
    allText : PropTypes.string
  };

export default HomeHeader;