import React, { useContext } from "react";
import AppContext from "../../AppContext";
import { useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import SurveyButton from "../../Components/SurveyButton/SurveyButton";


const GenericPage = (props) => {
  const { config , setModal , setModalOpen } = useContext(AppContext);

  let location = useLocation();

  const header = config?.pages
    ?.filter((y) => y.relative_path?.toLowerCase() === location.pathname?.toLowerCase())[0]
    ?.page_content_module?.filter((y) =>
      !!y.page_heading
    );
  const content = config?.pages
  ?.filter((y) => y.relative_path?.toLowerCase() === location.pathname?.toLowerCase())[0]
  ?.page_content_module?.filter((y) =>
    !!y.rte
  );

  const showSurvey = config?.pages?.filter((y) => y.relative_path?.toLowerCase() === location.pathname?.toLowerCase())[0]?.show_nps_cta;
  document.title = config?.pages?.filter((y) => y.relative_path?.toLowerCase() === location.pathname?.toLowerCase())[0]?.page_title || config?.pages?.filter((y) => y.relative_path?.toLowerCase() === location.pathname?.toLowerCase())[0]?.title || 'Page Title';

  return (
    <Box sx={{ backgroundColor: "rgb(246, 246, 246)" }}>
      <Container maxWidth="lg" sx={{ marginTop: { xs: "10%", md: "5%" } }}>
        <Grid container spacing={2} padding="16px">
          {header.length > 0 &&
            <Typography color="title" sx={{fontWeight: 600, fontSize: {xs:'24px', md:"32px"}}}>
              {header[0]?.page_heading?.text}
            </Typography>
          }
          {content.length > 0 &&
            <Typography 
              color="title" 
              sx={{fontWeight: 400, fontSize: {xs:'14px', md:"16px"}}}
              dangerouslySetInnerHTML={{
                __html: content[0]?.rte?.rich_text_editor,
              }}
            />
          }
        </Grid>
        {showSurvey && <SurveyButton setModal={setModal} setModalOpen={setModalOpen} config={config}/>}
      </Container>
    </Box>
  );
};

export default GenericPage;
