import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";
import PropTypes  from "prop-types";

const PopularDrugButton = ({
  popularDrugs,
  primaryColor,
  opacity,
  hoverOpacity,
  pressedOpacity,
  focusedOpacity,
  setSearchInput,
  loading
}) => {
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: { xs: "32px", md: "48px" },
        }}
      >
        <Typography
          sx={{
            position: "absolute",
            fontWeight: "600",
            fontSize: { xs: "16px", md: "24px" },
            alignItems: "center",
            fontStyle: "normal",
          }}
        >
          Popular Searches
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexFlow: "wrap",
          justifyContent: "flex-start",
          textAlign: "center",
        }}
      >
        {loading ? [1,2,3,4,5,6,7,8,9,10,11].map((x) => {
          return(
            <Box
              sx={{
                padding: "4px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
              key={x}
            >
              <Skeleton
                variant="rounded"
                animation="pulse"
                sx={{
                  borderRadius: "40px",
                  // padding: { xs: "4px 8px 4px 8px", md: "8px 16px 8px 16px" },
                  boxShadow: "none",
                  width: {xs: "90px", md: "120px"},
                  height: {xs: "30px", md: "40px"},
                  transition: "2s",
                  backgroundColor: "grey",
                }} />
            </Box>
          )
}) : (
          <Fragment>
            {popularDrugs?.map((x) => {
              return (
                <Box
                  sx={{
                    padding: "4px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                  key={x.navigation_ndc + x.name}
                >
                  <Button
                    variant="contained"
                    sx={{
                      color: primaryColor,
                      background: opacity,
                      textTransform: "none",
                      borderRadius: "40px",
                      padding: {
                        xs: "4px 8px 4px 8px",
                        md: "8px 16px 8px 16px",
                      },
                      boxShadow: "none",
                      fontSize: { xs: "14px", md: "16px" },
                      "&:hover": {
                        color: primaryColor,
                        background: hoverOpacity,
                        boxShadow: "0 1 5 0 #000000",
                      },
                      "&:active": {
                        color: primaryColor,
                        background: pressedOpacity,
                        boxShadow: "0 1 4 0 #000000",
                      },
                      "&:focus": {
                        color: primaryColor,
                        background: focusedOpacity,
                        boxShadow: "none",
                      },
                      "&:disabled": {
                        color: "#AFAFAF",
                        backgroundColor: "#D4D4D4",
                        boxShadow: "none",
                      },
                    }}
                    component={Link}
                    to={`/findMedication?id=${x.navigation_ndc}`}
                    onClick={()=>setSearchInput(x.name)}
                  >
                    {x.name}
                  </Button>
                </Box>
              );
            })}
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
};

PopularDrugButton.propTypes = {
  /**
   * Array of the popular drugs objects
   */
  popularDrugs: PropTypes.array,
  primaryColor : PropTypes.string,
  opacity : PropTypes.string,
  hoverOpacity : PropTypes.string,
  pressedOpacity : PropTypes.string,
  focusedOpacity : PropTypes.string,
  setSearchInput : PropTypes.func,
  loading: PropTypes.bool
};

PopularDrugButton.defaultProps = {
  popularDrugs: [
    {
      brandName: "Cialis",
      displayGeneric: true,
      genericName: "Tadalafil",
      ndc: "16714007501",
    },
  ],
};
export default PopularDrugButton;
