import React, { Fragment, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ShareModal from "../ShareCouponModal/ShareCouponModal";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import SavingsClub from "../SavingsClub/SavingsClub";
import RetailCoupon from "../RetailCoupon/RetailCoupon";
import Link from "@mui/material/Link";
import { useReactToPrint } from "react-to-print";
import PropTypes from "prop-types";

const DrugPriceCardUse23 = ({
  claimPrice,
  discountCardName,
  selectedDrugName,
  selectedDrugQuantity,
  brandName,
  dosage,
  form,
  pharmacyDisplayName,
  memberId,
  bin,
  pcn,
  group,
  index,
  open,
  imgLink,
  imgAltText,
  openThisCard,
  closeThisCard,
  primaryColor,
  tertiaryColor,
  couponDisclaimer,
  networkType,
  // printPageArea,
  print,
  setPrint,
  savingsClubDisclaimer,
  savingsClubText,
  savingsClubButtonText,
  urlLink,
  retailBodyText,
  retailCardDisclaimer,
  retailImg,
  retailImgAltText,
  couponInstructions,
  cardEmail,
  cardPhoneNumber,
  termsAndConditionsURL,
  brandGeneric,
  subtext,
  setModal,
  setModalOpen,
  smsDisclaimer,
  emailDisclaimer,
  shareModalApi,
  pharmacyName,
  pharmacyAddress,
  distance,
  poweredByImg,
  poweredByAlt
}) => {
  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Coupon-Card",
    onAfterPrint: () => setPrint(false),
  });

  useEffect(() => {
    print === true && handlePrint();
  }, [print, handlePrint]);

  const scrollRef = useRef();

  const current = new Date().toLocaleDateString();
  const lowestPrice = () => {
    return index === 0 ? "rgba(2, 100, 58, 1)" : "rgba(80, 72, 71, 1)";
  };

  const updatedPhoneFormat = () => {
    const phoneNumber = cardPhoneNumber.replace(/[^\d]/g, "");
    // console.log(phoneNumber.length)
    if (phoneNumber?.length === 11) {
      return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
        1,
        4
      )})-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
    } else {
      return `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    }
  };

  useEffect(() => {
    if (open) {
      scrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [open]);

  const newDistance = distance.toString().slice(0, 4)

  return (
    <Box
      data-testid="Use23"
      sx={{
        marginBottom: "16px !important",
      }}
      ref={scrollRef}
    >
      {networkType === "Club" ? (
        <SavingsClub
          claimPrice={claimPrice}
          discountCardName={discountCardName}
          selectedDrugName={selectedDrugName}
          selectedDrugQuantity={selectedDrugQuantity}
          brandName={brandName}
          dosage={dosage}
          form={form}
          pharmacyDisplayName={pharmacyDisplayName}
          memberId={memberId}
          bin={bin}
          pcn={pcn}
          group={group}
          index={index}
          open={open}
          openThisCard={openThisCard}
          primaryColor={primaryColor}
          tertiaryColor={tertiaryColor}
          couponDisclaimer={couponDisclaimer}
          networkType={networkType}
          setPrint={setPrint}
          imgLink={imgLink}
          imgAltText={imgAltText}
          lowestPrice={lowestPrice}
          savingsClubDisclaimer={savingsClubDisclaimer}
          savingsClubText={savingsClubText}
          savingsClubButtonText={savingsClubButtonText}
          urlLink={urlLink}
          closeThisCard={closeThisCard}
        />
      ) : networkType === "Retail" ? (
        <RetailCoupon
          claimPrice={claimPrice}
          discountCardName={discountCardName}
          selectedDrugName={selectedDrugName}
          selectedDrugQuantity={selectedDrugQuantity}
          brandName={brandName}
          dosage={dosage}
          form={form}
          pharmacyDisplayName={pharmacyDisplayName}
          memberId={memberId}
          bin={bin}
          pcn={pcn}
          group={group}
          index={index}
          open={open}
          openThisCard={openThisCard}
          primaryColor={primaryColor}
          tertiaryColor={tertiaryColor}
          couponDisclaimer={couponDisclaimer}
          networkType={networkType}
          setPrint={setPrint}
          imgLink={imgLink}
          imgAltText={imgAltText}
          lowestPrice={lowestPrice}
          savingsClubDisclaimer={savingsClubDisclaimer}
          savingsClubText={savingsClubText}
          savingsClubButtonText={savingsClubButtonText}
          urlLink={urlLink}
          retailBodyText={retailBodyText}
          retailCardDisclaimer={retailCardDisclaimer}
          retailImg={retailImg}
          retailImgAltText={retailImgAltText}
          closeThisCard={closeThisCard}
        />
      ) : (
        <Fragment>
          {open === false ? (
            <Box
              onClick={() => {
                openThisCard();
              }}
              sx={{
                color: "rgba(255, 255, 255, 1)",
                fontSize: "12px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                display: "flex",
                justifyContent: "center",
                alignItems: "space-between",
                minHeight: "156px",
                padding: "16px 24px",
                borderRadius: "15px",
                backgroundColor: "rgba(255, 255, 255, 1)",
                cursor: "pointer",
              }}
            >
              <Fragment>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    sm={8}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: {xs: "4px", md: "8px"}
                        }}>
                        <Typography
                          sx={{
                            color: "rgba(80, 72, 71, 1)",
                            fontSize: { xs: "16px", md: "20px" },
                            fontWeight: "600",
                            width: "100%",
                          }}
                      >
                        {pharmacyName}
                      </Typography>
                      <Typography
                        variant="body1"
                        // color="title"
                        sx={{
                          fontSize: { xs: "12px", md: "14px" },
                          color: "rgba(80, 72, 71, 1)",
                          fontWeight: 400,
                          whiteSpace: "nowrap",
                          overflow: "hidden"
                        }}
                      >
                        {pharmacyAddress}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#757593",
                          gap: "10px",
                        }}
                      >
                        <DirectionsOutlinedIcon />
                        {newDistance}{" "}mi
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignContent: "flex-end",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {index === 0 ? (
                        <Box
                          sx={{
                            // width: {xs: "90px", md: "103px"},
                            maxHeight: { xs: "22px", md: "25px" },
                            backgroundColor: "rgba(3, 167, 127, 0.2)",
                            borderRadius: "22px",
                            display: "flex",
                            justifyContent: "center",
                            padding: "2px 8px",
                            alignItems: "center",
                            marginRight: {xs: "0px", sm: "32px"},
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              color: "rgba(2, 100, 58, 1)",
                              fontSize: { xs: "12px", md: "14px" },
                            }}
                          >
                            Lowest Price
                          </Typography>
                        </Box>
                      ) : (
                        <Fragment></Fragment>
                      )}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="text"
                        sx={{
                          color: "rgba(80, 72, 71, 1)",
                          background: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start", 
                          paddingBottom: "0px",
                          "&:hover": {
                            color: "rgba(80, 72, 71, 1)",
                            background: "none",
                          },
                          "&:active": {
                            color: "rgba(80, 72, 71, 1)",
                            background: "none",
                          },
                          "&:focus": {
                            color: "rgba(80, 72, 71, 1)",
                            background: "none",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: { xs: "12px", md: "16px" },
                              fontWeight: "600",
                              color: lowestPrice,
                              whiteSpace: "nowrap",
                            }}
                          >
                            WITH COUPON
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: { xs: "20px", md: "24px" },
                              fontWeight: "700",
                              color: lowestPrice,
                            }}
                          >
                            $
                            {claimPrice !== null && !isNaN(Number(claimPrice))
                              ? claimPrice.toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : claimPrice}
                          </Typography>
                        </Box>
                        <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                          <KeyboardArrowRightOutlinedIcon />
                        </Box>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Fragment>
            </Box>
          ) : (
            // Coupon card is open
            <Fragment>
              <Box
                data-testid="drugpricecardopen"
                ref={printRef}
                sx={{ margin: print === true ? "16px" : "0px" }}
              >
                {print === true ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      marginLeft: "16px",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      padding: { xs: "16px 0px", md: "16px 0px" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#504847",
                        marginBottom: "8px",
                      }}
                    >
                      {selectedDrugName}{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "#504847",
                        marginBottom: "16px",
                      }}
                    >
                      {" "}
                      {brandGeneric} for: {subtext}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#757593",
                        marginBottom: "8px",
                      }}
                    >
                      {selectedDrugQuantity} {form} | {dosage}
                    </Typography>
                    {/* </Box> */}
                    <Box
                      sx={{
                        borderBottom: "1px solid #B9B6B6",
                        marginBottom: "16px",
                      }}
                    ></Box>
                  </Grid>
                ) : (
                  <></>
                )}

                <Box
                  sx={{
                    marginBottom: "16px !important",
                    boxShadow: "1px 2px 4px 1px rgba(0, 0, 0, 0.25)",
                    borderRadius: "15px",
                  }}
                  className="printView"
                >
                  <Box
                    sx={{
                      color: "rgba(255, 255, 255, 1)",
                      fontSize: "12px",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "space-between",
                      minHeight: "156px",
                      // padding: "0.15%",
                      padding: "16px 24px",
                      borderRadius: "15px 15px 0px 0px",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      cursor: "pointer",
                    }}
                  >
                    <Fragment>
                      <Grid container>
                        <Grid
                          item
                          xs={7}
                          sm={8}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: {xs: "4px", md: "8px"}
                            }}>
                            <Typography
                              sx={{
                                color: "rgba(80, 72, 71, 1)",
                                fontSize: { xs: "16px", md: "20px" },
                                fontWeight: "600",
                                width: "100%",
                              }}
                            >
                              {pharmacyName}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: { xs: "12px", md: "14px" },
                                color: "rgba(80, 72, 71, 1)",
                                fontWeight: 400,
                                whiteSpace: "nowrap",
                                overflow: "hidden"
                              }}
                            >
                              {pharmacyAddress}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: 600,
                                color: "#757593",
                                gap: "10px",
                              }}
                            >
                              <DirectionsOutlinedIcon />
                              {newDistance}{" "}mi
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          sm={4}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignContent: "flex-end",
                          }}
                        >
                          <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            {index === 0 ? (
                              //Checking if it is the cheapest price
                              <Box
                                sx={{
                                  maxHeight: { xs: "22px", md: "25px" },
                                  backgroundColor: "rgba(3, 167, 127, 0.2)",
                                  borderRadius: "22px",
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: "2px 8px",
                                  alignItems: "center",
                                  marginRight: {xs: "0px", sm: "32px"},
                                  whiteSpace: "nowrap",
                                  overflow: "hidden"
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "rgba(2, 100, 58, 1)",
                                    fontSize: { xs: "12px", md: "14px" },
                                  }}
                                >
                                  Lowest Price
                                </Typography>
                              </Box>
                            ) : (
                              <Fragment></Fragment>
                            )}
                          </Box>
                          <Box
                            sx={{ display: "flex", justifyContent: "flex-end", paddingBottom: "0px" }}
                          >
                            <Button
                            onClick={() => {
                                closeThisCard();
                              }}
                              variant="text"
                              sx={{
                                color: "rgba(80, 72, 71, 1)",
                                background: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start", 
                                paddingBottom: "0px",
                                "&:hover": {
                                  color: "rgba(80, 72, 71, 1)",
                                  background: "none",
                                },
                                "&:active": {
                                  color: "rgba(80, 72, 71, 1)",
                                  background: "none",
                                },
                                "&:focus": {
                                  color: "rgba(80, 72, 71, 1)",
                                  background: "none",
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Typography
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: { xs: "12px", md: "16px" },
                                    fontWeight: "600",
                                    color: lowestPrice,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  WITH COUPON
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: { xs: "20px", md: "24px" },
                                    fontWeight: "700",
                                    color: lowestPrice,
                                  }}
                                >
                                  $
                                  {claimPrice !== null &&
                                  !isNaN(Number(claimPrice))
                                    ? claimPrice.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : claimPrice}
                                </Typography>
                              </Box>
                              <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                                <KeyboardArrowDownOutlinedIcon />
                              </Box>
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Fragment>
                  </Box>

                  <Box
                    sx={{
                      color: "rgba(255, 255, 255, 1)",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                      minHeight: "258px",
                      padding: "0px 24px 24px 24px",
                      borderRadius: "0px 0px 15px 15px ",
                      backgroundColor: "rgba(255, 255, 255, 1)",
                      cursor: "pointer",
                    }}
                  >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      borderBottom: "1px solid #B9B6B6",
                      maxWidth: "100%",
                      display: "flex",
                      justifyContent: "center",
                      maxHeight: "1px",
                      padding: "0px",
                    }}
                  ></Grid>

                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      padding: { xs: "16px 0px", md: "16px 0px" },
                    }}
                  >
                    <Typography
                      color="#757593"
                      sx={{
                        fontSize: {
                          xs: "12px",
                          md: "16px",
                          fontWeight: 600,
                        },
                      }}
                    >
                      {couponInstructions}
                    </Typography>
                  </Grid>
                  <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "18px", md: "20px" },
                        fontWeight: "400",
                        color: "rgba(80, 72, 71, 1)",
                        marginLeft: { xs: "0", md: "40px" },
                      }}
                    >
                      BIN: <b>{bin}</b>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "18px", md: "20px" },
                        fontWeight: "400",
                        color: "rgba(80, 72, 71, 1)",
                        marginLeft: { xs: "0", md: "40px" },
                      }}
                    >
                      PCN: <b>{pcn}</b>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "18px", md: "20px" },
                        fontWeight: "400",
                        color: "rgba(80, 72, 71, 1)",
                        marginLeft: { xs: "0", md: "40px" },
                      }}
                    >
                      GROUP: <b>{group}</b>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "18px", md: "20px" },
                        fontWeight: "400",
                        color: "rgba(80, 72, 71, 1)",
                        marginLeft: { xs: "0", md: "40px" },
                        marginBottom: "24px",
                      }}
                    >
                      MEMBER ID: <b>{memberId?.toUpperCase()}</b>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={0}
                    sm={4}
                    md={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: { xs: "flex-start", sm: "center" },
                      justifyContent: "flex-start",
                      marginLeft: { xs: "0", md: "0" },
                    }}
                  >
                    <Button
                      onClick={() =>
                        setModal(
                          <ShareModal
                            quantityAndDose={`${selectedDrugQuantity} ${form} | ${dosage}`}
                            buttonColor={primaryColor}
                            setModalOpen={setModalOpen}
                            name={selectedDrugName}
                            form={form}
                            strength={dosage}
                            quantity={selectedDrugQuantity}
                            bin={bin}
                            pcn={pcn}
                            group={group}
                            memberId={memberId}
                            discountCardName={discountCardName}
                            price={
                              claimPrice !== null && !isNaN(Number(claimPrice))
                                ? claimPrice.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : claimPrice
                            }
                            primaryColor={primaryColor}
                            tertiaryColor={tertiaryColor}
                            setModal={setModal}
                            smsDisclaimer={smsDisclaimer}
                            emailDisclaimer={emailDisclaimer}
                            shareModalApi={shareModalApi}
                          />
                        )
                      }
                      data-testid="button"
                      sx={{
                        display: {
                          xs: "none",
                          md: print === true ? "none" : "flex",
                        },
                        justifyContent: "center",
                        width: { md: "200px", lg: "250px" },
                        height: "48px",
                        padding: "10px 24px",
                        fontSize: "20px",
                        backgroundColor: primaryColor,
                        color: tertiaryColor,
                        borderRadius: "40px",
                        marginBottom: "16px",
                        textTransform: "none",
                        border: `1px solid ${primaryColor}`,
                        "&:hover": {
                          color: primaryColor,
                          border: `1px solid ${primaryColor}`,
                          backgroundColor: tertiaryColor,
                        },
                      }}
                    >
                      <ShareOutlinedIcon
                        sx={{ height: "18px", width: "20px" }}
                      />
                      Share
                    </Button>
                    <Button
                      onClick={() => {
                        setPrint(true);
                      }}
                      sx={{
                        display: {
                          xs: "none",
                          md: print === true ? "none" : "flex",
                        },
                        justifyContent: "center",
                        width: { md: "200px", lg: "250px" },
                        height: "48px",
                        padding: "10px 24px",
                        fontSize: "20px",
                        backgroundColor: primaryColor,
                        border: `1px solid ${primaryColor}`,
                        color: tertiaryColor,
                        borderRadius: "40px",
                        textTransform: "none",
                        "&:hover": {
                          color: primaryColor,
                          border: `1px solid ${primaryColor}`,
                          backgroundColor: tertiaryColor,
                        },
                      }}
                    >
                      <PrintOutlinedIcon
                        sx={{ height: "18px", width: "20px" }}
                      />
                      Print
                    </Button>
                  </Grid>
                  </Grid>
                  <Grid item xs={12} sm={9} sx={{ marginBottom: "8px" }}>
                    {cardPhoneNumber?.length > 0 && (
                      <Typography
                        variant="body1"
                        sx={{
                          color: "rgba(117, 117, 147, 1)",
                          fontSize: { xs: "10px", sm: "12px", md: "14px" },
                          marginLeft: { xs: "0", md: "40px" },
                        }}
                      >
                        Customer support: {updatedPhoneFormat()}
                      </Typography>
                    )}

                    {cardEmail?.length > 0 && (
                      <Typography
                        variant="body1"
                        sx={{
                          color: "rgba(117, 117, 147, 1)",
                          fontSize: { xs: "10px", sm: "12px", md: "14px" },
                          marginLeft: { xs: "0", md: "40px" },
                        }}
                      >
                        Email:{" "}
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(117, 117, 147, 1)",
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "14px",
                            },
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                          component={Link}
                          href={`mailto:${cardEmail}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {cardEmail}
                        </Typography>
                      </Typography>
                    )}
                    {termsAndConditionsURL?.length > 0 && (
                      <Typography
                        variant="body1"
                        sx={{
                          color: "rgba(117, 117, 147, 1)",
                          fontSize: { xs: "10px", sm: "12px", md: "14px" },
                          marginLeft: { xs: "0", md: "40px" },
                        }}
                      >
                        {discountCardName}:{" "}
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(117, 117, 147, 1)",
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "14px",
                            },
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                          component={Link}
                          href={`${termsAndConditionsURL}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms & Conditions
                        </Typography>
                      </Typography>
                    )}
                  </Grid>
                  <Grid container>
                  <Grid item xs={12} sm={9} md={8}>
                    <Typography
                      data-testid="check-date"
                      variant="body1"
                      sx={{
                        color: "rgba(117, 117, 147, 1)",
                        fontSize: { xs: "10px", sm: "12px", md: "14px" },
                        marginLeft: { xs: "0", md: "40px" },
                      }}
                    >
                      Generated: {current}
                    </Typography>
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{
                        __html: couponDisclaimer
                          ?.replace(
                            "%%client_name%%",
                            pharmacyDisplayName || ""
                          )
                          ?.replace(
                            "%%discount_card_name%%",
                            discountCardName || ""
                          ),
                      }}
                      sx={{
                        color: "rgba(117, 117, 147, 1)",
                        fontSize: { xs: "10px", md: "14px" },
                        marginLeft: { xs: "0", md: "40px" },
                        marginBottom: "16px",
                      }}
                    ></Typography>
                  </Grid>
                  {poweredByImg ? (
                <Grid
                  item
                  xs={0}
                  md={4}
                  sx={{
                    display: {xs: "none", md: "flex"},
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "none",
                    marginBottom: "16px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      color: "rgba(117, 117, 147, 1)",
                      fontSize: { xs: "10px", md: "16px" },
                      height: "30px",
                      backgroundColor: "none",
                      gap: "6px"
                    }}
                  >
                    {/* <Typography sx={{fontSize: "12px", fontWeight: 600, color: "#757593"}}>
                        Powered By
                    </Typography> */}
                    <img
                      src={poweredByImg}
                      alt={`${poweredByAlt}`}
                      style={{
                        height: { xs: "22px", md: "30px" },
                        // width: { xs: "116px", md: "153px" },
                        backgroundColor: "rgba(255, 255, 255, 1)"
                      }}
                    ></img>
                  </Box>
                </Grid>
              ) : (
                <Fragment></Fragment>
              )}
              </Grid>
                  {poweredByImg ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={0}
                  sx={{
                    display: {xs: "flex", md: "none"},
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: "none",
                    marginBottom: "16px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      color: "rgba(117, 117, 147, 1)",
                      fontSize: { xs: "10px", md: "16px" },
                      height: "22px",
                      backgroundColor: "none",
                      gap: "6px"
                    }}
                  >
                    <img
                      src={poweredByImg}
                      alt={`${poweredByAlt}`}
                      style={{
                        height: { xs: "16px", md: "30px" },
                        backgroundColor: "rgba(255, 255, 255, 1)"
                      }}
                    ></img>
                  </Box>
                </Grid>
              ) : (
                <Fragment></Fragment>
              )}
                  <Grid
                    item
                    xs={12}
                    md={0}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setPrint(true);
                      }}
                      sx={{
                        display: {
                          xs: print === true ? "none" : "flex",
                          md: "none",
                        },
                        width: "45%",
                        height: "32px",
                        borderRadius: "40px",
                        backgroundColor: primaryColor,
                        color: tertiaryColor,
                        textTransform: "none",
                        marginBottom: "1%",
                        border: `1px solid ${primaryColor}`,
                        fontSize: "14px",
                        "&:hover": {
                          color: primaryColor,
                          border: `1px solid ${primaryColor}`,
                          backgroundColor: tertiaryColor,
                        },
                      }}
                    >
                      <PrintOutlinedIcon
                        sx={{ height: "18px", width: "20px" }}
                      />
                      Print
                    </Button>
                    <Button
                      onClick={() =>
                        setModal(
                          <ShareModal
                            brandName={brandName}
                            quantityAndDose={`${selectedDrugQuantity} ${form}s | ${dosage}`}
                            buttonColor={primaryColor}
                            setModalOpen={setModalOpen}
                            name={selectedDrugName}
                            form={form}
                            strength={dosage}
                            quantity={selectedDrugQuantity}
                            bin={bin}
                            pcn={pcn}
                            group={group}
                            memberId={memberId}
                            discountCardName={discountCardName}
                            price={
                              claimPrice !== null && !isNaN(Number(claimPrice))
                                ? claimPrice.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : claimPrice
                            }
                            primaryColor={primaryColor}
                            tertiaryColor={tertiaryColor}
                            setModal={setModal}
                            smsDisclaimer={smsDisclaimer}
                            emailDisclaimer={emailDisclaimer}
                            shareModalApi={shareModalApi}
                          />
                        )
                      }
                      sx={{
                        display: {
                          xs: print === true ? "none" : "flex",
                          md: "none",
                        },
                        width: "45%",
                        height: "32px",
                        borderRadius: "40px",
                        textTransform: "none",
                        marginBottom: "1%",
                        backgroundColor: primaryColor,
                        color: tertiaryColor,
                        border: `1px solid ${primaryColor}`,
                        fontSize: "14px",
                        "&:hover": {
                          color: primaryColor,
                          border: `1px solid ${primaryColor}`,
                          backgroundColor: tertiaryColor,
                        },
                      }}
                    >
                      <ShareOutlinedIcon
                        sx={{ height: "18px", width: "20px" }}
                      />
                      Share
                    </Button>
                  </Grid>
                </Box>
              </Box>
              </Box>
            </Fragment>
          )}
        </Fragment>
      )}
    </Box>
  );
};

DrugPriceCardUse23.propTypes = {
  claimPrice: PropTypes.number,
  discountCardName: PropTypes.string,
  selectedDrugName: PropTypes.string,
  selectedDrugQuantity: PropTypes.string,
  brandName: PropTypes.string,
  dosage: PropTypes.string,
  form: PropTypes.string,
  pharmacyDisplayName: PropTypes.string,
  memberId: PropTypes.string,
  bin: PropTypes.string,
  pcn: PropTypes.string,
  group: PropTypes.string,
  index: PropTypes.number,
  open: PropTypes.bool,
  imgLink: PropTypes.string,
  imgAltText: PropTypes.string,
  openThisCard: PropTypes.func,
  closeThisCard: PropTypes.func,
  primaryColor: PropTypes.string,
  tertiaryColor: PropTypes.string,
  couponDisclaimer: PropTypes.string,
  networkType: PropTypes.string,
  // printPageArea,
  print: PropTypes.bool,
  setPrint: PropTypes.func,
  savingsClubDisclaimer: PropTypes.string,
  savingsClubText: PropTypes.string,
  savingsClubButtonText: PropTypes.string,
  urlLink: PropTypes.string,
  retailBodyText: PropTypes.string,
  retailCardDisclaimer: PropTypes.string,
  retailImg: PropTypes.string,
  retailImgAltText: PropTypes.string,
  couponInstructions: PropTypes.string,
  cardEmail: PropTypes.string,
  cardPhoneNumber: PropTypes.string,
  termsAndConditionsURL: PropTypes.string,
  brandGeneric: PropTypes.string,
  subtext: PropTypes.string,
  setModal: PropTypes.func,
  setModalOpen: PropTypes.func,
  smsDisclaimer: PropTypes.string,
  emailDisclaimer: PropTypes.string,
  shareModalApi: PropTypes.string,
};

export default DrugPriceCardUse23;
