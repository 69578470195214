import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppContext from "./AppContext";
import API from "./Services/API";
import { useUA } from "use-ua-parser-js";
import cookie from "react-cookie";
import uuid from "react-uuid";
import Content from "./Components/Content/Content";
import { ThemeProvider } from "@mui/material/styles";
import createThemeService from "./Services/createThemeService";
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import Home from "./Views/Home/Home";
import TitleBar from "./Components/TitleBar/TitleBar";
import Footer from "./Components/Footer/Footer";
import DrugPrice from "./Views/DrugPrice/DrugPrice";
import DrugPriceUse23 from "./Views/DrugPriceUse23/DrugPriceUse23";
import MySavings from "./Views/MySavings/MySavings";
import HowItWorks from "./Views/HowItWorks2/HowItWorks2";
import GenericPage from "./Views/GenericPage/GenericPage";
import AppModal from './Components/AppModal/AppModal';
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
// import HowItWorks2 from "./Views/HowItWorks2/HowItWorks2";
import { config as ogconfig } from "./Configs/config";
import './App.css';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
/* for live preview */
import Contentstack from "contentstack"
import ContentstackLivePreview from "@contentstack/live-preview-utils";
import { callLivePreview } from "./Configs/livePreview"


const App = () => {

  const [config, setConfig] = useState(ogconfig);
  const [useCase, setUseCase] = useState(config?.client_configurationConnection?.use_case || "1");
  

  useEffect(() => {
    if (process.env.REACT_APP_live_preview==='true') {
    const Stack = Contentstack.Stack(
        { 
          "api_key": "blt0ebf167f504774d7", 
          "delivery_token": "csd6b5210a03173fc6aa65d99a", 
          "environment": "qa",
          "region": Contentstack.Region.AZURE_NA,
          live_preview: {
            management_token: 'cs2f9f018851b950a6d0a605d7',
            enable: true,
            ssr: false,
            host: 'azure-na-api.contentstack.com'
          } 
        });
      
    ContentstackLivePreview.init({stackSdk: Stack});
      
    const onEntryChange = ContentstackLivePreview.onEntryChange;
    const updateLivePreview= async () => {
      const liveContent = await callLivePreview(Stack)
      setConfig(liveContent)
    }
    onEntryChange(updateLivePreview);
  }
  }, [])

  

    // app Insights
    useEffect(() => {
      if (config?.env?.app_insights_connection_string) {
        const appInsights = new ApplicationInsights({ config: {
          connectionString: config['env']['app_insights_connection_string'],
          enableAutoRouteTracking	: true,
          autoTrackPageVisitTime : true
        } });
        appInsights.loadAppInsights();
        appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  
  const [availableRoutes] = useState([
    { 
      name: "Home", 
      route: "/store/:store_number", 
      component: "Home", 
    },
    { 
      name: "Home", 
      route: "/", 
      component: "Home", 
    },
    {
      name: "DrugPrice",
      route: "/findMedication",
      component: "DrugPrice",
    },
    
    useCase === "3" ? {} :{
      name: "MySavings",
      route: "/MySavings",
      component: "MySavings",
    },
    
    ...config?.pages?.map((x) => {
      return {
        name: x.title,
        route: x.relative_path,
        component: x.relative_path?.toLowerCase() === '/howitworks' ? "HowItWorks" : "GenericPage",
      }
    })
  ]);

  const [appContext, setAppContext] = useState({ availableRoutes });

  const [modalContent, setModalContent] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [memberId, setMemberId] = useState(null);
  const [sourceId, setSourceId] = useState(null);
  const [transactionId, setTransactionId] = useState(
    JSON.parse(sessionStorage.getItem('transactionId')) && JSON.parse(sessionStorage.getItem('transactionId'))?.transactionId && ((new Date() - Date.parse(JSON.parse(sessionStorage.getItem('transactionId'))?.date) < 24*60*60*1000 ))
    ? JSON.parse(sessionStorage.getItem('transactionId'))?.transactionId 
    : uuid()
  );

  const [clientId, setClientId] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [apiUrl, setApiUrl] = useState(null);

  const [ncpdpId, setNcpdpId] = useState(null);
  const [searchInput, setSearchInput] = useState('');

  const [theme, setTheme] = useState();

  const [recentSearches, setRecentSearches] = useState(JSON.parse(localStorage.getItem("recentDrugItem")) || []);
  const [savedCoupons, setSavedCoupons] = useState(JSON.parse(localStorage.getItem("mySaving")) || []);
  const UADetails = useUA();

  const [coordinates, setCoordinates] = useState(JSON.parse(localStorage.getItem("coordinates")) || null);
  const [searchDistance, setSearchDistance] = useState(10)
  const [zipcode, setZipcode] = useState(localStorage.getItem("zipcode"));
  const [zipCodeError, setZipCodeError] = useState(false);
  const [pharmacies, setPharmacies] = useState([]);

  useEffect(() => {
    !!zipcode && localStorage.setItem("zipcode", zipcode);
  }, [zipcode])

  useEffect(() => {
    !!coordinates && localStorage.setItem("coordinates", JSON.stringify(coordinates));
  }, [coordinates])


  // for zip codes / locations
  const fetchZip = async (message) => {
    if (message==='geo') {
      try{
      // get it from geolocation
      navigator.geolocation.getCurrentPosition(async (position) => {

        if(position?.coords?.latitude) {
          setCoordinates({latitude: position?.coords?.latitude, longitude: position?.coords?.longitude})

          const zipcode = await API.post('DrugSearchAPI/drugsearch/v1/zipcode', {
            data: {
              "latitude": position?.coords?.latitude,
              "longitude": position?.coords?.longitude
            },
          })

          const zip = zipcode.data?.data[0]?.zip_code
          setZipcode(zip)
          setModalOpen(false)
          // setZipCodeError(false)
        }
    });
  } catch(error) {
    //Check the error
    console.log("error 1: ", error)
    // setZipCodeError(true)
  }
    
  }
  // get it from input
  else if (message) {
    try{
      const zipcode_response = await API.post('DrugSearchAPI/drugsearch/v1/zipcode', {
        data: {
          "zip_code": message,
        },
      })
  
      setZipcode(message)
  
      const {latitude,longitude} = zipcode_response.data?.data[0]
  
      if (latitude && longitude) {
        setCoordinates({latitude, longitude})
      }
      setModalOpen(false)
      setZipCodeError(false)
    } catch (error){
      //Check the error
    console.log("error 2: ", error, zipCodeError)
    setZipCodeError(true)
    }
  }

  }

  const setModal = (content) => {
    setModalContent(content);
    setModalOpen(true);
  }

  const updateRecentSearch = (newSearch, oldName) => {
    setRecentSearches((recentSearches) => {
      let _newSearchArray = [...recentSearches];
      if (_newSearchArray.find((x) => x.name === newSearch.name)) {
        _newSearchArray = _newSearchArray.filter(
          (y) => y.name !== newSearch.name
        );
      }
      if (
        _newSearchArray.find(
          (x) => x.name === newSearch.name
        )
      ) {
        return _newSearchArray;
      } else {
        return [newSearch, ..._newSearchArray ];
      }
    });
  };
  
  useEffect(() => {
    transactionId && sessionStorage.setItem("transactionId", JSON.stringify({transactionId, date: new Date()}));
  }, [transactionId])

  useEffect(() => {
    localStorage.setItem("recentDrugItem", JSON.stringify(recentSearches));
  }, [recentSearches]);

  useEffect(() => {
    localStorage.setItem("mySaving", JSON.stringify(savedCoupons));
  }, [savedCoupons]);

  useEffect(() => {
    sessionStorage?.setItem("clientId", config?.client_configurationConnection?.client_id);
    sessionStorage?.setItem("apiKey", config?.env?.waltz_api_key);
    sessionStorage?.setItem("apiUrl", config?.env?.apim_host);
    setClientId(config?.client_configurationConnection?.client_id);
    setApiKey(config?.env?.waltz_api_key);
    setApiUrl(config?.env?.apim_host);

    setUseCase(config?.client_configurationConnection?.use_case);

    setTheme(createThemeService(config));

    return () => {
      sessionStorage.clear();
      // localStorage.clear();
    };
  }, [config]);





  useEffect(() => {

    const getPharmacies = async () => {
      try {

        const pharmacies = await API.post('DrugSearchAPI/drugsearch/v1/pharmacy', {
          data: {
            "latitude": coordinates.latitude,
             "longitude":coordinates.longitude,
             "radius": searchDistance
          },
        })
        if(pharmacies?.data?.data) {
          setPharmacies(pharmacies?.data?.data)
        }
      } catch (error) {
        // deal with saying there's an error with your coordinates
      }
      
    };

    if (!!coordinates && ["2", "3"].includes(useCase)) {
      getPharmacies();
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCase, coordinates, searchDistance])




  useEffect(() => {

    const getMemberId = async () => {
      try {
        const response = await API.get(`MemberAPI/memberid/${config?.api_version_configurationConnection?.member_api_version?.toLowerCase() || 'v1'}`);
        if (response?.data?.data?.member_id) {
          setMemberId(response?.data?.data?.member_id);
          //some type of regex validation here that the cookie is valid
          // cookie.save("memberId", response?.data?.data?.member_id, {secure:true}); //put this back in if we want to save member ids to cookies
        } else {
          setMemberId("WLTZ12345");
        }
      } catch (error) {
        setMemberId("WLTZ12345");
      }
      
    };

    //member id
    const _memberId = null; //cookie.load("memberId"); //put this back in if we want to save member ids to cookies
    if (_memberId) {
      setMemberId(_memberId);
    } else {
      getMemberId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    
    const getSourceAndNcpdpId = async (useCaseInput) => {
      let _source_id;
      let _storeNumber;
      let _ncpdpId;

      if (useCaseInput === "1") { 

        if (window.location.search !== "" && window.location.search && window.location.search?.includes("source_id")) {
          const arrayOfQueries = window.location.search?.substring(1)?.split("&");
          let url_source_id;
          arrayOfQueries?.forEach((x) => {
            if (x.includes("source_id")) {
              url_source_id = x.replace("source_id=", "");
            }
          });
          if (url_source_id && url_source_id !=='' && /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/.test(url_source_id)) {
            _source_id = url_source_id;
            try {
              const response = await API.get(`PricingAPI/ncpdp/${config?.api_version_configurationConnection?.ncpdp_api_version?.toLowerCase() || 'v1'}?sourceId=${_source_id}`);
              if (response?.data?.data && response?.data?.data !== '') {
                _ncpdpId = response?.data?.data;
              } 
            } catch (error) {

            }
          } 
        } else if (window.location?.pathname && window.location?.pathname !== "" && window.location?.pathname?.startsWith('/store/')) {
          if(/^\d+$/.test(window.location?.pathname?.replace('/store/',''))) {
            _storeNumber = window.location?.pathname?.replace('/store/','');
          } 
          if (_storeNumber) {
            try {
              const response = await API.get(`PricingAPI/ncpdp/${config?.api_version_configurationConnection?.ncpdp_api_version?.toLowerCase() || 'v1'}?storeNumber=${_storeNumber}`);
              if (response?.data?.data) {
                _ncpdpId = response?.data?.data;
                
                if (response?.data?.sourceId && response?.data?.sourceId !== '') {
                  _source_id = response?.data?.sourceId;
                }
              }
            } catch (error) {

            }
          }
        } 
        if (!_ncpdpId && localStorage.getItem("storeNumber")) {
          // setStoreNumber(localStorage.getItem("storeNumber"));
          if(/^\d+$/.test(localStorage.getItem("storeNumber"))) {
            _storeNumber = localStorage.getItem("storeNumber");
          } 
          if (_storeNumber) {
            try {
              const response = await API.get(`PricingAPI/ncpdp/${config?.api_version_configurationConnection?.ncpdp_api_version?.toLowerCase() || 'v1'}?storeNumber=${_storeNumber}`);
              if (response?.data?.data) {
                _ncpdpId = response?.data?.data;
                
                if (response?.data?.sourceId && response?.data?.sourceId !== '') {
                  _source_id = response?.data?.sourceId;
                }
              }
            } catch (error) {

            }
          }
        } 
        if (!_source_id && localStorage.getItem("sourceId") && /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/.test(localStorage.getItem("sourceId"))) {
          _source_id = localStorage.getItem("sourceId");
          if(!_ncpdpId) {
            try {
              const response = await API.get(`PricingAPI/ncpdp/${config?.api_version_configurationConnection?.ncpdp_api_version?.toLowerCase() || 'v1'}?sourceId=${_source_id}`);
              if (response?.data?.data && response?.data?.data !== '') {
                _ncpdpId = response?.data?.data;
              } 
            } catch (error) {

            }
          }
        } 
      } 

      const cookieNcpdpId = cookie.load("ncpdpId")

      setSourceId(_source_id || "AB123");
      _source_id && localStorage.setItem("sourceId", _source_id);
      setNcpdpId(_ncpdpId || cookieNcpdpId || config?.client_configurationConnection?.default_ncpdpid[0] || '0316960');
      _ncpdpId && cookie.save("ncpdpId", _ncpdpId, {secure:true});
      _storeNumber && localStorage.setItem('storeNumber', _storeNumber);

    }

    useCase && getSourceAndNcpdpId(useCase);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCase]);



  useEffect(() => {
    //user agent
    sessionStorage.setItem("browser_version", UADetails?.browser?.version || "108.0.0.0");
    sessionStorage.setItem("user_agent", UADetails?.browser?.name || "Chrome");
    sessionStorage.setItem("os_source_and_version",`${UADetails?.os?.name} ${UADetails?.os?.version}`);
  }, [UADetails]);


  const components = {
    Home: Home,
    DrugPrice: ["2", "3"].includes(useCase) ? DrugPriceUse23 : DrugPrice,
    MySavings: MySavings,
    HowItWorks: HowItWorks,
    GenericPage: GenericPage
  };




  useEffect(() => {
    const _appContext = {
      availableRoutes,
      modalOpen,
      modalContent,
      memberId,
      ncpdpId,
      config,
      savedCoupons,
      setSavedCoupons,
      sourceId,
      transactionId,
      setTransactionId,
      clientId,
      apiKey,
      apiUrl,
      recentSearches,
      setRecentSearches,
      updateRecentSearch,
      setModalOpen,
      setModal,
      searchInput,
      setSearchInput,
      useCase,
      zipcode,
      setZipcode,
      coordinates,
      searchDistance,
      setSearchDistance,
      pharmacies,
      setPharmacies,
      fetchZip,
      zipCodeError,
      setZipCodeError
    };
    setAppContext(_appContext);

    return () => setAppContext();
  }, [
    availableRoutes,
    memberId,
    ncpdpId,
    savedCoupons,
    sourceId,
    transactionId,
    clientId,
    apiKey,
    apiUrl,
    recentSearches,
    modalContent,
    modalOpen, 
    searchInput,
    config,
    useCase,
    zipcode,
    searchDistance,
    pharmacies,
    zipCodeError,
  ]);

  // const [location,setLocation] = useState(false)
  
  // useEffect(()=> {
  //   const defaultLocation = {center: { lat: 41.783546, lng: -87.865993 } , zoom: 8}
  //   setLocation(defaultLocation)
  // },[])
  

  return (
    <AppContext.Provider value={appContext}>
      {!!theme && (
        <ThemeProvider theme={theme}>
          <Router>
            <ScrollToTop />
            <TitleBar
              mainLogo={config?.marketing?.header_logo_image?.url}
              savedCoupons={savedCoupons}
              numberOfCoupons={savedCoupons?.length}
              badgeColor={config.color_configurationConnection.header_badges.hex}
              headerColor={config.color_configurationConnection.header_bar_color_fill.hex}
              linkColor={config.color_configurationConnection.link_default_color.hex}
              linkHovered={config.color_configurationConnection.link_hover_focused_color.hex}
              linkClicked={config.color_configurationConnection.link_pressed_color.hex}
              headerConfig={config.header_configurationConnection.header_navigation}
              primaryColor={config?.color_configurationConnection?.primary_color?.hex}
              tertiaryColor={config?.color_configurationConnection?.secondary_color?.hex}
              focusColor={config?.color_configurationConnection?.link_pressed_color?.hex}
              logoAltText={config?.marketing?.header_logo_image?.alt_text}
              headerLinkBackgroundColor={config?.color_configurationConnection?.web_header_link_buttons_background_fill_color_default?.hex}
              headerLinkBackgroundHover={config?.color_configurationConnection?.web_header_link_buttons_background_fill_color_hover?.hex}
              headerLinkButtonFontColor={config?.color_configurationConnection?.web_header_link_buttons_font_color?.hex}
              searchInput={searchInput}
              useCase={useCase}
            />
              <ErrorBoundary>
                <Content components={components} availableRoutes={availableRoutes} />
              </ErrorBoundary>
              {modalOpen && modalContent && <AppModal modalContent={modalContent} open={modalOpen} setModalOpen={setModalOpen}/>}
              {/* <AlchemerIframe /> */}
            <Footer
              backgroundColor={config?.color_configurationConnection?.footer_background_color?.hex}
              selectedIcon={config?.marketing?.footer_image_logo?.url}
              config={config}
              logoAltText={config?.marketing?.footer_image_logo?.alt_text}
              disclaimerText={config?.color_configurationConnection?.footer_legal_disclaimer_color?.hex}
              pricingFooterConfig={config?.footer_configurationConnection?.pricing_page_footer_disclaimer}
            />            
          </Router>
        </ThemeProvider>
      )}
    </AppContext.Provider>
  );
};

export default App;