import React from "react";
import { Modal } from "@mui/material";
import PropTypes from "prop-types";

const AppModal = ({open, modalContent, setModalOpen}) => {
  
    return (
        <Modal open={open} onClose={()=> setModalOpen(false)} >
          <>
            {modalContent}
          </>
        </Modal>
    );
  };

AppModal.propTypes = {
  open: PropTypes.bool,
  modalContent: PropTypes.object,
  setModalOpen: PropTypes.func,
};
  
  export default AppModal;