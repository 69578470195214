import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  Fragment,
} from "react";
import AppContext from "../../AppContext";
import { useSearchParams } from "react-router-dom";
import API from "../../Services/API";
// import PricingFooter from "../../Components/PricingFooter/PricingFooter";
import Button from "@mui/material/Button";
// import DrugInfoCard from "../../Components/DrugInfoCard/DrugInfoCard";
import PriceList from "../../Components/PriceList/PriceList";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import Fab from "@mui/material/Fab";
// import SurveyFooter from "../../Components/SurveyFooter/SurveyFooter";
import SurveyButton from "../../Components/SurveyButton/SurveyButton";
import axios from "axios";
import uniq from "lodash/uniq";
import MobileSearchEdit from "../../Components/MobileSearchEdit/MobileSearchEdit";
import { CancelToken, isCancel } from "axios";
import DrugInfoCard from "../../Components/DrugInfoCard/DrugInfoCard";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Link as RouterLink } from "react-router-dom";

const DrugPrice = (props) => {
  const {
    memberId,
    ncpdpId,
    clientId,
    sourceId,
    transactionId,
    updateRecentSearch,
    savedCoupons,
    setSavedCoupons,
    config,
    setModal,
    setModalOpen,
    // searchInput,
    setSearchInput,
  } = useContext(AppContext);

  const [selectedTab, setSelectedTab] = useState(0);
  const [drugOptions, setDrugOptions] = useState([]);
  const [selectedForm, setSelectedForm] = useState("");
  const [selectedDrugOption, setSelectedDrugOption] = useState(null);
  const [selectedDrugName, setSelectedDrugName] = useState("");
  const [availableForms, setAvailableForms] = useState([]);
  const [availableDosages, setAvailableDosages] = useState([]);
  const [selectedDosage, setSelectedDosage] = useState("");
  const [selectedPackageNames, setSelectedPackageNames] = useState();
  const [availableDrugQuantities, setAvailableDrugQuantities] = useState([]);
  const [urlInfo, setUrlInfo] = useState();
  const [prices, setPrices] = useState([]);
  const [nonNormalDrug, setNonNormalDrug] = useState([]);
  const [ndcLeaflet, setNdcLeaflet] = useState();
  const [drugNames, setDrugNames] = useState([]);
  const [drugOptionsloading, setDrugOptionsLoading] = useState(true);
  const [priceLoading, setPriceLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [search, setSearch] = useSearchParams();
  const [numOfResultsToShow, setNumOfResultsToShow] = useState(6);
  const [showSurvey, setShowSurvey] = useState(true);
  const [mobileEditMenuOpen, setMobileEditMenuOpen] = useState(false);

  //Error Handling
  const [drugInfoError, setDrugInfoError] = useState(false);
  const [drugPricesError, setDrugPricesError] = useState(false);

  const current =
    new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();

  const trackCoupon = (data = {}) => {
    try {
      API.post(
        `PricingAPI/coupon/${
          config?.api_version_configurationConnection?.coupon_api_version?.toLowerCase() ||
          "v3"
        }`,
        { data: data }
      );
      if (!!window?.heap) {
        try {
          window?.heap?.track("Coupon Clicked (Drug Page)", data);
        } catch (error) {
          // console.log('heaperror',error)
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Calling the function to grab the drug data (used in the urlData)
  useEffect(() => {
    const callDrugInfo = async (cancel) => {
      const searchAsObject = Object.fromEntries(new URLSearchParams(search));
      const selectedNdc = !!searchAsObject.id ? searchAsObject.id : undefined;
      // searchAsObject.id && setSelectedNdc(searchAsObject.id); //searchAsObject.id !== selectedNdc &&
      // searchAsObject.qty && /\d/g.test(searchAsObject.qty) ? setSelectedDrugQuantity(searchAsObject.qty) : setSelectedDrugQuantity('');
      const _selectedDrugQuantity =
        !!searchAsObject.qty && /^\d+$/.test(searchAsObject.qty)
          ? searchAsObject.qty
          : undefined;
      // console.log('1')
      // console.log(selectedNdc,memberId,sourceId,transactionId,firstLoad)
      if (
        selectedNdc &&
        selectedNdc !== "" &&
        memberId &&
        sourceId &&
        transactionId &&
        firstLoad
      ) {
        // console.log('1.1')
        try {
          setDrugOptionsLoading(true);
          const response = await API.post(
            `/DrugSearchAPI/drugsearch/${
              config?.api_version_configurationConnection?.drug_search_api_version?.toLowerCase() ||
              "v11"
            }/drug`,
            {
              data: {
                transactionId: JSON.parse(
                  sessionStorage.getItem("transactionId")
                )?.transactionId,
                memberId: memberId,
                sourceId: sourceId,
                gaId: "GA1.3.2029094371.1658794395",
                gaSessionId: "GA1.3.1644885013.1658794395",
                navigation_ndc: selectedNdc,
              },
              conf: { cancelToken: cancel.token },
            }
          );
          let _drugList = [...response?.data?.data?.drugList];
          // let _searchNames = [...response?.data?.data?.searchNames];
          // setDrugNames(
          //   response?.data?.data?.searchNames
          //     ?.sort((a, b) => b.default - a.default)
          //     ?.sort((a, b) => a.name - b.name)
          // );

          setDrugNames((oldDrugNames) => {
            if (
              oldDrugNames.filter((y) => y.navigation_ndc === selectedNdc)
                ?.length > 0
            ) {
              //dont need to do anything
            } else {
              //close the mobile menu if it's open
              setMobileEditMenuOpen(false);
            }
            return response?.data?.data?.searchNames
              ?.sort((a, b) => b.default - a.default)
              ?.sort((a, b) => a.name - b.name);
          });

          // const firstName = _drugList.filter(
          //   (y) => y.navigation_ndc === selectedNdc
          // )[0]?.name;

          // if (_searchNames?.length > 1) {
          //   const uniqSearchNames = uniq(
          //     _searchNames
          //       .filter((y) => y.name !== firstName)
          //       ?.map((x) => x.navigation_ndc)
          //   );
          //   const moreRequests = uniqSearchNames.map(async (x) => {
          //     const _response = await API.post(
          //       `/DrugSearchAPI/drugsearch/${
          //         config?.api_version_configurationConnection?.drug_search_api_version?.toLowerCase() ||
          //         "v11"
          //       }/drug`,
          //       {
          //         data: {
          //           transactionId: transactionId,
          //           memberId: memberId,
          //           sourceId: sourceId,
          //           gaId: "GA1.3.2029094371.1658794395",
          //           gaSessionId: "GA1.3.1644885013.1658794395",
          //           navigation_ndc: x,
          //         },
          //       }
          //     );
          //     return _response?.data?.data?.drugList;
          //   });
          //   const allResults = await Promise.all(moreRequests);
          //   allResults?.forEach((x) => {
          //     x?.forEach((x2) => {
          //       _drugList.push(x2);
          //     });
          //   });
          //   setSelectedDrugOption(
          //     _drugList?.filter((y) => y.navigation_ndc === selectedNdc)[0]
          //   );
          //   setDrugOptions(_drugList);
          //   setDrugOptionsLoading(false);
          //   setFirstLoad(false);
          // } else {
          setSelectedDrugOption({
            ..._drugList?.filter((y) => y.navigation_ndc === selectedNdc)[0],
            selectedDrugQuantity:
              _selectedDrugQuantity ||
              _drugList
                ?.filter((y) => y.navigation_ndc === selectedNdc)[0]
                ?.quantities?.filter((y) => y.default === true)[0]?.size,
          });
          setDrugOptions(_drugList);
          setDrugOptionsLoading(false);
          setFirstLoad(false);
          setDrugInfoError(false);
          // }
        } catch (error) {
          if (isCancel(error)) {
            // setDrugInfoError(true);
            // console.log('Request canceled', error);
          } else {
            setDrugOptionsLoading(false);
            setDrugInfoError(true);
            console.log(error);
          }
        }
      } else if (selectedNdc && !firstLoad && drugOptions?.length > 0) {
        // console.log('2')
        if (
          drugOptions?.filter((y) => y.navigation_ndc === selectedNdc)?.length >
          0
        ) {
          // console.log('3')
          setSelectedDrugOption((selectedOption) =>
            selectedOption.navigation_ndc === selectedNdc &&
            selectedOption.selectedDrugQuantity === _selectedDrugQuantity
              ? selectedOption
              : {
                  ...drugOptions?.filter(
                    (y) => y.navigation_ndc === selectedNdc
                  )[0],
                  selectedDrugQuantity:
                    _selectedDrugQuantity ||
                    drugOptions
                      ?.filter((y) => y.navigation_ndc === selectedNdc)[0]
                      ?.quantities?.filter((y) => y.default === true)[0]?.size,
                }
          );
        } else {
          // console.log('4')
          setFirstLoad(true);
        }
      }
    };
    const cancel = CancelToken.source();
    callDrugInfo(cancel);

    return () => cancel.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, firstLoad, drugOptions, memberId, sourceId]);

  //Setting selected Ndc, Drug Name, Form, Dosage, and Quantity from the dropdowns
  //Find a way to trigger these by clicking the select, instead of just on useEffect
  useEffect(() => {
    if (selectedDrugOption) {
      setSelectedDrugName(selectedDrugOption?.name);
      setSearchInput(selectedDrugOption?.name);
      setSelectedForm(selectedDrugOption?.form);
      setSelectedDosage(selectedDrugOption?.dosage);
      selectedDrugOption?.packageSize &&
      selectedDrugOption?.packageNames?.length > 0
        ? setSelectedPackageNames({
            singular: selectedDrugOption?.packageNames[0]
              ?.replace("[", "")
              ?.replace("]", "")
              ?.replace('"', "")
              ?.split(",")[0],
            plural: selectedDrugOption?.packageNames[0]
              ?.replace("[", "")
              ?.replace("]", "")
              ?.replace('"', "")
              ?.split(",")[1],
          })
        : setSelectedPackageNames(undefined);

      const searchAsObject = Object.fromEntries(new URLSearchParams(search));
      if (searchAsObject.id !== selectedDrugOption?.navigation_ndc) {
        setSearch({ id: selectedDrugOption?.navigation_ndc });
      }

      // if (!searchAsObject.qty) {
      //   setSelectedDrugQuantity(
      //     selectedDrugOption?.quantities?.filter((y) => y.default === true)[0]
      //       ?.size
      //   );
      // }
      setAvailableDrugQuantities(selectedDrugOption?.quantities);

      if (drugOptions?.length > 0) {
        const _availableForms = uniq(
          drugOptions
            ?.filter((y) => y.name === selectedDrugOption?.name)
            ?.map((x) => x.form)
        )?.sort((a, b) => a - b);
        setAvailableForms(_availableForms);
        const _availableDosages = uniq(
          drugOptions
            ?.filter(
              (y) =>
                y.name === selectedDrugOption?.name &&
                y.form === selectedDrugOption?.form
            )
            ?.map((x) => x.dosage)
        )?.sort(
          (a, b) =>
            a.substring(0, a.indexOf(" ")) - b.substring(0, b.indexOf(" "))
        );
        setAvailableDosages(_availableDosages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDrugOption, drugOptions]);

  useEffect(() => {
    selectedDrugOption &&
      updateRecentSearch &&
      updateRecentSearch(
        {
          name: selectedDrugOption?.name,
          brandGeneric: selectedDrugOption?.brand_generic,
          alternateName: !!selectedDrugOption?.subtext
            ? `${selectedDrugOption?.name} | ${selectedDrugOption?.brand_generic} for ${selectedDrugOption?.subtext}`
            : `${selectedDrugOption?.name} | ${selectedDrugOption?.brand_generic}`, //
          ndc: selectedDrugOption?.navigation_ndc,
          quantity: selectedDrugOption?.selectedDrugQuantity,
        },
        selectedDrugOption?.name
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDrugOption]);

  //Getting the coupon prices
  useEffect(() => {
    const callDrugPrices = async (cancel) => {
      if (
        selectedDrugOption &&
        memberId &&
        ncpdpId &&
        // selectedDrugQuantity &&
        sourceId &&
        transactionId &&
        !drugOptionsloading
      ) {
        try {
          setPriceLoading(true);
          const response = await API.post(
            `/PricingAPI/pricing/${
              config?.api_version_configurationConnection?.pricing_api_version?.toLowerCase() ||
              "v13"
            }/marketplacesearch`,
            {
              data: {
                brand_generic: selectedDrugOption.brand_generic,
                brand_name: selectedDrugOption.name,
                brand_name_code: selectedDrugOption.bnc,
                dosage: selectedDrugOption.dosage,
                drug_name: selectedDrugOption.name,
                form: selectedDrugOption.form,
                generic_name: selectedDrugOption.name,
                gpi14: selectedDrugOption.gpi,
                mscode: selectedDrugOption.msCode,
                packageSize: selectedDrugOption.packageSize,
                quantity: selectedDrugOption.selectedDrugQuantity,
                ncpdpid: ncpdpId,
                resp_count: "20",
                transactionId: transactionId,
                memberId: memberId,
                sourceId: sourceId,
                gaId: "GA1.3.2029094371.1658794395",
                gaSessionId: "GA1.3.1644885013.1658794395",
                ndc: selectedDrugOption.price_ndc,
              },
              conf: { cancelToken: cancel.token },
            }
          );
          setPrices(response?.data?.data || []);
          setNonNormalDrug(response?.data?.nonNormalDrug || []);
          setNumOfResultsToShow(response?.data?.totalNoOfResults);
          setPriceLoading(false);
          setDrugPricesError(false);
        } catch (error) {
          if (isCancel(error)) {
            // setDrugPricesError(true);
            // console.log('Request canceled', error);
          } else {
            setPriceLoading(false);
            setDrugPricesError(true);
            console.log(error);
            if (!!window?.heap) {
              window?.heap?.track("Error", error);
            }
          }
        }
      }
    };

    const cancel = CancelToken.source();
    callDrugPrices(cancel);

    return () => cancel.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedDrugOption),
    drugOptionsloading,
    memberId,
    ncpdpId,
    sourceId,
  ]);

  const changeDosage = (newDosage) => {
    setSelectedDrugOption({
      ...drugOptions?.filter(
        (y) =>
          y.name === selectedDrugName &&
          y.form === selectedForm &&
          y.dosage === newDosage
      )[0],
      selectedDrugQuantity: drugOptions
        ?.filter(
          (y) =>
            y.name === selectedDrugName &&
            y.form === selectedForm &&
            y.dosage === newDosage
        )[0]
        ?.quantities?.filter((y) => y.default === true)[0]?.size,
    });
  };

  const changeForm = (newForm) => {
    setSelectedDrugOption({
      ...drugOptions?.filter(
        (y) => y.name === selectedDrugName && y.form === newForm
      )[0],
      selectedDrugQuantity: drugOptions
        ?.filter((y) => y.name === selectedDrugName && y.form === newForm)[0]
        ?.quantities?.filter((y) => y.default === true)[0]?.size,
    });
  };

  const changeName = (newName) => {
    setSelectedDrugName(newName);
    setSearchInput(newName);
    setSearch({
      id: drugNames?.filter((y) => y.name === newName)[0]?.navigation_ndc,
    });
  };

  const handleSaveCoupon = useCallback(async () => {
    if (
      !savedCoupons.filter(
        (y) =>
          y.navigation_ndc === selectedDrugOption?.navigation_ndc &&
          y.quantity === selectedDrugOption?.selectedDrugQuantity
      )?.length > 0
    ) {
      setSavedCoupons((savedCoupons) => [
        {
          generic_name:
            selectedDrugOption?.brand_generic === "Generic"
              ? selectedDrugOption?.name
              : selectedDrugOption?.subtext,
          brand_name:
            selectedDrugOption?.brand_generic === "Generic"
              ? selectedDrugOption?.subtext
              : selectedDrugOption?.name,
          drug_name: selectedDrugOption?.name,
          originalCouponPrice:
            prices?.length > 0 ? `${prices[0]?.claim_price}` : undefined,
          quantity: selectedDrugOption?.selectedDrugQuantity,
          navigation_ndc: selectedDrugOption?.navigation_ndc,
          ndc: selectedDrugOption?.price_ndc,
          ncpdp: prices?.length > 0 && prices[0]?.ncpdp ? prices[0]?.ncpdp : ncpdpId,
          mscode: selectedDrugOption?.msCode,
          brand_name_code: selectedDrugOption?.bnc,
          gpi14: selectedDrugOption?.gpi,
          brandGeneric: `${selectedDrugOption?.brand_generic}`,
          timeSaved: current,
          // cashCardEmailId: selectedDrugOption?.cashCardEmailId,
          // cashCardPhoneNumber: selectedDrugOption?.cashCardPhoneNumber,
          // cashCardTermsAndConditionsURL: selectedDrugOption?.cashCardTermsAndConditionsURL,
          ...selectedDrugOption,
        },
        ...savedCoupons,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDrugOption, prices, setSavedCoupons]);

  const handleQuantityChange = useCallback((newQuantity) => {
      setSearch({ id: selectedDrugOption?.navigation_ndc, qty: newQuantity });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[JSON.stringify(selectedDrugOption), setSearch]);

  const callLabelInfo = useCallback(async () => {
    if (selectedDrugOption?.label_file) {
      const response = await axios.get(
        `https://${config?.env?.drug_image_leaflet_host}/indigorx/drug/leaflet/${selectedDrugOption?.label_file}`
      );
      setNdcLeaflet(response?.data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedDrugOption)]);

  useEffect(() => {
    callLabelInfo();
  }, [callLabelInfo]);

  // const printPageArea = () => {
  //   const bodyElement = document.getElementsByTagName("body")[0];
  //   bodyElement.classList.add("printing");
  //   window.print();
  //   // console.log("Print: ", window.print())
  //   bodyElement.classList.remove("printing");
  //   setPrint(false);
  // };

  const [print, setPrint] = useState(false);

  // console.log("Prices: ", prices)
  // console.log("NDC: ", selectedDrugOption)
  // useEffect(() => {
  //   print === true && printPageArea();
  // }, [print]);

  useEffect(() => {
    document.title = "Drug Pricing";
  }, []);

  return (
    <Box sx={{ backgroundColor: "#EEEEEE", overflow: "hidden" }}>
      {/* <Box className="printView"> */}
      {/* <Fragment> */}
      {drugInfoError === true ? (
        <Box
          sx={{
            width: "100%",
            height: { xs: "600px", md: "680px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <ErrorOutlineIcon
            sx={{
              height: { xs: "80px", md: "120px" },
              width: { xs: "80px", md: "120px" },
              fontWeight: 400,
            }}
          />
          <Typography
            variant="title"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 400,
              fontSize: { xs: "14px", md: "16px" },
              padding: "16px",
            }}
          >
            We encountered an error. Try refreshing the page or try again later.
          </Typography>
          <Button
            component={RouterLink}
            to="/"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: { xs: "4px 16px", md: "10px 24px" },
              borderRadius: "24px",
              width: { xs: "98px", md: "142px" },
              height: { xs: "29px", md: "48px" },
              textTransform: "none",
              backgroundColor:
                config?.color_configurationConnection?.primary_color?.hex,
              color:
                config?.color_configurationConnection?.secondary_color?.hex,
              border: `1px solid ${config?.color_configurationConnection?.primary_color?.hex}`,
              "&:hover": {
                color:
                  config?.color_configurationConnection?.primary_color?.hex,
                backgroundColor:
                  config?.color_configurationConnection?.secondary_color?.hex,
              },
              "&:active": {
                color:
                  config?.color_configurationConnection?.primary_color?.hex,
                backgroundColor:
                  config?.color_configurationConnection?.secondary_color?.hex,
              },
              "&:focus": {
                color:
                  config?.color_configurationConnection?.primary_color?.hex,
                backgroundColor:
                  config?.color_configurationConnection?.secondary_color?.hex,
              },
            }}
          >
            Go Home
          </Button>
        </Box>
      ) : (
        <Fragment>
          <DrugInfoCard
            ndcImageUrl={
              selectedDrugOption?.image_file
                ? `https://${config?.env?.drug_image_leaflet_host}/indigorx/drug/images/${selectedDrugOption?.image_file}`
                : undefined
            }
            brandGeneric={selectedDrugOption?.brand_generic}
            subtextName={selectedDrugOption?.subtext}
            changeName={changeName}
            selectedDrugName={selectedDrugName}
            drugNames={drugNames}
            changeForm={changeForm}
            selectedForm={selectedForm}
            availableForms={availableForms}
            changeDosage={changeDosage}
            selectedDosage={selectedDosage}
            availableDosages={availableDosages}
            setSelectedDrugQuantity={handleQuantityChange}
            selectedDrugQuantity={
              selectedDrugOption?.selectedDrugQuantity || ""
            }
            availableDrugQuantities={availableDrugQuantities}
            selectedPackageNames={selectedPackageNames}
            buttonColor={
              config?.color_configurationConnection?.primary_color?.hex
            }
            currentlySaved={
              savedCoupons?.filter(
                (y) =>
                  y.navigation_ndc === selectedDrugOption?.navigation_ndc &&
                  y.quantity === selectedDrugOption?.selectedDrugQuantity
              )?.length > 0
                ? true
                : false
            }
            handleSaveCoupon={handleSaveCoupon}
            selectedNdc={selectedDrugOption?.navigation_ndc}
            setModal={setModal}
            setModalOpen={setModalOpen}
            savedCoupons={savedCoupons}
            primaryColor={
              config?.color_configurationConnection?.primary_color?.hex
            }
            tertiaryColor={
              config?.color_configurationConnection?.secondary_color?.hex
            }
            // printPageArea={printPageArea}
            print={print}
            loading={drugOptionsloading}
            drugInfoError={drugInfoError}
            enableSaving={true}
          />

          <MobileSearchEdit
            loading={drugOptionsloading}
            selectedDrugQuantity={
              selectedDrugOption?.selectedDrugQuantity || ""
            }
            selectedForm={selectedForm}
            selectedDosage={selectedDosage}
            selectedDrugName={selectedDrugName}
            brandGeneric={selectedDrugOption?.brand_generic}
            buttonColor={
              config?.color_configurationConnection?.primary_color?.hex
            }
            changeName={changeName}
            drugNames={drugNames}
            changeForm={changeForm}
            availableForms={availableForms}
            changeDosage={changeDosage}
            availableDosages={availableDosages}
            setSelectedDrugQuantity={handleQuantityChange}
            availableDrugQuantities={availableDrugQuantities}
            selectedPackageNames={selectedPackageNames}
            primaryColor={
              config?.color_configurationConnection?.primary_color?.hex
            }
            tertiaryColor={
              config?.color_configurationConnection?.secondary_color?.hex
            }
            setShowSurvey={setShowSurvey}
            mobileEditMenuOpen={mobileEditMenuOpen}
            setMobileEditMenuOpen={setMobileEditMenuOpen}
          />
          {/* </Fragment> */}
          {/* </Box> */}
          <Box>
            {/* home page search box*/}

            <Container maxWidth="lg">
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    {/* prices and drug info box */}
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: { xs: "2%", md: "0" },
                        }}
                      >
                        <Box>
                          <Button
                            variant="contained"
                            color="subtitle"
                            sx={{
                              color:
                                selectedTab === 0
                                  ? config?.color_configurationConnection
                                      ?.secondary_color?.hex
                                  : "rgba(41, 41, 51, 1)",
                              backgroundColor:
                                selectedTab === 0
                                  ? config?.color_configurationConnection
                                      ?.primary_color?.hex
                                  : "rgba(80, 72, 71, 0.1)",
                              "&:hover": {
                                color:
                                  config?.color_configurationConnection
                                    ?.primary_color?.hex,
                                backgroundColor:
                                  config?.color_configurationConnection
                                    ?.secondary_color?.hex,
                              },
                              "&:active": {
                                backgroundColor:
                                  config?.color_configurationConnection
                                    ?.primary_color?.hex,
                                color:
                                  config?.color_configurationConnection
                                    ?.secondary_color?.hex,
                              },
                              "&:focus": {
                                backgroundColor:
                                  config?.color_configurationConnection
                                    ?.primary_color?.hex,
                                color:
                                  config?.color_configurationConnection
                                    ?.secondary_color?.hex,
                              },

                              boxShadow: "none",
                              borderRadius: "5px 0px 0px 5px",
                              textTransform: "none",
                            }}
                            onClick={() => setSelectedTab(0)}
                          >
                            <Typography
                              sx={{ fontSize: "12px", fontWeight: "600" }}
                            >
                              Coupons
                            </Typography>
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              color:
                                selectedTab === 1
                                  ? config?.color_configurationConnection
                                      ?.secondary_color?.hex
                                  : "rgba(41, 41, 51, 1)",
                              backgroundColor:
                                selectedTab === 1
                                  ? config?.color_configurationConnection
                                      ?.primary_color?.hex
                                  : "rgba(80, 72, 71, 0.1)",
                              "&:hover": {
                                color:
                                  config?.color_configurationConnection
                                    ?.primary_color?.hex,
                                backgroundColor:
                                  config?.color_configurationConnection
                                    ?.secondary_color?.hex,
                              },
                              "&:active": {
                                backgroundColor:
                                  config?.color_configurationConnection
                                    ?.primary_color?.hex,
                                color:
                                  config?.color_configurationConnection
                                    ?.secondary_color?.hex,
                              },
                              "&:focus": {
                                backgroundColor:
                                  config?.color_configurationConnection
                                    ?.primary_color?.hex,
                                color:
                                  config?.color_configurationConnection
                                    ?.secondary_color?.hex,
                              },
                              boxShadow: "none",
                              borderRadius: "0px 5px 5px 0px",
                              textTransform: "none",
                            }}
                            onClick={() => setSelectedTab(1)}
                          >
                            <Typography
                              sx={{ fontSize: "12px", fontWeight: "600" }}
                            >
                              Drug Info & Interactions
                            </Typography>
                          </Button>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            color: "rgb(75, 75, 75) !important",
                          }}
                        >
                          <Box sx={{ display: "flex" }}></Box>
                        </Box>
                      </Box>
                      {drugPricesError === true ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            height: { xs: "380px", md: "500px" },
                            alignItems: { xs: "flex-start", md: "center" },
                          }}
                        >
                          <Typography
                            variant="title"
                            sx={{
                              fontWeight: 400,
                              marginTop: { xs: "24px", md: "0px" },
                              fontSize: { xs: "14px", md: "16px" },
                            }}
                          >
                            We're unable to find prices at this time. Refresh
                            the page or try again later.
                          </Typography>
                        </Box>
                      ) : (
                        <Fragment>
                          {selectedTab === 0 && (
                            <Fragment>
                              <PriceList
                                memberId={memberId}
                                prices={prices}
                                setModal={setModal}
                                setModalOpen={setModalOpen}
                                selectedDrugOption={selectedDrugOption}
                                selectedDrugQuantity={
                                  selectedDrugOption?.selectedDrugQuantity
                                }
                                urlInfo={urlInfo}
                                setUrlInfo={setUrlInfo}
                                price_ndc={selectedDrugOption?.price_ndc}
                                loading={drugOptionsloading || priceLoading}
                                // loading={true}
                                sourceId={sourceId}
                                transactionId={transactionId}
                                numOfResultsToShow={numOfResultsToShow}
                                primaryColor={
                                  config?.color_configurationConnection
                                    ?.primary_color?.hex
                                }
                                tertiaryColor={
                                  config?.color_configurationConnection
                                    ?.secondary_color?.hex
                                }
                                couponDisclaimer={
                                  config?.coupon_configurationConnection
                                    ?.coupon_card_disclaimer
                                }
                                ncpdpId={ncpdpId}
                                clientId={clientId}
                                trackCoupon={trackCoupon}
                                // printPageArea={printPageArea}
                                print={print}
                                setPrint={setPrint}
                                nonNormalDrug={nonNormalDrug}
                                imgLink={
                                  config?.marketing?.savings_club_image?.url
                                }
                                imgAltText={
                                  config?.marketing?.savings_club_image
                                    ?.alt_text
                                }
                                savingsClubDisclaimer={
                                  config?.coupon_configurationConnection
                                    ?.savings_club_disclaimer
                                }
                                savingsClubText={
                                  config?.coupon_configurationConnection
                                    ?.savings_club_text
                                }
                                savingsClubButtonText={
                                  config?.coupon_configurationConnection
                                    ?.savings_club_button_text
                                }
                                retailBodyText={
                                  config?.coupon_configurationConnection
                                    ?.retail_body_text
                                }
                                retailCardDisclaimer={
                                  config?.coupon_configurationConnection
                                    ?.retail_card_disclaimer
                                }
                                retailImg={config?.marketing?.favicon?.url}
                                retailImgAltText={`${config?.title} Retail Price`}
                                couponInstructions={
                                  config?.coupon_configurationConnection
                                    ?.coupon_header
                                }
                                brandGeneric={selectedDrugOption?.brand_generic}
                                subtext={selectedDrugOption?.subtext}
                                smsDisclaimer={config?.coupon_configurationConnection?.sms?.disclaimer}
                                emailDisclaimer={config?.coupon_configurationConnection?.email?.disclaimer}
                                shareModalApi={config?.api_version_configurationConnection?.sms_api_version}
                              />
                            </Fragment>
                          )}
                          {selectedTab === 1 &&
                            (ndcLeaflet !== undefined ? (
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Paper
                                    sx={{
                                      padding: "2%",
                                      boxShadow: "5px",
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      mt: "3%",
                                      borderRadius: "15px",
                                    }}
                                  >
                                    <Typography
                                      className="drugInfo"
                                      sx={{ width: "100%" }}
                                      dangerouslySetInnerHTML={{
                                        __html: ndcLeaflet,
                                      }}
                                    ></Typography>
                                  </Paper>
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Paper
                                    sx={{
                                      padding: "2%",
                                      boxShadow: "5px",
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      mt: "3%",
                                      mb: "3%",
                                      minHeight: "300px",
                                      borderRadius: "15px",
                                    }}
                                  >
                                    <Typography color="title">
                                      {
                                        config
                                          ?.error_validation_configurationConnection
                                          ?.pricing_page_leaflet_error_message
                                      }
                                    </Typography>
                                  </Paper>
                                </Grid>
                              </Grid>
                            ))}
                        </Fragment>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {showSurvey && <SurveyButton setModal={setModal} setModalOpen={setModalOpen} config={config}/>}
            </Container>
            {/* <SurveyFooter /> */}
            {/* <PricingFooter /> */}
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export default DrugPrice;
