import React from "react";
import { Button, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

const DeleteCoupon = ({ setModalOpen, primaryColor, tertiaryColor, deleteCoupon, quantity, navigationNdc, savedCoupons, name }) => {

    const confirmDelete = () => {
        deleteCoupon(navigationNdc, quantity, savedCoupons);
        setModalOpen(false)
    }
  return (
    <Box
      data-testid='deletecouponmodal'
      sx={{
        position: "absolute",
        top: { xs: "40%", sm: "40%", md: "40%" },
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: { xs: "80%", sm: "60%", md: "30%", lg: "25%" },
        bgcolor: "background.paper",
        borderRadius: "16px",
        boxShadow: 24,
        p: 4,
        padding: "16px 24px",
        display: "flex",
        alignItems: "space-between",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Typography color="title" sx={{ display: "flex", alignItems: "flex-end", fontSize: "20px", fontWeight: 600 }}>
            Remove {name} from My Savings?
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          }}
        >
          <IconButton onClick={() => setModalOpen(false)} sx={{
            marginTop: "-12px",
            marginRight: "-20px"}}>
            <CloseIcon color="title" />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography color="title" sx={{ fontSize: "16px", fontWeight: 400 }}>
          Are you sure you want to remove this drug from your saved favorites?
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{display: "flex", alignItems: "flex-end", justifyContent: "flex-start"}}>
        <Button
            data-testid='cancelbutton'
            sx={{
              display: "flex",
              justifyContent: "center",
              color: primaryColor,
                backgroundColor: tertiaryColor,
                border: `1px solid ${primaryColor}`,
              borderRadius: "24px",
              textTransform: "none",
              fontWeight: 600,
              width: "99%",
              "&:hover": {
                color: primaryColor,
                backgroundColor: tertiaryColor,
                border: `1px solid ${primaryColor}`,
              },
              "&:focus": {
                color: primaryColor,
                backgroundColor: tertiaryColor,
                border: `1px solid ${primaryColor}`,
              },
            }}
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6} sx={{display: "flex", alignItems: "flex-end", justifyContent: "flex-end"}}>
          <Button
            data-testid='deletebutton'
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: primaryColor,
              color: tertiaryColor,
              borderRadius: "24px",
              textTransform: "none",
              border: `1px solid ${primaryColor}`,
              fontWeight: 600,
              width: "99%",
              "&:hover": {
                color: primaryColor,
                backgroundColor: tertiaryColor,
                border: `1px solid ${primaryColor}`,
              },
              "&:focus": {
                color: primaryColor,
                backgroundColor: tertiaryColor,
                border: `1px solid ${primaryColor}`,
              },
            }}
            onClick={() => confirmDelete()}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

DeleteCoupon.propTypes = {
  setModalOpen : PropTypes.func, 
  primaryColor : PropTypes.string, 
  tertiaryColor : PropTypes.string, 
  deleteCoupon : PropTypes.func, 
  quantity : PropTypes.string, 
  navigationNdc : PropTypes.string, 
  savedCoupons : PropTypes.array, 
  name : PropTypes.string
};

export default DeleteCoupon;
