import React, { useEffect, useState, Fragment } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DrugPriceCard from "../DrugPriceCard/DrugPriceCard";
import DrugPriceCardUse23 from "../DrugPriceCardUse23/DrugPriceCardUse23";
import Skeleton from "@mui/material/Skeleton";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

const PriceList = ({
  memberId,
  prices,
  selectedDrugOption,
  selectedDrugQuantity,
  price_ndc,
  loading,
  sourceId,
  transactionId,
  numOfResultsToShow,
  primaryColor,
  tertiaryColor,
  couponDisclaimer,
  clientId,
  ncpdpId,
  trackCoupon,
  // printPageArea,
  print,
  setPrint,
  nonNormalDrug,
  imgLink,
  imgAltText,
  savingsClubDisclaimer,
  savingsClubText,
  savingsClubButtonText,
  retailBodyText,
  retailCardDisclaimer,
  retailImg,
  retailImgAltText,
  couponInstructions,
  brandGeneric,
  subtext,
  setModal,
  setModalOpen,
  smsDisclaimer,
  emailDisclaimer,
  shareModalApi,
  poweredByImg,
  poweredByAlt,
  useCase23
}) => {
  const [open, setOpen] = useState(-1);
  const handleOpen = () => setOpen(true);

  const [showAll, setShowAll] = useState(
    prices?.length > numOfResultsToShow || prices.length === 0 ? false : true
  );
  const [lessPrices, setLessPrices] = useState([]);

  useEffect(() => {
    setShowAll(
      prices?.length > numOfResultsToShow || prices.length === 0 ? false : true
    );
  }, [prices, numOfResultsToShow]);

  useEffect(() => {
    loading && setOpen(-1);
    loading && setShowAll(false);
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      if (showAll) {
        setLessPrices(prices);
      } else {
        setLessPrices(prices?.slice(0, numOfResultsToShow));
      }
    }
  }, [prices, numOfResultsToShow, showAll, loading]);

  useEffect(() => {
    if (!!window?.heap && lessPrices.length > 0) {
      let priceObject = {};
      lessPrices.forEach((x, index) => {
        priceObject[`Row ${index + 1} Vendor Name`] = x.discount_card_name;
        priceObject[`Row ${index + 1} Drug Price`] = x.claim_price;
      });

      try {
        window?.heap?.track("Prices Shown", {
          transactionId,
          waltz_memberid: memberId,
          sourceId,
          ndc: price_ndc,
          quantity: selectedDrugQuantity,
          ...priceObject,
        });
      } catch (error) {
        // console.log('heaperror',error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessPrices]);


  return (
    <Box>
      <Box sx={{ marginTop: "3%" }}>
        <Box>
          {!loading &&
            nonNormalDrug?.length > 0 &&
            nonNormalDrug?.map((x, index) => {
              return (
                <Box sx={{ marginBottom: "24px !important" }} key={index}>
                  <Box
                    sx={
                      index % 2 !== 0
                        ? {
                            display: "flex",
                            borderRadius: "15px",
                            minHeight: "2rem",
                            // padding: "16px 24px 16px 24px",
                            justifyContent: "flex-start",
                            // backgroundColor:"#F7F7F7",
                            border: "1px transparent #B9B6B6",
                          }
                        : {
                            display: "flex",
                            // borderRadius: "15px",
                            minHeight: "2.4rem",
                            padding: "24px 0px",
                            // backgroundColor:"#F7F7F7",
                            background: "none",
                            borderBottom: "1px solid #504847",
                          }
                    }
                  >
                    <Typography
                      color="#676161"
                      fontWeight={500}
                      fontSize={"16px"}
                      lineHeight={"24px"}
                    >
                      {x?.displayMessage}
                    </Typography>
                  </Box>
                </Box>
              );
            })}

          {loading === true
            ? [1, 2, 3].map((x) => {
                return (
                  <Box sx={{ marginBottom: "16px !important" }} key={x}>
                    <Box
                      sx={{
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "15px",
                        height: "3rem",
                        padding: "16px 24px 16px 24px",
                      }}
                    >
                      <Skeleton
                        variant="rectangle"
                        animation="pulse"
                        sx={{
                          transition: "1s",
                          borderRadius: "5px",
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </Box>
                  </Box>
                );
              })
            : useCase23 ?
              lessPrices
                ?.sort((a, b) => a.claim_price - b.claim_price)
                .map((x, index) => {
                  return (
                    <DrugPriceCardUse23
                      key={x.discount_card_name + x.pharmacy_address_1}
                      claimPrice={x.claim_price}
                      discountCardName={x.discount_card_name}
                      selectedDrugName={selectedDrugOption?.name}
                      selectedDrugQuantity={selectedDrugQuantity}
                      priceNdc={selectedDrugOption?.price_ndc}
                      navigationNdc={selectedDrugOption?.navigation_ndc}
                      selectedGpi={selectedDrugOption?.gpi}
                      msCode={selectedDrugOption?.msCode}
                      brandName={selectedDrugOption?.brand_name}
                      dosage={selectedDrugOption?.dosage}
                      form={selectedDrugOption?.form}
                      brandOrGeneric={selectedDrugOption?.brand_generic}
                      networkType={x.network_type}
                      pharmacyDisplayName={x.pharmacy_display_name}
                      transactionId={transactionId}
                      memberId={x.memberId}
                      sourceId={sourceId}
                      packageSize={selectedDrugOption?.packageSize}
                      bnc={selectedDrugOption?.bnc}
                      bin={x.bin}
                      pcn={x.pcn}
                      group={x.group}
                      buttonColor={primaryColor}
                      textColor={primaryColor}
                      handleOpen={handleOpen}
                      index={index}
                      mySavings={false}
                      open={open === index ? true : false}
                      imgLink={imgLink}
                      imgAltText={imgAltText}
                      setModal={setModal}
                      setModalOpen={setModalOpen}
                      openThisCard={() => {
                        setOpen(index);
                        trackCoupon({
                          pharmacyId: "",
                          pharmacyName: x.pharmacy_display_name,
                          drugName: selectedDrugOption?.name,
                          ndc: selectedDrugOption?.price_ndc,
                          gpi14: selectedDrugOption?.gpi,
                          genericName:
                            selectedDrugOption?.brand_generic === "Generic"
                              ? selectedDrugOption?.name
                              : selectedDrugOption?.subtext,
                          brandName: selectedDrugOption?.brand_name,
                          ncpdpId: ncpdpId,
                          mscode: selectedDrugOption?.msCode,
                          bin: x?.bin,
                          pcn: x?.pcn,
                          group: x?.group,
                          type: selectedDrugOption?.form,
                          strength: selectedDrugOption?.dosage,
                          quantity: selectedDrugQuantity,
                          network_type: x?.network_type,
                          discount_card_name: x?.discount_card_name,
                          currency: "USD",
                          amount: `${x?.claim_price}`,
                          transactionId: transactionId,
                          memberId: x?.memberId,
                          sourceId: sourceId,
                          createdOn: "09-Feb-2023",
                          clientId: clientId,
                          packageSize: selectedDrugOption?.packageSize || "",
                          rowNumber: index + 1,
                          waltz_memberid: memberId,
                        });
                      }}
                      closeThisCard={() => setOpen(-1)}
                      primaryColor={primaryColor}
                      tertiaryColor={tertiaryColor}
                      couponDisclaimer={couponDisclaimer}
                      // printPageArea={printPageArea}
                      print={print}
                      setPrint={setPrint}
                      savingsClubDisclaimer={savingsClubDisclaimer}
                      savingsClubText={savingsClubText}
                      savingsClubButtonText={savingsClubButtonText}
                      urlLink={x.url_link}
                      retailBodyText={retailBodyText}
                      retailCardDisclaimer={retailCardDisclaimer}
                      retailImg={retailImg}
                      retailImgAltText={retailImgAltText}
                      couponInstructions={couponInstructions}
                      cardEmail={x.cashCardEmailId}
                      cardPhoneNumber={x.cashCardPhoneNumber}
                      termsAndConditionsURL={x.cashCardTermsAndConditionsURL}
                      brandGeneric={brandGeneric}
                      subtext={subtext}
                      smsDisclaimer={smsDisclaimer}
                      emailDisclaimer={emailDisclaimer}
                      shareModalApi={shareModalApi}
                      pharmacyName={x.pharmacy_display_name}
                      pharmacyAddress={x.pharmacy_address_1}
                      distance={x.distance}
                      poweredByImg={poweredByImg}
                      poweredByAlt={poweredByAlt}
                    />
                  );
                }) : 
                  lessPrices
                ?.sort((a, b) => a.claim_price - b.claim_price)
                .map((x, index) => {
                  return (
                    <DrugPriceCard
                      key={x.discount_card_name + x.pharmacy_address_1}
                      claimPrice={x.claim_price}
                      discountCardName={x.discount_card_name}
                      selectedDrugName={selectedDrugOption?.name}
                      selectedDrugQuantity={selectedDrugQuantity}
                      priceNdc={selectedDrugOption?.price_ndc}
                      navigationNdc={selectedDrugOption?.navigation_ndc}
                      selectedGpi={selectedDrugOption?.gpi}
                      msCode={selectedDrugOption?.msCode}
                      brandName={selectedDrugOption?.brand_name}
                      dosage={selectedDrugOption?.dosage}
                      form={selectedDrugOption?.form}
                      brandOrGeneric={selectedDrugOption?.brand_generic}
                      networkType={x.network_type}
                      pharmacyDisplayName={x.pharmacy_display_name}
                      transactionId={transactionId}
                      memberId={x.memberId}
                      sourceId={sourceId}
                      packageSize={selectedDrugOption?.packageSize}
                      bnc={selectedDrugOption?.bnc}
                      bin={x.bin}
                      pcn={x.pcn}
                      group={x.group}
                      buttonColor={primaryColor}
                      textColor={primaryColor}
                      handleOpen={handleOpen}
                      index={index}
                      mySavings={false}
                      open={open === index ? true : false}
                      imgLink={imgLink}
                      imgAltText={imgAltText}
                      setModal={setModal}
                      setModalOpen={setModalOpen}
                      openThisCard={() => {
                        setOpen(index);
                        trackCoupon({
                          pharmacyId: "",
                          pharmacyName: x.pharmacy_display_name,
                          drugName: selectedDrugOption?.name,
                          ndc: selectedDrugOption?.price_ndc,
                          gpi14: selectedDrugOption?.gpi,
                          genericName:
                            selectedDrugOption?.brand_generic === "Generic"
                              ? selectedDrugOption?.name
                              : selectedDrugOption?.subtext,
                          brandName: selectedDrugOption?.brand_name,
                          ncpdpId: ncpdpId,
                          mscode: selectedDrugOption?.msCode,
                          bin: x?.bin,
                          pcn: x?.pcn,
                          group: x?.group,
                          type: selectedDrugOption?.form,
                          strength: selectedDrugOption?.dosage,
                          quantity: selectedDrugQuantity,
                          network_type: x?.network_type,
                          discount_card_name: x?.discount_card_name,
                          currency: "USD",
                          amount: `${x?.claim_price}`,
                          transactionId: transactionId,
                          memberId: x?.memberId,
                          sourceId: sourceId,
                          createdOn: "09-Feb-2023",
                          clientId: clientId,
                          packageSize: selectedDrugOption?.packageSize || "",
                          rowNumber: index + 1,
                          waltz_memberid: memberId,
                        });
                      }}
                      closeThisCard={() => setOpen(-1)}
                      primaryColor={primaryColor}
                      tertiaryColor={tertiaryColor}
                      couponDisclaimer={couponDisclaimer}
                      // printPageArea={printPageArea}
                      print={print}
                      setPrint={setPrint}
                      savingsClubDisclaimer={savingsClubDisclaimer}
                      savingsClubText={savingsClubText}
                      savingsClubButtonText={savingsClubButtonText}
                      urlLink={x.url_link}
                      retailBodyText={retailBodyText}
                      retailCardDisclaimer={retailCardDisclaimer}
                      retailImg={retailImg}
                      retailImgAltText={retailImgAltText}
                      couponInstructions={couponInstructions}
                      cardEmail={x.cashCardEmailId}
                      cardPhoneNumber={x.cashCardPhoneNumber}
                      termsAndConditionsURL={x.cashCardTermsAndConditionsURL}
                      brandGeneric={brandGeneric}
                      subtext={subtext}
                      smsDisclaimer={smsDisclaimer}
                      emailDisclaimer={emailDisclaimer}
                      shareModalApi={shareModalApi}
                      pharmacyName={x.pharmacy_display_name}
                      pharmacyAddress={x.pharmacy_address_1}
                      distance={x.distance}
                      poweredByImg={poweredByImg}
                      poweredByAlt={poweredByAlt}
                    />
                  );
                })
                }
        </Box>
      </Box>
      {!showAll && !loading && prices?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            marginTop: "16px",
            marginBottom: "16px",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => setShowAll(true)}
            sx={{
              color: "rgb(255, 255, 255)",
              border: `1px solid ${primaryColor}`,
              margin: "5px 0px",
              padding: "6px 16px",
              background: primaryColor,
              lineheight: "normal",
              whiteSpace: "no-wrap",
              textTransform: "inherit",
              borderRadius: "20px",
              "&:hover": {
                color: primaryColor,
                background: "rgb(255, 255, 255)",
                borderColor: primaryColor,
                border: "1px solid",
              },
            }}
          >
            Load More
          </Button>
        </Box>
      )}
    </Box>
  );
};

PriceList.propTypes = {
  memberId: PropTypes.string,
  prices: PropTypes.array,
  selectedDrugOption: PropTypes.object,
  selectedDrugQuantity: PropTypes.string,
  price_ndc: PropTypes.string,
  loading: PropTypes.bool,
  sourceId: PropTypes.string,
  transactionId: PropTypes.string,
  numOfResultsToShow: PropTypes.number,
  primaryColor: PropTypes.string,
  tertiaryColor: PropTypes.string,
  couponDisclaimer: PropTypes.string,
  clientId: PropTypes.string,
  ncpdpId: PropTypes.string,
  trackCoupon: PropTypes.func,
  // printPageArea,
  print: PropTypes.bool,
  setPrint: PropTypes.func,
  nonNormalDrug: PropTypes.array,
  imgLink: PropTypes.string,
  imgAltText: PropTypes.string,
  savingsClubDisclaimer: PropTypes.string,
  savingsClubText: PropTypes.string,
  savingsClubButtonText: PropTypes.string,
  retailBodyText: PropTypes.string,
  retailCardDisclaimer: PropTypes.string,
  retailImg: PropTypes.string,
  retailImgAltText: PropTypes.string,
  couponInstructions: PropTypes.string,
  brandGeneric: PropTypes.string,
  subtext: PropTypes.string,
  setModal: PropTypes.func,
  setModalOpen: PropTypes.func,
  smsDisclaimer: PropTypes.string,
  emailDisclaimer: PropTypes.string,
  shareModalApi: PropTypes.string,
};

export default PriceList;
