import React from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

const DrugSearchButton = ({primaryColor, tertiaryColor, setSearchButtonClick}) => {
  return (
    <Button
    onClick={() => {setSearchButtonClick(true)}}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxHeight: {xs: "48px", md: "60px"},
        textTransform: "none",
        fontSize: { xs: "16px", md: "20px" },
        fontWeight: 600,
        padding: "10px 24px 10px 24px",
        backgroundColor: primaryColor,
        border: `1px solid ${primaryColor}`,
        color: tertiaryColor,
        borderRadius: "50px",
        "&:hover": {
            color: primaryColor,
            backgroundColor: tertiaryColor,
            border: `1px solid ${primaryColor}`
        }
      }}
    >
      Find Low Prices
    </Button>
  );
};

DrugSearchButton.propTypes = {
  primaryColor : PropTypes.string,
  tertiaryColor : PropTypes.string,
  setSearchButtonClick : PropTypes.func
};

export default DrugSearchButton;
