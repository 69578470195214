import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorderRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Drawer from "@mui/material/Drawer";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DrugSearch from "../DrugSearch/DrugSearch";
import PropTypes from 'prop-types'

const TitleBar = ({
  mainLogo,
  numberOfCoupons,
  headerColor,
  headerConfig,
  focusColor,
  logoAltText,
  headerLinkBackgroundColor,
  headerLinkBackgroundHover,
  headerLinkButtonFontColor,
  badgeColor,
  searchInput,
  useCase
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const [mobileLinks, setMobileLinks] = useState([]);
  useEffect(() => {
    let links = [];
    headerConfig.forEach((x) => {
      if (x.child_link.length > 0) {
        links = [...links, ...x.child_link];
      } else {
        links = [...links, x.parent_link];
      }
    });

    setMobileLinks(links);
  }, [headerConfig]);

  let location = useLocation();
  useEffect(() => {
    if (location && location.pathname !== "/" && !location?.pathname?.toLowerCase()?.startsWith("/store/")) {
      setShowSearch(true)
    } else {
      setShowSearch(false);
      setSearchModalOpen(false);
    }
  }, [location]);

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container = window !== undefined ? () => window.document.body : undefined;

  const [searchModalOpen, setSearchModalOpen] = useState(false);

  const handleSearchModalToggle = () => {
    setSearchModalOpen((prevState) => !prevState);
  };

  useEffect(()=>{
    mobileOpen && setSearchModalOpen(false);
  },[mobileOpen])

  const badgeStyle = {
    "& .MuiBadge-badge": {
      color: headerLinkBackgroundColor,
      backgroundColor: badgeColor,
      right: "5px",
      top: "5px",
      width: 14,
      height: 20,
      borderRadius: "50%",
      fontSize: "10px",
      fontWeight: 600,
      display: "flex",
      justifyContent: "center",
    },
  };
  // const clickedBadge = {
  //   "& .MuiBadge-badge": {
  //     color: badgeColor,
  //     backgroundColor: headerLinkBackgroundColor,
  //     right: "6px",
  //     top: "7px",
  //     width: 16,
  //     height: 18,
  //     borderRadius: "50%",
  //   },
  // };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          minHeight: "80px",
          backgroundColor: headerColor,
          justifyContent: "center",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <RouterLink 
              to="/" 
              onClick={()=>setMobileOpen(false)}
            >
              <Box title='image_title'
              sx={{
                height: "53px",
                minWidth:{xs: "130px", sm: "160px"},
                backgroundImage: `url("${mainLogo}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: 'contain',
                backgroundPosition: 'center',
              }}
              alt={logoAltText}
              />
            </RouterLink>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "center",
                display: { xs: "flex" },
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  justifyContent: "center",
                  display: { xs: "none", md: "flex" },
                }}
              >
                {showSearch && (
                  <DrugSearch sbHeight={"2.5"} setMenuOpen={setMenuOpen} searchInput={location?.pathname?.toLowerCase()?.startsWith("/findmedication") ? searchInput : ''}/>
                )}
              </Box>
            </Box>

            {/* Split Mobile and Desktop View Here */}

            {/* Mobile View */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              {searchModalOpen === false ? (
                <Box
                  sx={{
                    flexGrow: 0,
                    display: { xs: showSearch ? "flex" : "none", md: "none" },
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    sx={{ fontSize: "1.5rem", fontWeight: 500 }}
                    size="large"
                    onClick={(e,d) => {handleSearchModalToggle(e,d); setMobileOpen(false);}}
                    color="headerLinkButtonFontColor"
                    aria-label="search"
                  >
                    <SearchIcon alt="Search" />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: showSearch ? "flex" : "none",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: headerLinkButtonFontColor,
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                >
                  <IconButton
                    sx={{ fontSize: "1.5rem", fontWeight: 500 }}
                    size="large"
                    onClick={handleSearchModalToggle}
                  >
                    <SearchIcon
                      alt="Search"
                      color="headerLinkBackgroundColor"
                    />
                  </IconButton>
                </Box>
              )}
              {useCase !== "3" && 
                <Box
                  sx={{
                    flexGrow: 0,
                    display: { xs: "flex", md: "flex" },
                    margin: {xs: "4px", sm: "15px"},
                    width: "40px",
                    height: "40px",
                  }}
                >
                  <Tooltip title="My Savings">
                    <IconButton
                      color="headerLinkButtonFontColor"
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: 500,
                        textTransform: "none",
                        padding: { xs: "6px", sm: "6px" },
                      }}
                      size="large"
                      component={RouterLink}
                      to={"/MySavings"}
                      onClick={()=>{setMobileOpen(false); location.pathname?.toLowerCase() !== "/mysavings" && setSearchModalOpen(false);}}
                    >
                      {numberOfCoupons ? (
                        <Badge
                          badgeContent={numberOfCoupons}
                          color="headerBadges"
                          sx={badgeStyle}
                        >
                          <BookmarkBorderIcon
                            alt="My Savings"
                            color="headerLinkButtonFontColor"
                          />
                        </Badge>
                      ) : (
                        <BookmarkBorderIcon
                          alt="My Savings"
                          color="headerLinkButtonFontColor"
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              }
              {/* ) : (
                <Box
                  sx={{
                    flexGrow: 0,
                    display: { xs: "flex", md: "flex" },
                    margin: {xs: "10px", sm: "15px"},
                    backgroundColor: headerLinkButtonFontColor,
                    borderRadius: "50%",
                  }}
                >
                  <Tooltip title="My Savings">
                    <IconButton
                      color="headerLinkBackgroundColor"
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: 500,
                        textTransform: "none",
                        padding: { xs: "6px", sm: "6px" },
                      }}
                      size="large"
                      component={RouterLink}
                      to={"/MySavings"}
                    >
                      {numberOfCoupons ? (
                        <Badge
                          badgeContent={numberOfCoupons}
                          color="headerLinkBackgroundColor"
                          sx={clickedBadge}
                        >
                          <BookmarkBorderIcon
                            alt="My Savings"
                            color="headerLinkBackgroundColor"
                          />
                        </Badge>
                      ) : (
                        <BookmarkBorderIcon
                          alt="My Savings"
                          color="headerLinkBackgroundColor"
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              )} */}

              {headerConfig.map((x,index) => {
                if (x.child_link.length > 0) {
                  return (
                    <Box
                      key={'menu-'+index}
                      sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}
                    >
                      <Button
                        variant="text"
                        sx={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          color: headerLinkButtonFontColor,
                          background: "none",
                          textTransform: "none",
                          "&:hover": {
                            color: headerLinkBackgroundHover,
                          },
                          "&:focus": {
                            color: headerLinkBackgroundHover,
                          },
                        }}
                        onClick={handleOpenUserMenu}
                        color="headerLinkButtonFontColor"
                      >
                        <Typography
                          variant="subtitle1"
                          color="headerLinkButtonFontColor"
                          sx={{
                            fontWeight: 500,
                            fontSize: "16px",
                            "&:focus": {
                              color: headerLinkBackgroundHover,
                            },
                          }}
                        >
                          Help
                        </Typography>
                        <KeyboardArrowDownIcon
                          alt="My Savings"
                          style={{
                            color: headerLinkButtonFontColor,
                            "&:focus": {
                              color: headerLinkBackgroundHover,
                            },
                          }}
                        />
                      </Button>
                      <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        className="titlebar-menu"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        {x.child_link.map((y) => {
                          if (y.link.href.startsWith("https") === true) {
                            return (
                              <MenuItem
                                key={y.link.href}
                                onClick={handleCloseUserMenu}
                                component={Link}
                                href={`${y.link.href}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Typography textAlign="center">
                                  {y.link.title}
                                </Typography>
                              </MenuItem>
                            );
                          } else {
                            return (
                              <MenuItem
                                key={y.link.title}
                                onClick={handleCloseUserMenu}
                                component={RouterLink}
                                to={`${y.link.href}`}
                              >
                                <Typography textAlign="center">
                                  {y.link.title}
                                </Typography>
                              </MenuItem>
                            );
                          }
                        })}
                      </Menu>
                    </Box>
                  );
                } else {
                  return (
                    <Box
                    key={x.parent_link.link.href}
                      sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}
                    >
                      <Button
                        to={`${x.parent_link.link.href}`}
                        component={RouterLink}
                        sx={{ textTransform: "none" }}
                        color="headerLinkButtonFontColor"
                        aria-label={`link to ${x.parent_link.link.title}`}
                      >
                        <Typography
                          variant="subtitle1"
                          color="headerLinkButtonFontColor"
                          sx={{
                            fontWeight: 500,
                            fontSize: "16px",
                            "&:focus": {
                              color: focusColor,
                            },
                          }}
                        >
                          {x.parent_link.link.title}
                        </Typography>
                      </Button>
                    </Box>
                  );
                }
              })}
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  width: "40px",
                    height: "40px",
                  borderRadius: "50%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {mobileOpen === false ? (
                  <IconButton
                    size="large"
                    aria-label="navigation menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleDrawerToggle}
                    color="headerLinkButtonFontColor"
                  >
                    <MenuIcon />
                  </IconButton>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: headerLinkButtonFontColor,
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                    }}
                  >
                    <IconButton
                      size="large"
                      aria-label="close menu"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleDrawerToggle}
                      color="headerLinkBackgroundColor"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}
                <Menu
                  // id="menu-appbar"
                  //   anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  //   open={Boolean(anchorElNav)}
                  open={false}
                  onClose={handleDrawerToggle}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {/* {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))} */}
                </Menu>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
      <Box component="nav">
        <Drawer
          container={container}
          anchor="top"
          variant="temporary"
          open={searchModalOpen}
          onClose={handleSearchModalToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            //   display: { sm: 'block' },
            backdropFilter: "blur(1px)",
            flexShrink: 0,
            "& .MuiDrawer-paper": { boxSizing: "border-box", height: "140px" },
          }}
        >
          {searchModalOpen && showSearch && (
            <DrugSearch
              sbHeight={"3.5"}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              modalView
              onSelection={() => setSearchModalOpen(false)}
            />
          )}
        </Drawer>

        <Drawer
          container={container}
          anchor="right"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            //   display: { sm: 'block' },
            backdropFilter: "blur(1px)",
            flexShrink: 0,
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: "276px" },
          }}
        >
          <Box sx={{ height: "80px" }} />

          <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            {mobileLinks.map((x) => {
              if (x.link.href.startsWith("https") === true) {
                return (
                  <MenuItem
                    key={x.link.href}
                    onClick={handleCloseUserMenu}
                    component={Link}
                    href={`${x.link.href}`}
                    aria-label={`link to ${x.link.title}`}
                  >
                    <Typography textAlign="center">{x.link.title}</Typography>
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    key={x.link.title}
                    onClick={handleCloseUserMenu}
                    component={RouterLink}
                    to={`${x.link.href}`}
                    aria-label={`link to ${x.link.title}`}
                  >
                    <Typography textAlign="center">{x.link.title}</Typography>
                  </MenuItem>
                );
              }
            })}
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};


TitleBar.propTypes = {
  mainLogo : PropTypes.string,
  numberOfCoupons : PropTypes.number,
  headerColor : PropTypes.string,
  headerConfig : PropTypes.array,
  focusColor : PropTypes.string,
  logoAltText : PropTypes.string,
  headerLinkBackgroundColor : PropTypes.string,
  headerLinkBackgroundHover : PropTypes.string,
  headerLinkButtonFontColor : PropTypes.string,
  badgeColor : PropTypes.string,
  searchInput : PropTypes.string,
  useCase : PropTypes.string
}

export default TitleBar;
