import axios from 'axios';


const getClient = async (useCache) => {

  const clientId = sessionStorage.getItem('clientId'); 
  const apiKey = sessionStorage.getItem('apiKey'); 
  const apiUrl = sessionStorage.getItem('apiUrl'); 
  const browser_version = sessionStorage.getItem('browser_version');
  const user_agent = sessionStorage.getItem('user_agent');
  const os_source_and_version = sessionStorage.getItem('os_source_and_version');
  const cache = await caches.open(`${clientId}-${apiUrl}`);

  const options = {
    baseURL: apiUrl?.includes('localhost') ? `http://${apiUrl}` : `https://${apiUrl}`,
    timeout: 900000,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'browser_version': browser_version,
      'client_id': clientId,
      'clientId': clientId,
      'Ocp-Apim-Subscription-Key': apiKey,
      'os_source_and_version': os_source_and_version,
      'user_agent':user_agent
    }
  };

  const client = axios.create(options);

  if (useCache) {

    const requestHandler = async (request) => {
      if (request.method === 'GET' || 'get') {
        const response = await cache.match(request.baseURL + request.url)
        if (response) {
          request.headers.cached = true;
          request.data = await response.json();
          return Promise.reject(request);
        } else {
          return request;
        }
      } else {
        return request;
      }
    };
  
    // Add a request interceptor
    client.interceptors.request.use(
      requestConfig => requestHandler(requestConfig),
      (requestError) => {
        // this is where we could universally log request errors
        console.log('Request error: ', requestError);
        return Promise.reject(requestError);
      },
    );
  
    // Add a response interceptor
    client.interceptors.response.use(
      (response) => {
        if ((response.config?.method === 'GET' || 'get') && [200,201,202,203,204,205].includes(response.status)) {
          const now = Date.now();
          response.headers.timeCached = new Date(now).toLocaleString();
          cache.put(response.config.baseURL + response.config.url, new Response(JSON.stringify(response.data), response));
        }
        return response;
      },
      (error) => {
        if (error.headers?.cached === true) {
          return Promise.resolve(error);
        }
        return Promise.reject(error);
      }
    );
  
    return client;

  } else { //below is what we do for all requests where we don't explicitly say useCache

    // Add a request interceptor
    client.interceptors.request.use(
      requestConfig => requestConfig,
      (requestError) => {
        // this is where we could universally log request errors
        console.log('Request error: ', requestError);

        return Promise.reject(requestError);
      },
    );

    // Add a response interceptor
    client.interceptors.response.use(
      async (response) => {
        const isCached = await cache.match(response.request?.responseURL);
        if (!!isCached && (response.config?.method === 'GET' || 'get') && [200,201,202,203,204,205].includes(response.status)) {
          const now = Date.now();
          response.headers.timeCached = new Date(now).toLocaleString();
          cache.put(response.config.baseURL + response.config.url, new Response(JSON.stringify(response.data), response));
        }
        return response;
      },
      (error) => {
        if (error?.response?.status >= 400) {
          // this is where we could universally log response errors
          console.log('Response error: ', error?.response);
          console.log('response when error: ', error?.response?.data?.body);
        }

        return Promise.reject(error);
      },
    );

    return client;
  }
};


const API = {
  async get(url, { conf = {}, useCache = false } = {}) {
    const cli = await getClient(useCache);
    return cli.get(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  async delete(url, { conf = {}, useCache = false } = {}) {
    const cli = await getClient(useCache);
    return cli.delete(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  async head(url, { conf = {}, useCache = false } = {}) {
    const cli = await getClient(useCache);
    return cli.head(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  async options(url, { conf = {}, useCache = false } = {}) {
    const cli = await getClient(useCache);
    return cli.options(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  async post(url, { data = {}, conf = {}, useCache = false } = {}) {
    const cli = await getClient(useCache);
    return cli.post(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  async put(url, { data = {}, conf = {}, useCache = false } = {}) {
    const cli = await getClient(useCache);
    return cli.put(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  async patch(url, { data = {}, conf = {}, useCache = false } = {}) {
    const cli = await getClient(useCache);
    return cli.patch(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
 
};

export default API;