import { createTheme } from "@mui/material/styles";


const createThemeService = (config) => {
    return createTheme({
        palette: {
          primary: {
            main: config?.color_configurationConnection?.primary_color?.hex,
          },
          secondary: {
            // main: config?.color_configurationConnection?.secondary_color?.hex,
            main: config?.color_configurationConnection?.accent_tertiary_color?.hex,
          },
          tertiary: {
             main: config?.color_configurationConnection?.secondary_color?.hex,
          },
          focus: {
            main: config?.color_configurationConnection?.link_pressed_color?.hex,
          },
          opacity: {
            main: `rgba(${config?.color_configurationConnection?.primary_color?.rgb?.r}, ${config?.color_configurationConnection?.primary_color?.rgb?.g}, ${config?.color_configurationConnection?.primary_color?.rgb?.b}, 0.15)`,
          },
          hoverOpacity: {
            main: `rgba(${config?.color_configurationConnection?.primary_color?.rgb?.r}, ${config?.color_configurationConnection?.primary_color?.rgb?.g}, ${config?.color_configurationConnection?.primary_color?.rgb?.b}, 0.5)`,
          },
          titlebarBackground: {
            main: config?.color_configurationConnection?.header_bar_color_fill?.hex,
          },
          headerLinkBackgroundColor: {
            main: config?.color_configurationConnection?.web_header_link_buttons_background_fill_color_default?.hex,
          },
          headerLinkBackgroundHover: {
            main: config?.color_configurationConnection?.web_header_link_buttons_background_fill_color_hover?.hex,
          },
          headerLinkButtonFontColor: {
            main: config?.color_configurationConnection?.web_header_link_buttons_font_color?.hex,
          },
          headerBadges: {
            main: config?.color_configurationConnection?.header_badges?.hex,
          },
          footer_background_color: {
            main: config?.color_configuration?.footer_background_color?.hex,
          },
          footer_legal_disclaimer_color: {
            main: config?.color_configuration?.footer_legal_disclaimer_color?.hex,
          },
          drugInfoLinkHover: {
            main: "rgba(80,72,71, 3)"
          },
          drugInfoLinkPressed: {
            main: "rgba(80,72,71, 2)"
          },
          title: {
            main: "rgba(80, 72, 71, 1)",
          },
          subtitle: {
            main: "rgba(41, 41, 51, 1)",
          },
          body: {
            main: "rgba(117, 117, 147, 1)",
          },
          placeholder: {
            main: "rgba(185, 182, 182, 1)",
          },
          grey: {
            main: "rgba(202, 202, 206, 1)",
          },
          hoverGrey: {
            main: "rgba(237, 237, 237, 1)",
          },
        },
        components: {
          MuiAutocomplete: {
            ListBox: {
              styleOverrides: {
                root: {
                  borderRadius: 0,
                },
              },
            },
          },
         MuiFormHelperText: {
          styleOverrides: {
            root: {
              color: "#DD1E24",
              "&$error": {
                color: "#DD1E24",
           }
            }
          }
         },
          MuiSelect: {
            styleOverrides: {
              root: {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(202, 202, 206, 1)"
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  borderWidth: "1px"
                },
              },
            }
          },
          // MuiButton: {
          //   styleOverrides: {
          //     root: {
          //       backgroundColor: config?.color_configurationConnection?.primary_color?.hex,
          //       color: config?.color_configurationConnection?.secondary_color?.hex,
          //       // border: `1px solid ${config?.color_configurationConnection?.primary_color?.hex}`,
          //       "&:hover": {
          //         color: config?.color_configurationConnection?.primary_color?.hex,
          //         backgroundColor: config?.color_configurationConnection?.primary_color?.hex,
          //         border: `1px solid ${config?.color_configurationConnection?.primary_color?.hex}`,
          //       },
          //       "&:focus":  {
          //         color: config?.color_configurationConnection?.primary_color?.hex,
          //         backgroundColor: config?.color_configurationConnection?.primary_color?.hex,
          //         border: `1px solid ${config?.color_configurationConnection?.primary_color?.hex}`,
          //       },
          //       "&:active":  {
          //         color: config?.color_configurationConnection?.primary_color?.hex,
          //         backgroundColor: config?.color_configurationConnection?.primary_color?.hex,
          //         border: `1px solid ${config?.color_configurationConnection?.primary_color?.hex}`,
          //       },
          //     }
          //   }
          // },
          MuiModal: {
            styleOverrides: {
              root: {
                borderRadius: "0px",
                backgroundColor: "none",
                // backdropFilter: "blur(3px)",            
              },
            },
          },

        },
        typography: {
          fontFamily: config?.marketing?.font_family,
        },
        link: {
          fontFamily: config?.marketing?.font_family,
        },
        button: {
          backgroundColor: config?.color_configurationConnection?.primary_color?.hex,
        },
      })
};

export default createThemeService;