import React, { Fragment, useRef, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ShareModal from "../ShareCouponModal/ShareCouponModal";
import DeleteCoupon from "../DeleteCoupon/DeleteCoupon";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useReactToPrint } from "react-to-print";
import PropTypes from "prop-types";

const CouponCard = ({
  navigationNdc,
  open,
  discountCardName,
  deleteCoupon,
  savedCoupons,
  openThisCard,
  closeThisCard,
  genericName,
  brandName,
  quantityAndDose,
  pharmacyDisplayName,
  brandGeneric,
  name,
  form,
  dosage,
  quantity,
  bin,
  pcn,
  group,
  memberId,
  claimPrice,
  config,
  primaryColor,
  tertiaryColor,
  couponDisclaimer,
  networkType,
  couponInstructions,
  cardEmail,
  cardPhoneNumber,
  termsAndConditionsURL,
  setModal,
  setModalOpen,
  smsDisclaimer,
  emailDisclaimer,
  shareModalApi
}) => {
  const [print, setPrint] = useState(false);

  const current = new Date().toLocaleDateString();
  const scrollRef = useRef();

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Coupon-Card",
    onAfterPrint: () => setPrint(false),
  });
  
  useEffect(() => {
    print === true && handlePrint();
  }, [print, handlePrint]);

  useEffect(() => {
    if (open) {
      scrollRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [open]);

  const chooseTitle = () => {
    if (networkType === "Retail") {
      return "RETAIL PRICE";
    } else {
      return "WITH COUPON";
    }
  };
  // const updatedPhoneFormat = () => {
  //   const phoneNumber = cardPhoneNumber.replace(/[^\d]/g, "");
  //   if (phoneNumber.length === 11) {
  //     return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
  //       1,
  //       4
  //     )})-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
  //   } else {
  //     return `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(
  //       3,
  //       6
  //     )}-${phoneNumber.slice(6, 10)}`;
  //   }
  // };

  return (
    <Fragment>
      { name === undefined || quantityAndDose === undefined ? (
        <Fragment></Fragment>
      ) : (
      <Box
        ref={scrollRef}
        sx={{
          marginBottom: "16px !important",
        }}
      >
        {open === false ? (
          <Box
            sx={{
              color: "rgba(255, 255, 255, 1)",
              fontSize: "12px",
              boxShadow: "1px 2px 4px 1px rgba(0, 0, 0, 0.25)",
              display: "flex",
              justifyContent: "center",
              alignItems: "space-between",
              padding: { xs: "16px", md: "24px 24px 16px 24px" },
              borderRadius: "15px",
              maxWidth: "100%",
              backgroundColor: "rgba(255, 255, 255, 1)",
              minHeight: { xs: "154px", md: "160px" },
            }}
          >
            <Fragment>
              <Grid container>
                <Grid
                  item
                  xs={8}
                  sm={9}
                  md={8}
                  lg={9}
                  sx={{ display: "flex", cursor: "pointer" }}
                  onClick={openThisCard}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(80, 72, 71, 1)",
                        fontSize: { xs: "16px", lg: "20px" },
                        fontWeight: "600",
                      }}
                    >
                      {name}
                    </Typography>
                    {brandName === "undefined" || brandName === "" ? (
                      <Typography
                        sx={{
                          color: "#504847",
                          fontSize: { xs: "10px", lg: "14px" },
                          fontWeight: "400",
                        }}
                      >
                        Generic
                      </Typography>
                    ) : brandGeneric === "Generic" ? (
                      <Typography
                        sx={{
                          color: "#504847",
                          fontSize: { xs: "10px", lg: "14px" },
                          fontWeight: "400",
                        }}
                      >
                        Generic for {brandName}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "#504847",
                          fontSize: { xs: "10px", lg: "14px" },
                          fontWeight: "400",
                        }}
                      >
                        Brand for {genericName}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={3}
                  md={4}
                  lg={3}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Box
                    sx={{
                      display: { xs: "none", md: "flex" },
                      height: { xs: "16px", md: "22px" },
                      backgroundColor: "rgba(117, 117, 147, 0.1)",
                      borderRadius: "48px",
                      whiteSpace: "nowrap",
                      padding: "2px 16px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#757593",
                        fontSize: { xs: "12px", lg: "14px" },
                        fontWeight: 600,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {discountCardName}
                    </Typography>
                  </Box>
                  <Button
                    aria-label={"delete coupon"}
                    disableRipple={true}
                    onClick={() =>
                      setModal(
                        <DeleteCoupon
                          setModalOpen={setModalOpen}
                          primaryColor={primaryColor}
                          tertiaryColor={tertiaryColor}
                          deleteCoupon={deleteCoupon}
                          navigationNdc={navigationNdc}
                          quantity={quantity}
                          savedCoupons={savedCoupons}
                          name={name}
                        />
                      )
                    }
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      color: "rgba(80, 72, 71, 1)",
                      justifyContent: "flex-end",
                      padding: "0px",
                      maxWidth: "16px",
                      background: "none",
                      "&:hover": {
                        background: "none",
                        textTransform: "none",
                      },
                    }}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={9}
                  md={9}
                  lg={10}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    cursor: "pointer",
                  }}
                  onClick={openThisCard}
                >
                  <Typography
                    sx={{
                      color: "#757593",
                      fontSize: { xs: "12px", lg: "16px" },
                      fontWeight: "400",
                    }}
                  >
                    {quantityAndDose}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={3}
                  md={3}
                  lg={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                  onClick={openThisCard}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: { xs: "space-between", md: "flex-end" },
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      { discountCardName.length > 0 && (
                        <Box
                          sx={{
                            display: { xs: "flex", md: "none" },
                            height: { xs: "16px", md: "22px" },
                            backgroundColor: "rgba(117, 117, 147, 0.1)",
                            borderRadius: "48px",
                            padding: "2px 16px",
                            alignItems: "flex-start",
                            marginRight: "1.5em",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#757593",
                              fontSize: { xs: "12px", lg: "14px" },
                              fontWeight: 600,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {discountCardName}
                          </Typography>
                        </Box>
                      )
                      }
                      <Button
                        variant="text"
                        sx={{
                          color: "rgba(80, 72, 71, 1)",
                          background: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          padding: "0px",
                          "&:hover": {
                            color: "rgba(80, 72, 71, 1)",
                            background: "none",
                          },
                          "&:active": {
                            color: "rgba(80, 72, 71, 1)",
                            background: "none",
                          },
                          "&:focus": {
                            color: "rgba(80, 72, 71, 1)",
                            background: "none",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              fontSize: { xs: "12px", lg: "16px" },
                              fontWeight: 600,
                              color: "rgba(80, 72, 71, 1)",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {chooseTitle()}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: { xs: "20px", lg: "24px" },
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                              fontWeight: "700",
                              color: "rgba(80, 72, 71, 1)",
                            }}
                          >
                            $
                            {claimPrice !== null && !isNaN(Number(claimPrice))
                              ? claimPrice.toLocaleString("en", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : claimPrice}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          <KeyboardArrowRightOutlinedIcon />
                        </Box>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          </Box>
        ) : (
          <Fragment>
            <Box
              sx={{
                color: "rgba(255, 255, 255, 1)",
                fontSize: "12px",
                boxShadow: "1px 2px 4px 1px rgba(0, 0, 0, 0.25)",
                display: "flex",
                justifyContent: "center",
                alignItems: "space-between",
                padding: { xs: "16px", md: "24px" },
                borderRadius: "15px",
                maxWidth: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                margin: print === true ? "24px" : "0px"
              }}
              ref={printRef} 
            >
              <Grid container spacing={{ xs: 0, md: 1 }}>
                <Grid
                  item
                  xs={8}
                  sm={8}
                  lg={9}
                  sx={{
                    display: "flex",
                    
                    height: {
                      xs: "70px",
                      sm: "70px",
                      md: "112px",
                      lg: "100px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(80, 72, 71, 1)",
                        fontSize: { xs: "16px", lg: "20px" },
                        fontWeight: "600",
                      }}
                    >
                      {name}
                    </Typography>
                    {brandName === "undefined" || brandName === "" ? (
                      <Typography
                        sx={{
                          color: "#504847",
                          fontSize: { xs: "10px", lg: "14px" },
                          fontWeight: "400",
                        }}
                      >
                        Generic
                      </Typography>
                    ) : brandGeneric === "Generic" ? (
                      <Typography
                        sx={{
                          color: "#504847",
                          fontSize: { xs: "10px", lg: "14px" },
                          fontWeight: "400",
                        }}
                      >
                        Generic for {brandName}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "#504847",
                          fontSize: { xs: "10px", lg: "14px" },
                          fontWeight: "400",
                        }}
                      >
                        Brand for {genericName}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  lg={3}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  
                  { discountCardName.length > 0 && (
                  <Box
                    sx={{
                      display: { xs: "none", md: "flex" },
                      height: { xs: "16px", md: "22px" },
                      backgroundColor: "rgba(117, 117, 147, 0.1)",
                      borderRadius: "48px",
                      whiteSpace: "nowrap",
                      padding: "2px 16px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#757593",
                        fontSize: { xs: "12px", lg: "14px" },
                        fontWeight: 600,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {discountCardName}
                    </Typography>
                  </Box>
                  )}
                  <Button
                    aria-label={"delete coupon"}
                    disableRipple={true}
                    onClick={() => {
                      setModal(
                        <DeleteCoupon
                          setModalOpen={setModalOpen}
                          primaryColor={primaryColor}
                          tertiaryColor={tertiaryColor}
                          deleteCoupon={deleteCoupon}
                          navigationNdc={navigationNdc}
                          quantity={quantity}
                          savedCoupons={savedCoupons}
                          name={name}
                        />
                      );
                    }}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      color: "rgba(80, 72, 71, 1)",
                      justifyContent: "flex-end",
                      padding: "0px",
                      background: "none",
                      "&:hover": {
                        background: "none",
                        backgroundColor: "none",
                        textTransform: "none",
                      },
                    }}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={9}
                  md={9}
                  lg={10}
                  sx={{ display: "flex", alignItems: "flex-end"}}
                >
                  <Typography
                    sx={{
                      color: "#757593",
                      fontSize: { xs: "12px", lg: "16px" },
                      fontWeight: "400",
                    }}
                  >
                    {quantityAndDose}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={2}>
                  <Box
                    sx={{
                      display: "flex",
                      alignContent: "center",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Fragment>
                      <Fragment>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: {
                                xs: "space-between",
                                md: "flex-end",
                              },
                              flexDirection: "column",
                              alignItems: "flex-end",
                            }}
                          >
                            
                      { discountCardName.length > 0 && (
                            <Box
                              sx={{
                                display: { xs: "flex", md: "none" },
                                height: { xs: "16px", lg: "22px" },
                                backgroundColor: "rgba(117, 117, 147, 0.1)",
                                borderRadius: "48px",
                                padding: "2px 16px",
                                alignItems: "flex-start",
                                marginRight: "1.5em",
                                marginBottom: "16px",
                              }}
                            >
                              <Typography
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  fontSize: { xs: "12px", lg: "16px" },
                                  fontWeight: 600,
                                  color: "#757593",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {discountCardName}
                              </Typography>
                            </Box>
                      )}
                            <Button
                              variant="text"
                              onClick={closeThisCard}
                              sx={{
                                color: "rgba(80, 72, 71, 1)",
                                background: "none",
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end",

                                padding: "0px",
                                "&:hover": {
                                  color: "rgba(80, 72, 71, 1)",
                                  background: "none",
                                },
                                "&:active": {
                                  color: "rgba(80, 72, 71, 1)",
                                  background: "none",
                                },
                                "&:focus": {
                                  color: "rgba(80, 72, 71, 1)",
                                  background: "none",
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-end",
                                    fontSize: { xs: "12px", lg: "16px" },
                                    fontWeight: 600,
                                    color: "#504847",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {chooseTitle()}
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    fontSize: { xs: "20px", lg: "24px" },
                                    fontWeight: "700",
                                    color: "#504847",
                                  }}
                                >
                                  $
                                  {claimPrice !== null &&
                                  !isNaN(Number(claimPrice))
                                    ? claimPrice.toLocaleString("en", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : claimPrice}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignSelf: "center",
                                }}
                              >
                                <KeyboardArrowDownOutlinedIcon />
                              </Box>
                            </Button>
                          </Grid>
                        </Grid>
                      </Fragment>
                    </Fragment>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    borderBottom: "1px solid #B9B6B6",
                    maxWidth: "100%",
                    display: "flex",
                    justifyContent: "center",
                    maxHeight: "1px",
                    marginTop: {xs: "16px", md: "0px"}
                  }}
                ></Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: { xs: "16px 0px", sm: "16px 0px" },
                  }}
                >
                  <Typography
                    color="#757593"
                    sx={{
                      fontSize: { xs: "12px", lg: "16px", fontWeight: 600 },
                    }}
                  >
                    {couponInstructions}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ padding: "0px" }}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      md={9}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        padding: "0px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "18px", lg: "20px" },
                          fontWeight: "400",
                          color: "rgba(80, 72, 71, 1)",
                        }}
                      >
                        BIN: <b>{bin}</b>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "18px", lg: "20px" },
                          fontWeight: "400",
                          color: "rgba(80, 72, 71, 1)",
                        }}
                      >
                        PCN: <b>{pcn}</b>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "18px", lg: "20px" },
                          fontWeight: "400",
                          color: "rgba(80, 72, 71, 1)",
                        }}
                      >
                        GROUP: <b>{group}</b>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "18px", lg: "20px" },
                          fontWeight: "400",
                          color: "rgba(80, 72, 71, 1)",
                        }}
                      >
                        MEMBER ID: <b>{memberId?.toUpperCase()}</b>
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "rgba(117, 117, 147, 1)",
                          fontSize: { xs: "10px", sm: "12px", lg: "14px" },
                        }}
                      >
                        Generated: {current}
                      </Typography>
                      <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{
                          __html: couponDisclaimer
                            ?.replace(
                              "%%client_name%%",
                              pharmacyDisplayName || ""
                            )
                            ?.replace(
                              "%%discount_card_name%%",
                              discountCardName || ""
                            ),
                        }}
                        sx={{
                          color: "rgba(117, 117, 147, 1)",
                          fontSize: { xs: "8px", sm: "10px", lg: "14px" },
                        }}
                      >
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={0}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={() => setPrint(true)}
                        sx={{
                          display: {
                            xs: print === true ? "none" : "flex",
                          },
                          width: "49%",
                          height: { xs: "32px", lg: "48px" },
                          backgroundColor: primaryColor,
                          color: tertiaryColor,
                          border: `1px solid ${primaryColor}`,
                          borderRadius: "40px",
                          textTransform: "none",
                          "&:hover": {
                            color: primaryColor,
                            border: `1px solid ${primaryColor}`,
                            backgroundColor: tertiaryColor,
                          },
                        }}
                      >
                        <PrintOutlinedIcon />
                        Print
                      </Button>
                      <Button
                        onClick={() =>
                          setModal(
                            <ShareModal
                              quantityAndDose={quantityAndDose}
                              setModalOpen={setModalOpen}
                              name={name}
                              form={form}
                              strength={dosage}
                              quantity={quantity}
                              bin={bin}
                              pcn={pcn}
                              group={group}
                              memberId={memberId}
                              discountCardName={discountCardName}
                              price={
                                claimPrice !== null &&
                                !isNaN(Number(claimPrice))
                                  ? claimPrice.toLocaleString("en", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : claimPrice
                              }
                              primaryColor={primaryColor}
                              tertiaryColor={tertiaryColor}
                              setModal={setModal}
                              smsDisclaimer={smsDisclaimer}
                              emailDisclaimer={emailDisclaimer}
                              shareModalApi={shareModalApi}
                            />
                          )
                        }
                        sx={{
                          display: {
                            xs: print === true ? "none" : "flex",
                          },
                          width: "49%",
                          height: { xs: "32px", lg: "48px" },
                          backgroundColor: primaryColor,
                          color: tertiaryColor,
                          border: `1px solid ${primaryColor}`,
                          borderRadius: "40px",
                          textTransform: "none",
                          "&:hover": {
                            color: primaryColor,
                            border: `1px solid ${primaryColor}`,
                            backgroundColor: tertiaryColor,
                          },
                        }}
                      >
                        <ShareOutlinedIcon />
                        Share
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {/* </Box> */}
          </Fragment>
        )}
      </Box>
      )}
    </Fragment>
  );
};

CouponCard.propTypes = {
  navigationNdc: PropTypes.string,
  open : PropTypes.bool,
  discountCardName : PropTypes.string,
  deleteCoupon : PropTypes.func,
  savedCoupons : PropTypes.array,
  openThisCard : PropTypes.func,
  closeThisCard : PropTypes.func,
  genericName : PropTypes.string,
  brandName : PropTypes.string,
  quantityAndDose : PropTypes.string,
  pharmacyDisplayName : PropTypes.string,
  brandGeneric : PropTypes.string,
  name : PropTypes.string,
  form : PropTypes.string,
  dosage : PropTypes.string,
  quantity : PropTypes.string,
  bin : PropTypes.string,
  pcn : PropTypes.string,
  group : PropTypes.string,
  memberId : PropTypes.string,
  claimPrice : PropTypes.number,
  config : PropTypes.object,
  primaryColor : PropTypes.string,
  tertiaryColor : PropTypes.string,
  couponDisclaimer : PropTypes.string,
  networkType : PropTypes.string,
  couponInstructions : PropTypes.string,
  cardEmail : PropTypes.string,
  cardPhoneNumber : PropTypes.string,
  termsAndConditionsURL : PropTypes.string,
  setModal : PropTypes.func,
  setModalOpen : PropTypes.func,
  smsDisclaimer: PropTypes.string,
  emailDisclaimer: PropTypes.string,
  shareModalApi: PropTypes.string,
};


export default CouponCard;
