import React, { useState, useEffect } from "react";
import { renderToString } from 'react-dom/server';
import useScript from "../../Services/useScript";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import FmdGoodIcon from '@mui/icons-material/FmdGood';
// import GoogleMapReact from 'google-map-react'

const GoogleMap = ({ center, zoom, prices, setShowCoupon, showCoupon, setCouponNumber }) => {

  const googleMapsStatus = useScript(
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyDfKMdsSTLxNt2xvi7fWze5QIdJrCCzdE8&v=weekly&libraries=marker"
  );

  const [mapState, setMapState] = useState();
  const [markers, setMarkers] = useState([]);



  useEffect(() => {

    const initMap = () => {
      if (!!window.google && !mapState) {
        const map = new window.google.maps.Map(document.getElementById("map"), {
          center: center,
          zoom: zoom,
        });
        setMapState(map);
      }
    }
    
    if (googleMapsStatus === "ready") {
      initMap();
    }
  }, [googleMapsStatus, center])


  useEffect(() => {

    const updateMarkers = () => {
      setMarkers((oldMarkers) => {
        oldMarkers.forEach((x) =>{
          x.setMap(null);
        });

        const min_price = Math.min(...prices.map(item => item.claim_price));

        return prices.map((marker, i) => {
        const svgMarker = {
          path: "M 10.9987 13.9998 C 11.732 13.9998 12.36 13.7385 12.8827 13.2158 C 13.4045 12.6941 13.6654 12.0665 13.6654 11.3332 C 13.6654 10.5998 13.4045 9.97184 12.8827 9.44917 C 12.36 8.92739 11.732 8.6665 10.9987 8.6665 C 10.2654 8.6665 9.63781 8.92739 9.11603 9.44917 C 8.59337 9.97184 8.33203 10.5998 8.33203 11.3332 C 8.33203 12.0665 8.59337 12.6941 9.11603 13.2158 C 9.63781 13.7385 10.2654 13.9998 10.9987 13.9998 Z M 10.9987 26.8332 C 10.8209 26.8332 10.6431 26.7998 10.4654 26.7332 C 10.2876 26.6665 10.132 26.5776 9.9987 26.4665 C 6.75425 23.5998 4.33203 20.9389 2.73203 18.4838 C 1.13203 16.0278 0.332031 13.7332 0.332031 11.5998 C 0.332031 8.2665 1.40448 5.61095 3.54936 3.63317 C 5.69337 1.65539 8.17648 0.666504 10.9987 0.666504 C 13.8209 0.666504 16.304 1.65539 18.448 3.63317 C 20.5929 5.61095 21.6654 8.2665 21.6654 11.5998 C 21.6654 13.7332 20.8654 16.0278 19.2654 18.4838 C 17.6654 20.9389 15.2431 23.5998 11.9987 26.4665 C 11.8654 26.5776 11.7098 26.6665 11.532 26.7332 C 11.3543 26.7998 11.1765 26.8332 10.9987 26.8332 Z",
          fillColor: marker.claim_price === min_price ? "#02643A" : "#504847",
          fillOpacity: 1,
          strokeWeight: 0,
          rotation: 0,
          scale: 1.25,
          // anchor: new window.google.maps.Point(0, 20),
        };


        const contentString = renderToString(
          <div className="infowindow" id={`infowindow${i}`} style={{display: "flex", flexDirection: "column", justifyContent: "center",
            //  width: "68px", 
             height: "46px", gap: "5px", cursor: "pointer"}}>
            {/* <button  onClick={() => {setShowCoupon(true) && setCouponNumber(i)}} style={{width: "100%", height: "100%", backgroundColor: "none", border: "none"}}> */}
            {marker.claim_price === min_price ? (
              <div style={{
                // width: "62px", 
                height: "15px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: "rgba(3, 167, 127, 0.2)", borderRadius: "14px", padding: "1px 2px"}}>
                <p style={{fontSize: "10px", color: "#02643A", fontWeight: 500, margin: 0}}>
                  Lowest Price
                </p>
              </div>
            ) : (
              <p style={{fontSize: "10px", display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 600, color: "#504847", height: "12px", margin: "0px"}}>GET IT FOR</p>
            )}
            <p style={{fontSize: "16px", display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 600, color: "#504847", height: "15px", margin: "0px"}}>${marker.claim_price}</p>
            {/* </button> */}
          </div>
        );

        const infowindow = new window.google.maps.InfoWindow({
          content: contentString,
          ariaLabel: `Claim Price $${marker.claim_price}`,
        });

        const make_marker = new window.google.maps.Marker({
          position: { lat: marker.latitude, lng: marker.longitude },
          map: mapState,
          icon: svgMarker,
        });

        
        window.google.maps.event.addListener(infowindow, 'domready', ()=> {
          document.getElementById(`infowindow${i}`).addEventListener('click', () => {
            setShowCoupon(true);
            setCouponNumber(i);
          });
        })

        infowindow.open({
          anchor: make_marker,
          mapState,
        });

        return make_marker;
      });
      })

    };
    if (!!mapState) {
      updateMarkers();
    }
    // window.initMap = initMap;
  }, [mapState, JSON.stringify(prices)]);

  return (
    <Box sx={{ height: {xs: "450px", sm: "600px"}, width: "100%" }} id="map" className="map" />
  );
};

export default GoogleMap;
