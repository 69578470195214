import React from "react";
import { Box} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import PropTypes from 'prop-types'

const SurveyModal = ({ setModalOpen, primaryColor, tertiaryColor, surveyUrl }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        // top: { xs: "35%", sm: "30%", md: "40%" },
        // left: "auto",
        // transform: "translate(-50%, -50%)",
        left: {xs: "5%", md: "15%", lg: "20%" },
        top: { xs: "10%", sm: "10%", md: "20%", lg: "25%"},
        width: { xs: "90%", sm: "80%", md: "75%", lg: "60%" },
        height: { xs: "70%", sm: "65%", md: "60%", lg: "50%" },
        // maxHeight: { xs: "400px", sm: "350px", md: "450px", xl: "40%" },
        // minHeight: "475px",
        bgcolor: "background.paper",
        border: "0.5px solid #000",
        borderRadius: "16px",
        boxShadow: 24,
        padding: 0,
        display: "flex",
        verticalAlign: "middle",
        // alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={10}
          sx={{
            display: "flex",
            // position: "absolute",
            height: "100%",
            width: "100%",
            padding: 0,
          }}
        >
          <Box
            maxWidth="100%"
            sx={{
              display: "flex",
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          >
            <iframe title="npssurvey"
              src={surveyUrl}
              frameborder="0"
              height="450"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "16px"
              }}
            ></iframe>
          </Box>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          }}
        >
          <IconButton onClick={() => setModalOpen(false)}>
            <CloseIcon color="title" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

SurveyModal.propTypes = {
  setModalOpen : PropTypes.func, 
  primaryColor : PropTypes.string, 
  tertiaryColor : PropTypes.string, 
  surveyUrl : PropTypes.string,
}

export default SurveyModal;
