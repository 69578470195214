import React, {Fragment} from 'react';
import {Box, Typography, Container} from '@mui/material';
import PropTypes from 'prop-types'

const PricingFooter = ({pricingFooterConfig}) => {


    return (
        <Fragment>
            {pricingFooterConfig !== null && (
        <Box sx={{backgroundColor:  "rgba(202, 202, 206, 1)", display: "flex", alignItems: "center", padding: "2%"}}>
            <Container maxWidth='lg'>
                <Typography sx={{color: "rgba(80, 72, 71, 1)", fontWeight: 400, fontSize: {xs: "12px", md:"16px"}}}>
                {/* Search results display the lowest identified prices we locate at the time of your search. Your results may include offers from our affiliates or partners. Discount card name trademarks are the property of their respective owners and do not imply any endorsement, affiliation, association, or other connection with Waltz Health. */}
                {pricingFooterConfig}
                </Typography>
            </Container>
        </Box>
        )}
        </Fragment>
    )
}

PricingFooter.propTypes = {
    pricingFooterConfig : PropTypes.string
}

export default PricingFooter;