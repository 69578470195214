import React, { useContext } from 'react';
import AppContext from '../../AppContext';
import { Routes, Route, Navigate } from 'react-router-dom';


const Content = (props) => {
    
    const { availableRoutes } = useContext(AppContext);

    return (
    <Routes>
        {availableRoutes && availableRoutes?.filter((y) => y.route).map((route, key) => {
            const RouteToComponent = props.components[route.component];
            return <Route path={route.route} element={<RouteToComponent {...props} />} key={key} />;
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
    )
};
  
export default Content;