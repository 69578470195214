import React, { Fragment, useState, useEffect } from "react";
import Button from "@mui/material/Button";
// import { FormControl } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
// import EditIcon from "@mui/icons-material/Edit";
import Skeleton from "@mui/material/Skeleton";
import Dropdown from "../Dropdown/Dropdown";
import QuantityDropdown from "../QuantityDropdown/QuantityDropdown";
import MaxSaved from "../MaxSaved/MaxSaved";
import PropTypes from "prop-types";

const DrugInfoCard = ({
  ndcImageUrl,
  brandGeneric,
  subtextName,

  // pricesLoading,
  changeName,
  selectedDrugName,
  drugNames,
  changeForm,
  selectedForm,
  availableForms,
  changeDosage,
  selectedDosage,
  availableDosages,
  setSelectedDrugQuantity,
  selectedDrugQuantity,
  availableDrugQuantities,
  selectedPackageNames,
  buttonColor,

  handleSaveCoupon,
  currentlySaved,
  selectedNdc,
  setModal,
  setModalOpen,
  savedCoupons,
  primaryColor,
  tertiaryColor,
  printPageArea,
  print,
  loading,
  enableSaving
}) => {
  // const [dropdowns, setDropdowns] = useState(false);

  const [modifiedAvailableQuantities, setModifiedAvailableQuantities] =
    useState(
      !!selectedDrugQuantity &&
        availableDrugQuantities?.filter((y) => y.size === selectedDrugQuantity)
          ?.length > 0
        ? availableDrugQuantities
        : !!availableDrugQuantities
        ? [
            ...availableDrugQuantities,
            { default: false, size: selectedDrugQuantity },
          ]
        : [{ default: false, size: selectedDrugQuantity }]
    );

  useEffect(() => {
    !!selectedDrugQuantity &&
    availableDrugQuantities?.filter((y) => y.size === selectedDrugQuantity)
      ?.length > 0
      ? setModifiedAvailableQuantities(availableDrugQuantities)
      : !!availableDrugQuantities
      ? setModifiedAvailableQuantities([
          ...availableDrugQuantities,
          { default: false, size: selectedDrugQuantity },
        ])
      : setModifiedAvailableQuantities([
          { default: false, size: selectedDrugQuantity },
        ]);
  }, [selectedDrugQuantity, availableDrugQuantities]);

  // useEffect(() => {
  //   print === true && (
  //     printPageArea()
  //   )
  // }, [print])

  const checkToSave = () => {
    if (savedCoupons.length >= 20) {
      setModal(
        <MaxSaved
          setModalOpen={setModalOpen}
          primaryColor={primaryColor}
          tertiaryColor={tertiaryColor}
        />
      );
    } else {
      handleSaveCoupon(true);
    }
  };

  // const showPrint = () => {
  //   if (print === true) {
  //     return "flex";
  //   } else {
  //     return "none";
  //   }
  // };

  // const brandGenericHovered = "rgba(80,72,71, 3)";
  // const brandGenericClicked = "rgba(80,72,71, 2)";

  // console.log("DrugInfo: ", availableForms, availableDosages, availableDrugQuantities)
  return (
    <Box
      data-testid="druginfocard"
      sx={{
        width: "100vw",
        backgroundColor: brandGeneric === "Brand" ? "#FFE9B1" : "#C9EAF9",
        boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
        marginTop: {xs: "0px", md: "32px"},
        marginBottom: {xs: "none", md: "16px"}
      }}
    >
      <Container maxWidth="lg">

        <Grid container spacing={2}>

          <Grid item xs={7} md={9} sx={{display: "flex"}}>

            <Grid container spacing={1}>
            {/* Mobile Save Button */}
              <Grid
                item
                xs={12}
                md={0}
                sx={{
                  display: { xs: "flex", sm: "none" },
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  height: "3rem",
                  marginTop: { xs: "24px", sm: "0" },
                }}
              >
                {enableSaving && (currentlySaved ? (
                  <IconButton
                    aria-label="save"
                    onClick={() => checkToSave()}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: currentlySaved ? "white" : buttonColor,
                      color: currentlySaved ? buttonColor : "white",
                      borderRadius: "50%",
                      textTransform: "none",
                      // padding:"10px",
                      padding: "0.5rem",
                      width: "1.5rem",
                      height: "1.5rem",

                      marginTop: "8px",
                      "&:hover": {
                        backgroundColor: currentlySaved ? buttonColor : "white",
                        color: currentlySaved ? "white" : buttonColor,

                        border: `1px solid ${
                          currentlySaved ? buttonColor : "white"
                        }`,
                      },
                      "&:disabled": {
                        background: currentlySaved ? "white" : buttonColor,
                        color: currentlySaved ? buttonColor : "white",
                        borderRadius: "50%",
                        textTransform: "none",
                        // padding:"10px",
                        padding: "1rem",
                        width: "1.5rem",
                        height: "1.5rem",
                      },
                    }}
                    disabled
                  >
                    <BookmarkAddedOutlinedIcon />
                  </IconButton>
                ) : loading === true ? (
                  <IconButton
                    aria-label="save"
                    onClick={() => checkToSave()}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: currentlySaved ? "white" : buttonColor,
                      color: currentlySaved ? buttonColor : "white",
                      borderRadius: "50%",
                      textTransform: "none",
                      // padding:"10px",

                      marginTop: "8px",
                      padding: "0.5rem",
                      width: "1.5rem",
                      height: "1.5rem",
                      "&:hover": {
                        backgroundColor: currentlySaved ? buttonColor : "white",
                        color: currentlySaved ? "white" : buttonColor,

                        border: `1px solid ${
                          currentlySaved ? buttonColor : "white"
                        }`,
                      },
                      "&:disabled": {
                        backgroundColor: "#D4D4D4",
                        color: "#AFAFAF",
                        borderRadius: "50%",
                        textTransform: "none",
                        // padding:"10px",
                        padding: "1rem",
                        width: "1.5rem",
                        height: "1.5rem",
                      },
                    }}
                    disabled
                  >
                    <BookmarkAddOutlinedIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="save"
                    onClick={() => checkToSave()}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: currentlySaved ? "white" : buttonColor,
                      color: currentlySaved ? buttonColor : "white",
                      borderRadius: "50%",
                      textTransform: "none",
                      // padding:"10px",
                      marginTop: "8px",
                      padding: "1rem",
                      width: "1.5rem",
                      height: "1.5rem",
                      "&:hover": {
                        backgroundColor: currentlySaved ? buttonColor : "white",
                        color: currentlySaved ? "white" : buttonColor,

                        border: `1px solid ${
                          currentlySaved ? buttonColor : "white"
                        }`,
                      },
                    }}
                  >
                    <BookmarkAddOutlinedIcon />
                  </IconButton>
                ))}
              </Grid>
              <Grid
                item
                xs={0}
                sm={12}
                md={0}
                sx={{
                  display: { xs: "none", sm: "flex", md: "none" },
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                //   height: "3rem",
                  marginTop: { xs: "10%", sm: "24px" },
                }}
              >
                {currentlySaved ? (
                  <IconButton
                    aria-label="save"
                    onClick={() => checkToSave()}
                    data-testid={"after-save"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: currentlySaved ? "white" : buttonColor,
                      color: currentlySaved ? buttonColor : "white",
                      borderRadius: "24px",
                      textTransform: "none",
                      // padding:"10px",
                      padding: "8px 16px",
                      width: "90px",
                      height: "32px",

                      marginTop: "8px",
                      "&:hover": {
                        backgroundColor: currentlySaved ? buttonColor : "white",
                        color: currentlySaved ? "white" : buttonColor,

                        border: `1px solid ${
                          currentlySaved ? buttonColor : "white"
                        }`,
                      },
                      "&:disabled": {
                        background: currentlySaved ? "white" : buttonColor,
                        color: currentlySaved ? buttonColor : "white",
                        borderRadius: "24px",
                        textTransform: "none",
                        // padding:"10px",
                        padding: "8px 16px",
                      width: "90px",
                      height: "32px",
                      },
                    }}
                    disabled
                  >
                    <BookmarkAddedOutlinedIcon />
                    <Typography
                    variant="body2"
                    sx={{ display: { xs: "none", sm: "inline" } }}
                  >
                    Saved!
                  </Typography>
                  </IconButton>
                ) : loading === true ? (
                  <IconButton
                    aria-label="save"
                    onClick={() => checkToSave()}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: currentlySaved ? "white" : buttonColor,
                      color: currentlySaved ? buttonColor : "white",
                      borderRadius: "24px",
                      textTransform: "none",
                      // padding:"10px",

                      marginTop: "8px",
                      padding: "8px 16px",
                      width: "90px",
                      height: "32px",
                      "&:hover": {
                        backgroundColor: currentlySaved ? buttonColor : "white",
                        color: currentlySaved ? "white" : buttonColor,

                        border: `1px solid ${
                          currentlySaved ? buttonColor : "white"
                        }`,
                      },
                      "&:disabled": {
                        backgroundColor: "#D4D4D4",
                        color: "#AFAFAF",
                        borderRadius: "24px",
                        textTransform: "none",
                        // padding:"10px",
                        padding: "8px 16px",
                        width: "90px",
                        height: "32px",
                      },
                    }}
                    disabled
                  >
                    <BookmarkAddOutlinedIcon />
                    <Typography
                    variant="body2"
                    sx={{ display: { xs: "none", sm: "inline" } }}
                  >
                    Save
                  </Typography>
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="save"
                    onClick={() => checkToSave()}
                    data-testid={"before-save"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: currentlySaved ? "white" : buttonColor,
                      color: currentlySaved ? buttonColor : "white",
                      borderRadius: "24px",
                      textTransform: "none",
                      // padding:"10px",
                      marginTop: "8px",
                      padding: "8px 16px",
                      width: "90px",
                      height: "32px",
                      "&:hover": {
                        backgroundColor: currentlySaved ? buttonColor : "white",
                        color: currentlySaved ? "white" : buttonColor,

                        border: `1px solid ${
                          currentlySaved ? buttonColor : "white"
                        }`,
                      },
                    }}
                  >
                    <BookmarkAddOutlinedIcon />
                    <Typography
                      variant="body2"
                      sx={{ display: { xs: "none", sm: "inline" } }}
                    >
                      Save
                    </Typography>
                  </IconButton>
                )}
              </Grid>
              <Grid
                className="printView"
                item
                xs={12}
                md={10}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: "16px",
                  // marginBottom: "8px",
                  padding: "24px 0px 4px 0px",
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: "18px", sm: "32px" },
                    wordWrap: "break-word",
                    // display: "flex",
                    // justifyContent: "center",
                    fontWeight: 600,
                    color:"#504847"
                  }}
                >
                  {selectedDrugName || (
                    <Skeleton
                      sx={{
                        width: { xs: "12rem", md: "30rem" },
                        // fontSize: { xs: "2rem", sm: "3rem" },
                      }}
                    ></Skeleton>
                  )}
                </Typography>
                <Box sx={{display: "flex", flexDirection: {xs: "column", sm: "row"}}}>
                <Typography
                  data-testid="check-brandGeneric"
                  variant="body1"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    fontSize: { xs: "12px", sm: "16px" },
                    fontWeight: 400,
                    flexDirection: "row",
                    textTransform: "none",
                    textDecoration: "none",
                    marginBottom: "8px",
                    color:"#504847"
                  }}
                >
                  {!!subtextName && !!brandGeneric ? (
                    `${brandGeneric} for ${subtextName}`
                  ) : !!brandGeneric ? (
                    brandGeneric
                  ) : (
                    <Skeleton
                      sx={{
                        width: { xs: "10rem", md: "20rem" },
                        // fontSize: { xs: "1rem", sm: "2rem" },
                      }}
                    ></Skeleton>
                  )}
                </Typography>
                {/* {!!subtextName && drugNames?.filter((y) => y.name === subtextName)?.length >0 && 1===2 ? <Typography
                  variant="body1"
                  onClick={() => changeName(subtextName)}
                  sx={{
                    display: "flex",
                    textDecoration: "underline",
                    justifyContent: "start",
                    marginBottom: "5%",
                    fontSize: { xs: "16px", sm: "16px" },
                    fontWeight: 400,
                    cursor: "pointer",
                    textTransform: "none",
                    "&:hover": {
                      textDecoration: "underline",
                      color: brandGenericHovered,
                    },
                    "&:focus": {
                      textDecoration: "underline",
                      color: brandGenericClicked,
                    },
                  }}
                >
                  {!!subtextName && `${subtextName}`}
                </Typography>
                : <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "5%",
                  fontSize: { xs: "16px", sm: "16px" },
                  fontWeight: 400,
                }}
              >
                {!!subtextName && `${subtextName}`}
              </Typography>
                
                } */}
                </Box>
              </Grid>
              
              <Grid
                item
                xs={0}
                md={2}
                sx={{
                  display: {xs: "none", md: "flex"},
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "24px 24px 4px 0px",
                }}
              >
                {enableSaving && (currentlySaved ? (
                  <Button
                    onClick={() => checkToSave()}
                    sx={{
                      background: currentlySaved ? "white" : buttonColor,
                      color: currentlySaved ? buttonColor : "white",
                      borderRadius: { xs: "50%", md: "24px" },
                      textTransform: "none",
                      padding: "10px 24px",
                      width: "117px",
                      height: "42px",
                      "&:hover": {
                        backgroundColor: currentlySaved ? buttonColor : "white",
                        color: currentlySaved ? "white" : buttonColor,

                        border: `1px solid ${
                          currentlySaved ? buttonColor : "white"
                        }`,
                      },
                      "&:disabled": {
                        background: currentlySaved ? "white" : buttonColor,
                        color: currentlySaved ? buttonColor : "white",
                        borderRadius: { xs: "50%", md: "24px" },
                      textTransform: "none",
                      padding: "10px 24px",
                      width: "117px",
                      height: "42px",
                      },
                    }}
                    disabled
                  >
                    <BookmarkAddedOutlinedIcon />
                    <Typography
                      variant="body2"
                      sx={{ display: { xs: "none", sm: "inline" } }}
                    >
                      {currentlySaved ? "Saved!" : "Save"}
                    </Typography>
                  </Button>
                ) : loading === true ? (
                  <Button
                  onClick={() => checkToSave()}
                  sx={{
                    background: currentlySaved ? "white" : buttonColor,
                    color: currentlySaved ? buttonColor : "white",
                    borderRadius: { xs: "50%", md: "24px" },
                      textTransform: "none",
                      padding: "10px 24px",
                      width: "117px",
                      height: "42px",
                    "&:hover": {
                      backgroundColor: currentlySaved ? buttonColor : "white",
                      color: currentlySaved ? "white" : buttonColor,

                      border: `1px solid ${
                        currentlySaved ? buttonColor : "white"
                      }`,
                    },
                    "&:disabled": {
                      backgroundColor: "#D4D4D4",
                      color: "#AFAFAF",
                    }
                  }}
                  disabled
                >
                  <BookmarkAddOutlinedIcon />
                  <Typography
                    variant="body2"
                    sx={{ display: { xs: "none", sm: "inline" } }}
                  >
                    Save
                  </Typography>
                </Button>
                ) : (
                  <Button
                    onClick={() => checkToSave()}
                    sx={{
                      background: currentlySaved ? "white" : buttonColor,
                      color: currentlySaved ? buttonColor : "white",
                      borderRadius: { xs: "50%", md: "24px" },
                      textTransform: "none",
                      padding: "10px 24px",
                      width: "117px",
                      height: "42px",
                      "&:hover": {
                        backgroundColor: currentlySaved ? buttonColor : "white",
                        color: currentlySaved ? "white" : buttonColor,

                        border: `1px solid ${
                          currentlySaved ? buttonColor : "white"
                        }`,
                      },
                    }}
                  >
                    <BookmarkAddOutlinedIcon />
                    <Typography
                      variant="body2"
                      sx={{ display: { xs: "none", sm: "inline" } }}
                    >
                      {currentlySaved ? "Saved!" : "Save"}
                    </Typography>
                  </Button>
                ))}
              </Grid>
              {/* <Grid
                item
                xs={12}
                className="printView"
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <Box sx={{display: "flex", flexDirection: "row"}}>
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "5%",
                    fontSize: { xs: "16px", sm: "16px" },
                    fontWeight: 400,
                    // cursor: "pointer",
                    textTransform: "none",
                    textDecoration: "none",
                    mr: "4px",
                    color:"#504847"
                  }}
                >
                  {!!subtextName && !!brandGeneric ? (
                    `${brandGeneric} for`
                  ) : !!brandGeneric ? (
                    brandGeneric
                  ) : (
                    <Skeleton
                      sx={{
                        width: { xs: "10rem", md: "20rem" },
                        // fontSize: { xs: "1rem", sm: "2rem" },
                      }}
                    ></Skeleton>
                  )}
                </Typography>
                {!!subtextName && drugNames?.filter((y) => y.name === subtextName)?.length >0 && 1===2 ? <Typography
                  variant="body1"
                  onClick={() => changeName(subtextName)}
                  sx={{
                    display: "flex",
                    textDecoration: "underline",
                    justifyContent: "start",
                    marginBottom: "5%",
                    fontSize: { xs: "16px", sm: "16px" },
                    fontWeight: 400,
                    cursor: "pointer",
                    textTransform: "none",
                    "&:hover": {
                      textDecoration: "underline",
                      color: brandGenericHovered,
                    },
                    "&:focus": {
                      textDecoration: "underline",
                      color: brandGenericClicked,
                    },
                  }}
                >
                  {!!subtextName && `${subtextName}`}
                </Typography>
                : <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "5%",
                  fontSize: { xs: "16px", sm: "16px" },
                  fontWeight: 400,
                }}
              >
                {!!subtextName && `${subtextName}`}
              </Typography>
                
                }
                </Box>
              </Grid>
               */}
              <Grid
                item
                xs={0}
                md={3.5}
                sx={{ display: { xs: "none", md: "inline" } }}
                data-testid={"drug-name-dropdown"}
              >
                <Fragment>
                  <Dropdown
                    label={"Drug Name"}
                    setOption={changeName}
                    selectedOption={selectedDrugName}
                    disabled={loading}
                    primaryColor={primaryColor}
                    tertiaryColor={tertiaryColor}
                    availableOptions={drugNames.map((y) => {
                      return {
                        value: `${y.name}`,
                        key: `${y.name}`,
                        text: `${y.name} (${y.type})`,
                      };
                    })}
                  />
                </Fragment>
              </Grid>
              <Grid
                item
                xs={0}
                md={3}
                sx={{ display: { xs: "none", md: "inline" } }}
              >
                <Fragment>
                  <Dropdown
                    label={"Form"}
                    setOption={changeForm}
                    selectedOption={selectedForm}
                    disabled={loading}
                    primaryColor={primaryColor}
                    tertiaryColor={tertiaryColor}
                    availableOptions={availableForms.map((y) => {
                      return {
                        value: `${y}`,
                        key: `${y}`,
                        text: `${y}`,
                      };
                    })}
                  />
                </Fragment>
              </Grid>{" "}
              <Grid
                item
                xs={0}
                md={3}
                sx={{ display: { xs: "none", md: "inline" } }}
              >
                <Fragment>
                  <Dropdown
                    label={"Dosage"}
                    setOption={changeDosage}
                    selectedOption={selectedDosage}
                    disabled={loading}
                    primaryColor={primaryColor}
                    tertiaryColor={tertiaryColor}
                    availableOptions={availableDosages.map((y) => {
                      return {
                        value: `${y}`,
                        key: `${y}`,
                        text: `${y}`,
                      };
                    })}
                  />
                </Fragment>
              </Grid>
              <Grid
                item
                xs={0}
                md={2.5}
                sx={{ display: { xs: "none", md: "inline" }, width: "100%" }}
              >
                <Fragment>
                  <QuantityDropdown 
                    label={"Quantity"}
                    setOption={setSelectedDrugQuantity}
                    selectedOption={selectedDrugQuantity}
                    disabled={loading}
                    primaryColor={primaryColor}
                    tertiaryColor={tertiaryColor}
                    availableOptions={modifiedAvailableQuantities.map((y) => {
                      if (!!selectedPackageNames && selectedPackageNames?.singular && selectedPackageNames?.plural) {
                        return {
                          value: `${y.size}`,
                          key: `${y.size}`,
                          text: y.size ==="1" ? `${y.size} ${selectedPackageNames?.singular}` : `${y.size} ${selectedPackageNames?.plural}`,
                        }
                      } else {
                        return {
                          value: `${y.size}`,
                          key: `${y.size}`,
                          text: `${y.size}`,
                        }
                      }
                    })}
                  />
                </Fragment>
              </Grid>
            </Grid>

            </Grid>
            <Grid
            item
            className="printView"
            xs={5}
            sm={4}
            md={3}
            lg={3}
            sx={{
              display: "flex",
              paddingBottom:"16px",
              // justifyContent: "flex-end",
            //   width: {xs: "124px", md: "250px"},
              
              marginTop: {xs: "24px", sm: "16px", md: "-16px"},
              alignItems: "flex-start",
            }}
          >
            {!selectedDrugName ? (
              <Box
                className="drugImgBox"
                sx={{
                  display: "flex",
                  width: '100%',
                  height: { xs: "100%", sm: "100%" },
                  // top: "-1rem",
                  justifyContent: { xs: "flex-end", md: "flex-start" },
                  alignItems: "flex-start",
                  // backgroundImage: `url(/defaultMedication.jpg)`,
                  position: "relative",
                  backgroundSize: "cover",
                  backgroundColor: "rgba(0, 0, 0, 0.11)",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  paddingTop: 0
                }}
                alt="Generic Drug Image"
              >
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={"100%"}
                  animation="pulse"
                  sx={{ transition: "2s", backgroundColor: "grey" }}
                />
              </Box>
            ) : !ndcImageUrl ? (
              <Box
                className="drugImgBox"
                sx={{
                  display: "flex",
                  width: '100%',
                  height: { xs: "100%", sm: "100%" },
                  // top: "-1rem",
                  justifyContent: { xs: "flex-end", md: "flex-start" },
                  alignItems: "flex-start",
                  backgroundImage: `url(/defaultMedication.jpg)`,
                  position: "relative",
                  backgroundSize: "cover",
                  backgroundColor: "rgba(0, 0, 0, 0.11)",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  paddingTop: 0
                }}
                alt="Generic Drug Image"
              ></Box>
            ) : (
              <Box
                data-testid="drug-image-test"
                className="drugImgBox"
                alt={`${selectedDrugName}`}
                sx={{
                  display: "flex",
                  // minWidth: {xs: "124px", sm: "180px", md: "225px", lg: "250px"},
                  width: '100%',
                  height: { xs: "100%", sm: "100%" },
                  // top: { xs: "0", md: "-1rem" },
                  justifyContent: { xs: "flex-start", md: "flex-start" },
                  alignItems: "flex-start",
                  backgroundImage: `url("${ndcImageUrl}")`,
                  // position: "relative",
                  backgroundSize: "contain",
                  // backgroundColor: "rgba(0, 0, 0, 0.11)",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: {xs: "top", sm: "center center"},
                  // marginBottom: "16px",
                  // marginRight: { xs: "0px"},
                //   zIndex: "99999",
                  backgroundColor: brandGeneric === "Brand" ? "#FFE9B1" : "#C9EAF9",
                }}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

DrugInfoCard.propTypes = {
  ndcImageUrl : PropTypes.string,
  brandGeneric : PropTypes.string,
  subtextName : PropTypes.string,
  changeName : PropTypes.func,
  selectedDrugName : PropTypes.string,
  drugNames : PropTypes.array,
  changeForm : PropTypes.func,
  selectedForm : PropTypes.string,
  availableForms : PropTypes.array,
  changeDosage : PropTypes.func,
  selectedDosage : PropTypes.string,
  availableDosages : PropTypes.array,
  setSelectedDrugQuantity : PropTypes.func,
  selectedDrugQuantity : PropTypes.string,
  availableDrugQuantities : PropTypes.array,
  selectedPackageNames : PropTypes.object,
  buttonColor : PropTypes.string,
  handleSaveCoupon : PropTypes.func,
  currentlySaved : PropTypes.bool,
  selectedNdc : PropTypes.string,
  setModal : PropTypes.func,
  setModalOpen : PropTypes.func,
  savedCoupons : PropTypes.array,
  primaryColor : PropTypes.string,
  tertiaryColor : PropTypes.string,
  printPageArea : PropTypes.string,
  print : PropTypes.bool,
  loading : PropTypes.bool,
  enableSaving: PropTypes.bool
};

export default DrugInfoCard;
