import React, { Fragment, useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
// import { FormControl } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import Container from "@mui/material/Container";
// import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
// import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
import EditIcon from "@mui/icons-material/Edit";
// import Skeleton from "@mui/material/Skeleton";
import Dropdown from "../Dropdown/Dropdown";
import QuantityDropdown from "../QuantityDropdown/QuantityDropdown";
// import MaxSaved from "../MaxSaved/MaxSaved";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import PropTyes from 'prop-types'

const MobileSearchEdit = ({
  loading,
  selectedDrugQuantity,
  selectedForm,
  selectedDosage,
  buttonColor,
  brandGeneric,
  changeName,
  selectedDrugName,
  drugNames,
  changeForm,
  availableForms,
  changeDosage,
  availableDosages,
  setSelectedDrugQuantity,
  availableDrugQuantities,
  selectedPackageNames,
  primaryColor,
  tertiaryColor,
  setShowSurvey,
  mobileEditMenuOpen,
  setMobileEditMenuOpen
}) => {
  // const [dropdowns, setDropdowns] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [customQuantity, setCustomQuantity] = useState('');
  const [customInputValidated, setCustomInputValidated] = useState(true);

  const scrollRef = useRef();

  useEffect(() => {
    //add validation here for number sizes - no decimals, no gigantic numbers
    if (customQuantity !== "") {
      if (!/^\d+$/.test(customQuantity)) {
        setCustomInputValidated(false);
      } else {
        if (Number(customQuantity) >= 1.0 && Number(customQuantity) <= 10000.0) {
          setCustomInputValidated(true);
        } else {
          setCustomInputValidated(false);
        }
      }
    }
  }, [customQuantity]);

  useEffect(() => {
    setShowSurvey(!mobileEditMenuOpen)
    if(!mobileEditMenuOpen) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileEditMenuOpen])

  const [modifiedAvailableQuantities, setModifiedAvailableQuantities] =
    useState(
      !!selectedDrugQuantity &&
        availableDrugQuantities?.filter((y) => y.size === selectedDrugQuantity)
          ?.length > 0
        ? availableDrugQuantities
        : !!availableDrugQuantities
        ? [
            ...availableDrugQuantities,
            { default: false, size: selectedDrugQuantity },
          ]
        : [{ default: false, size: selectedDrugQuantity }]
    );

  useEffect(() => {
    !!selectedDrugQuantity &&
    availableDrugQuantities?.filter((y) => y.size === selectedDrugQuantity)
      ?.length > 0
      ? setModifiedAvailableQuantities(availableDrugQuantities)
      : !!availableDrugQuantities
      ? setModifiedAvailableQuantities([
          ...availableDrugQuantities,
          { default: false, size: selectedDrugQuantity },
        ])
      : setModifiedAvailableQuantities([
          { default: false, size: selectedDrugQuantity },
        ]);
  }, [selectedDrugQuantity, availableDrugQuantities]);

  const handleEnterClick = (e) => {
    if (e.keyCode === 13) {
      setSelectedDrugQuantity(e.target.value) && setIsCustom(false);
    }
  };

  return (
    <Box
      sx={{
        display: { xs: "flex", md: "none" },
        flexDirection: "row",
        marginBottom: "8px",
        boxShadow: "0px 4px 7px 0px rgb(0, 0, 0, 0.1)",
        backgroundColor:
          brandGeneric === "Generic"
            ? "rgb(233, 247, 253)"
            : "rgb(255, 246, 224)",
      }}
      ref={scrollRef}
    >
      <Grid container>
        <Grid
          item
          xs={8}
          md={0}
          lg={0}
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "flex-start",
            minHeight:'48px'
          }}
        >
          <Typography
            variant="body1"
            color="subtitle"
            sx={{
              textShadow: "1px",
              padding: "6px 16px",
              fontSize: "12px",
              fontWeight: "600",
              display: "flex",
              position: "absolute",
              alignItems: "center",
              // display: "inline-block",
              whiteSpace: "wrap",
              overflowX: "clip",
              color: "#504847",
              alignSelf: "center",
              maxWidth: "60%",
            }}
          >
            {!loading &&
              selectedDrugQuantity &&
              selectedForm &&
              selectedDosage &&
              `${selectedDrugQuantity} ${selectedForm} | ${selectedDosage}`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          md={0}
          lg={0}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "8px 24px 8px 24px",
          }}
        >
          {!selectedDrugName ? (
            <Button
              onClick={() => {
                setMobileEditMenuOpen(true);
              }}
              disabled={true}
              sx={{
                width: { xs: "5rem", md: "0" },
                maxHeight: "2rem",
                display: { xs: "flex", md: "none" },
                flexDirection: { xs: "row", md: "none" },
                justifyContent: "center",
                alignItems: { xs: "center", md: "none" },
                borderRadius: "30px",
                background: "#D9D9D9",
                textTransform: "none",
                cursor: "pointer",
                fontSize: "14px",
                minWidth: "64px",
                boxSizing: "border-box",
              }}
            >
              <EditIcon sx={{ display: "none" }} />
              <Typography
                variant="body1"
                sx={{ fontSize: "14px", color: "#D9D9D9" }}
              >
                Edit
              </Typography>
            </Button>
          ) : (
            <Button
              onClick={() => {
                setMobileEditMenuOpen(true);
              }}
              sx={{
                width: { xs: "5rem", md: "0" },
                maxHeight: "2rem",
                display: { xs: mobileEditMenuOpen ? 'none' :"flex", md: "none" },
                flexDirection: { xs: "row", md: "none" },
                justifyContent: "center",
                alignItems: { xs: "center", md: "none" },
                borderRadius: "30px",
                background: buttonColor,
                textTransform: "none",
                color: "white",
                cursor: "pointer",
                fontSize: "14px",
                minWidth: "64px",
                boxSizing: "border-box",
                padding: "6px 16px",
                "&:hover": {
                  background: "white",
                  color: buttonColor,
                  border: `1px solid ${buttonColor}`,
                },
              }}
            >
              <EditIcon sx={{ fontSize: "1.2rem" }} />
              <Typography variant="body1" sx={{ fontSize: "14px" }}>
                Edit
              </Typography>
            </Button>
          )}
        </Grid>
        {mobileEditMenuOpen === true ? (
          <Fragment>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                position: "relative",
                width: "100%",
                // padding: "2%",
                padding:'16px',
                display: { x: "relative", md: "none" },
              }}
            >
              <Fragment>
                <Dropdown
                  label={"Drug Name"}
                  setOption={changeName}
                  disabled={loading}
                  selectedOption={selectedDrugName}
                  availableOptions={drugNames.map((y) => {
                    return {
                      value: `${y.name}`,
                      key: `${y.name}`,
                      text: `${y.name}`,
                    };
                  })}
                  scrollWhenOpened={true}
                />
              </Fragment>

              <Fragment>
                <Dropdown
                  label={"Form"}
                  setOption={changeForm}
                  disabled={loading}
                  selectedOption={selectedForm}
                  availableOptions={availableForms.map((y) => {
                    return {
                      value: `${y}`,
                      key: `${y}`,
                      text: `${y}`,
                    };
                  })}
                  scrollWhenOpened={true}
                />
              </Fragment>

              <Fragment>
                <Dropdown
                  label={"Dosage"}
                  setOption={changeDosage}
                  disabled={loading}
                  // setOption={setNewDosage}
                  selectedOption={selectedDosage}
                  availableOptions={availableDosages.map((y) => {
                    return {
                      value: `${y}`,
                      key: `${y}`,
                      text: `${y}`,
                    };
                  })}
                  scrollWhenOpened={true}
                />
              </Fragment>

              <Fragment>
                <QuantityDropdown
                  label={"Quantity"}
                  setOption={setSelectedDrugQuantity}
                  disabled={loading}
                  scrollWhenOpened={true}
                  primaryColor={primaryColor}
                  tertiaryColor={tertiaryColor}
                  selectedOption={selectedDrugQuantity}
                  isCustom={isCustom}
                  setIsCustom={setIsCustom}
                  availableOptions={modifiedAvailableQuantities.map((y) => {
                    if (
                      !!selectedPackageNames &&
                      selectedPackageNames?.singular &&
                      selectedPackageNames?.plural
                    ) {
                      return {
                        value: `${y.size}`,
                        key: `${y.size}`,
                        text:
                          y.size === "1"
                            ? `${y.size} ${selectedPackageNames?.singular}`
                            : `${y.size} ${selectedPackageNames?.plural}`,
                      };
                    } else {
                      return {
                        value: `${y.size}`,
                        key: `${y.size}`,
                        text: `${y.size}`,
                      };
                    }
                  })}
                />

                {isCustom === true && (
                  <Box
                    sx={{
                      display: isCustom === false ? "none" : "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "16px",
                    }}
                  >
                    <TextField
                      // variant="outlined"
                      placeholder={"Enter Quantity"}
                      size="small"
                      sx={{
                        width:"100%",
                        marginRight:"16px",
                        color: "rgba(41, 41, 51, 1)",
                        fontSize: "16px",
                        "& fieldset": {
                          borderRadius: "40px !important",
                        },
                        "& input": {
                          backgroundColor: "white",
                          display: "flex",
                          alignItems: "center",
                          color: "rgba(41, 41, 51, 1)",
                          borderRadius: "40px !important",
                        },
                        "&:focus": { color: "black", fontWeight: 500 },
                      }}
                      value={customQuantity}
                      onKeyDown={handleEnterClick}
                      onChange={(e) => {
                        setCustomQuantity(e?.target?.value);
                      }}
                      error={!customInputValidated}
                      helperText={
                        !customInputValidated && customQuantity?.length > 0
                          ? "Enter a valid quantity"
                          : undefined
                      }
                    />
                    <Box
                      sx={{
                        display: isCustom === false ? "none" : "flex",
                        flexDirection: "row",
                        alignSelf: 'start'
                      }}
                    >
                    <IconButton
                      onClick={() => {
                        setIsCustom(false);
                        setCustomQuantity("")
                      }}
                      sx={{
                        display: "flex",
                        backgroundColor: buttonColor,
                        color: "white",
                        borderRadius: "50%",
                        height: "50%",
                        width: "40px",
                        marginRight:"8px",
                        "&:hover": {
                          backgroundColor: "white",
                          color: buttonColor,
                          border: `1px solid ${primaryColor}`,
                        },
                        "&:focus": {
                          backgroundColor: "white",
                          color: buttonColor,
                          border: `1px solid ${primaryColor}`,
                        },
                        "&:active": {
                          backgroundColor: "white",
                          color: buttonColor,
                          border: `1px solid ${primaryColor}`,
                        },
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                    <IconButton
                      disabled={!customInputValidated || customQuantity === ''}
                      onClick={() => {
                        setSelectedDrugQuantity(customQuantity);
                        setIsCustom(false);
                        setCustomQuantity("")
                      }}
                      sx={{
                        display: "flex",
                        backgroundColor: buttonColor,
                        color: "white",
                        borderRadius: "50%",
                        height: "40px",
                        width: "40px",
                        "&:hover": {
                          backgroundColor: "white",
                          color: buttonColor,
                          border: `1px solid ${primaryColor}`,
                        },
                        "&:focus": {
                          backgroundColor: "white",
                          color: buttonColor,
                          border: `1px solid ${primaryColor}`,
                        },
                        "&:active": {
                          backgroundColor: "white",
                          color: buttonColor,
                          border: `1px solid ${primaryColor}`,
                        },
                        "&:disabled": {
                          backgroundColor: "lightgray",
                          color: buttonColor,
                          border: `1px solid lightgray`,
                        },
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                    </Box>
                  </Box>
                )}
              </Fragment>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => setMobileEditMenuOpen(false)}
                sx={{
                  borderRadius: "30px",
                  backgroundColor: buttonColor,
                  color: "white",
                  width: "140px",
                  padding: "10px 24px",
                  height: "42px",
                  fontSize: "16px",
                  textTransform: "none",
                  marginTop: "40px",
                }}
              >
                Done
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: { xs: "flex", md: "none" },
                fontSize: "0.786rem",
                fontWeight: "600",
                color: "rgb(163 163 163)",
                marginTop: "12px",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: "5%",
              }}
            >
              POWERED BY
              <img
                style={{
                  maxWidth: "140px",
                  maxHeight: "30px",
                  margin: "0 0 0 5px",
                }}
                src="https://indigorxappdataprod.blob.core.windows.net/indigorx/cms/c4b75a67-7069-4c55-b376-67b2f1ece391waltz health logo (navy).png"
                alt="Waltz Health Logo"
              />
            </Grid>
          </Fragment>
        ) : (
          <Fragment></Fragment>
        )}
      </Grid>
    </Box>
  );
};

MobileSearchEdit.propTypes = {
  loading : PropTyes.bool,
  selectedDrugQuantity : PropTyes.string,
  selectedForm : PropTyes.string,
  selectedDosage : PropTyes.string,
  buttonColor : PropTyes.string,
  brandGeneric : PropTyes.string,
  changeName : PropTyes.func,
  selectedDrugName : PropTyes.string,
  drugNames: PropTyes.array,
  changeForm : PropTyes.func,
  availableForms : PropTyes.array,
  changeDosage : PropTyes.func,
  availableDosages : PropTyes.array,
  setSelectedDrugQuantity : PropTyes.func,
  availableDrugQuantities : PropTyes.array,
  selectedPackageNames : PropTyes.object,
  primaryColor : PropTyes.string,
  tertiaryColor : PropTyes.string,
  setShowSurvey :  PropTyes.func,
  mobileEditMenuOpen : PropTyes.bool,
  setMobileEditMenuOpen : PropTyes.func
}

export default MobileSearchEdit;
