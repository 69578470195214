import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";


const Dropdown = ({
  label,
  setOption,
  selectedOption,
  availableOptions,
  disabled,
  scrollWhenOpened
}) => {

  const [roundInput, setRoundInput] = useState(false);
  const selectRef = useRef();
  const scrollRef = useRef();

  return (
    <Box
      ref={scrollRef}
      sx={{
        color: "rgb(75, 75, 75)",
        textAlign: "left",
        marginBottom: "12px",
        // maxWidth: "500px",
      }}
    >
      <FormControl fullWidth size="small">
        <InputLabel
          ref={scrollRef}
          sx={{
            color: "rgb(75, 75, 75) !important",
            position: "relative",
            fontSize: "1rem",
            transform: "none",
            fontWeight: "600",
            marginBottom: "0.25rem",
          }}
        >
          {label}
        </InputLabel>
        <Select
          ref={selectRef}
          // inputRef={selectRef}
          // open={true}
          // autoWidth={true}
          key={label}
          IconComponent={KeyboardArrowDownIcon}
          color="body"
          sx={{
            borderColor: "rgb(102, 102, 102)",
            borderRadius: roundInput ? "24px 24px 0px 0px" : "24px",
            fontSize: "14px",
            backgroundColor: "#FFFFFF",
            '.MuiSvgIcon-root ': {
              display: availableOptions?.length < 2 || disabled ? 'none' : undefined
            }
          }}
          MenuProps={{
            PaperProps: { sx: { maxHeight: {xs:210, md: 345}, color: "#757593" , width: selectRef?.current?.style?.width} },
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "#EDEDED",
              },
              "&& .Mui-selected:hover": {
                backgroundColor: "#EDEDED",
              },
              // maxWidth:'100%'
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            }
          }}
          onOpen={() => {
            setRoundInput(true); 
            scrollWhenOpened && scrollRef?.current?.scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
          }}
          onClose={() => setRoundInput(false)}
          onChange={(e) => setOption(e?.target?.value)}
          value={selectedOption}
          disabled={(availableOptions?.length < 2 && label !== "Quantity") || disabled}
          // variant="outlined"
          // iconOutlined
        >
          {availableOptions?.map((x, index) => {
            return (
              <MenuItem value={x.value} key={x.key} sx={{whiteSpace:'pre-wrap'}}>
                {x.text}
              </MenuItem>
            );
          })}
          
        </Select>
      </FormControl>
    </Box>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  setOption: PropTypes.func,
  selectedOption: PropTypes.string,
  availableOptions: PropTypes.array,
  disabled : PropTypes.bool,
  scrollWhenOpened: PropTypes.bool
};

Dropdown.defaultProps = {
  label: "Quantity",
  setOption: () => {},
  availableOptions: [
    { value: "10", key: "10", text: "10" },
    { value: "15", key: "15", text: "15" },
    { value: "30", key: "30", text: "30" },
    { value: "60", key: "60", text: "60" },
  ],
};
export default Dropdown;
