import React, { useState, useContext, useEffect } from "react";
import { Button, Box, TextField, Typography, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import RoomIcon from '@mui/icons-material/Room';
import { useNavigate } from "react-router-dom";
import AppContext from "../../AppContext";

const ZipCodeModal = ({
  handleClose,
  setModalOpen,
  primaryColor,
  tertiaryColor,
  coordinates
}) => {
  const {
  fetchZip,
  zipCodeError
    // searchInput
  } = useContext(AppContext);

  const navigate = useNavigate();
  const [validatedZipcode, setValidatedZipcode] = useState(false);
  const [searchedZip, setSearchedZip] = useState(false)

  const errorMessage = () => {
    if([``, null, ""].includes(searchedZip)) {
      return true
    } 
    // else if(zipCodeError === true) {
    //   return true
    // } 
    else if(searchedZip.length >= 1){
      return true
    } else {
      return false;
    }
  }

  useEffect(() => {
    setValidatedZipcode(
      /^\b\d{5}(-\d{4})?\b$/.test(searchedZip)
    );
  }, [searchedZip]);

  const handleZipEnter = (e) => {
    if (e.keyCode === 13) {
      if (
        validatedZipcode
      ) {
        fetchZip(searchedZip);
      }
    }
  };

  const closeModal = () => {
    if (coordinates === null){
      setModalOpen(false);
      navigate("/");
    } else {
    setModalOpen(false);
    }
  }
  return (
    <Box
      sx={{
        position: "absolute",
        top: { xs: "45%", sm: "40%", md: "40%" },
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: { xs: "90%", sm: "75%", md: "50%" },
        bgcolor: "background.paper",
        borderRadius: "16px",
        boxShadow: 24,
        padding: "8px",
        display: "flex",
        alignItems: "space-between",
      }}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={10}
          sx={{
            display: "flex",
            // flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            color="title"
            sx={{
              display: "flex",
              alignSelf: "flex-end",
              fontWeight: "400",
              fontSize: { xs: "16px", md: "20px" },
              padding: { xs: "16px 0px 0px 16px", md: "16px 0px 0px 24px" },
            }}
          >
            Set your location
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            alignContent: "flex-start",
            justifyContent: "flex-end",
            padding: "0%",
            "&:hover": {
              background: "none",
            }
          }}
        >
          <IconButton
            aria-label="close"
            sx={{ padding: "0" }}
            onClick={() => closeModal()}
            disableRipple
          >
            <CloseIcon sx={{ display: "flex", alignSelf: "flex-start" }} />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignSelf: "flex-end",
            fontSize: "14px",
            margin: { xs: " 0px 16px 0px 16px", md: " 0px 24px 0px 24px" },
          }}
        >
          <Typography
            color="title"
            sx={{ fontSize: { xs: "12px", md: "16px" }, fontWeight: 400 }}
          >
            Get the best prices for your location.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: { xs: " 0px 16px 0px 16px", md: " 0px 24px 0px 24px" },
          }}
        >
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              borderRadius: "10px",
              backgroundColor: "rgba(234, 232, 232, 1)",
              boxShadow: "1px 2px 4px 1px rgba(0, 0, 0, 0.25)",
              margin: { xs: "0px 16px  8px 16px", md: "0px 24px 8px 24px" },
            }}
          > */}
          <Button
            onClick={async () => {
              await fetchZip('geo')
            }}
            // autoFocus="true"
            sx={{
              textTransform: "none",
              width: "100%",
              color: "#FFFFFF",
              background: primaryColor,
              fontSize: { xs: "16px", md: "20px" },
              alignItems: "center",
              border: `1px solid ${primaryColor}`,
              borderRadius: "40px",
              "&:hover": {
                backgroundColor: "#FFFFFF",
                border: `1px solid ${primaryColor}`,
                color: primaryColor,
                // margin: "1%",
              },
              "&:hover .material-symbols-outlined": {
                color: primaryColor,
                // margin: "1%",
              },
              "&:focus": {
                backgroundColor: "#FFFFFF",
                border: `1px solid ${primaryColor}`,
                color: primaryColor,
                // margin: "1%",
              },
              "&:focus .material-symbols-outlined": {
                color: primaryColor,
                // margin: "1%",
              },
            }}
          >
            <GpsFixedOutlinedIcon
              className="material-symbols-outlined"
              sx={{
                color: "#FFFFFF",
                fontWeight: 400,
                height: { xs: "18.25px", md: "22px" },
                width: { xs: "18.25px", md: "22px" },
              }}
            />
            Detect My Location
          </Button>
          {/* </Box> */}
        </Grid>
        <Grid item xs={12}>
          <Typography
            color="title"
            sx={{
              padding: { xs: "0px 0px 0px 16px", md: "0px 0px 0px 24px" },
              fontSize: { xs: "12px", md: "16px" }, fontWeight: 400 
            }}
          >
            Note: Your browser will ask for your permission.
          </Typography>
          <Grid item
          xs={12}
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            width: '100%',
            fontSize: { xs: "10px", md: "14px" }, fontWeight: 400 
            // marginBottom: { xs: "8px", md: "16px" },
            // maxHeight: {xs: "35px", md: "48px"},
          }}>OR
        </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ margin: { xs: "0px 16px  8px 16px", md: "0px 24px 8px 24px" } }}
        >
            <TextField
              key="zipcode"
              id="outlined-zipcode-input"
              variant="outlined"
              label={"Enter Zip Code"}
              onClick={() => {
                if (!searchedZip) {
                setSearchedZip(' ')
                }
              }}
              inputRef={input => {
                if (searchedZip?.length > 0) {
                  input && input.focus()
                }
              }}
              InputProps={{
                startAdornment :(
                  <InputAdornment position="start">
                <RoomIcon
              className="material-symbols-outlined"
              sx={{
                color: primaryColor,
                fontWeight: 400,
                height: { xs: "18.25px", md: "22px" },
                width: { xs: "18.25px", md: "22px" },
              }}
              />
              {!searchedZip || searchedZip.length === 0 ? " Enter Zip Code" : null}
              </InputAdornment>
              )
              }}
              sx={{
                "& fieldset": {
                  borderRadius: "40px !important",
                },
                width: "100%",
                "&:focus": { color: "black", fontWeight: 500 },
              }}
            //   value={email}
              onChange={(e) => {
                setSearchedZip(e.target.value)
                setValidatedZipcode(false)
              }}
              onKeyDown={handleZipEnter}
              error={errorMessage()}
              helperText={
                !validatedZipcode && searchedZip?.length > 0
                  ? "Please enter a valid Zip Code"
                  : zipCodeError ? "Please enter a valid Zip Code"
                  : undefined
              }
            />
        </Grid>
        {/* <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            marginBottom: { xs: "8px", md: "16px" },
          }}
        >
          <Button
            onClick={() => {
              setModalOpen(false)
            }}
            variant="outlined"
            sx={{
              display: "flex",
              alignItems: "center",
              padding: { xs: "8px 24px 8px 24px", md: "10px 24px 10px 24px" },
              color: "black",
              borderRadius: "32px",
              width: "99%",
              // height: "80%",
              borderColor: "black",
              textTransform: "none",
              fontSize: { xs: "14px", md: "20px" },
              height: { xs: "40px", md: "48px" },
              marginLeft: {xs: "16px", md: "24px"},
              "&:hover": {
                color: primaryColor,
                borderColor: primaryColor,
              },
            }}
          >
            Cancel
          </Button>
        </Grid> */}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            margin: { xs: "0px 16px  8px 16px", md: "0px 24px 8px 24px" }
            // maxHeight: {xs: "35px", md: "48px"},
          }}
        >
          <Button
            variant="outlined"
            sx={{
              display: "flex",
              alignItems: "center",
              padding: { xs: "8px 24px 8px 24px", md: "10px 24px 10px 24px" },
              color: tertiaryColor,
              borderRadius: "32px",
              backgroundColor: primaryColor,
              fontSize: { xs: "14px", md: "20px" },
              textTransform: "none",
              width: "100%",
              height: { xs: "40px", md: "48px" },
              // marginRight: {xs: "16px", md: "24px"},
              "&:hover": {
                color: primaryColor,
                borderColor: primaryColor,
              },
              "&:disabled": {
                backgroundColor: "#D4D4D4",
                color: "#AFAFAF",
              },
            }}
            onClick={async () => {
              if (validatedZipcode) {
              await fetchZip(searchedZip) 
              }
            }
            }
          >
            Set Location
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ZipCodeModal;
