import React, { useContext, useEffect, useState, Fragment } from "react";
import AppContext from "../../AppContext";
import API from "../../Services/API";
import DrugSearch from "../../Components/DrugSearch/DrugSearch";
import HomeHeader from "../../Components/HomeHeader/HomeHeader";
import PopularDrugButton from "../../Components/PopularDrugsButton/PopularDrugButton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import DrugSearchButton from "../../Components/DrugSearchButton/DrugSearchButton";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Typography from "@mui/material/Typography";
import ZipCodeModal from "../../Components/ZipCodeModal/ZipCodeModal";
// import SurveyModal from "../../Components/SurveyModal/SurveyModal";
// import Button from "@mui/material/Button";
// // import Fab from "@mui/material/Fab";
// import HowWaltzWorksCards from "../../Components/HowWaltzWorksCards/HowWaltzWorksCards";

const Home = (props) => {
  const { config, setSearchInput, setModalOpen, setModal, zipcode, useCase, coordinates } =
    useContext(AppContext);

  const [popularDrugs, setPopularDrugs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchButtonClick, setSearchButtonClick] = useState(false);

  const [popularDrugsError, setPopularDrugsError] = useState(false);

  // const howWaltzWorksContent =
  //   config?.homepage_configurationConnection?.page_content_module[2]
  //     ?.box_configuration?.box_content;
  useEffect(() => {
    ["2", "3"].includes(useCase) &&
    coordinates === null &&
      setModal(
        <ZipCodeModal
          setModalOpen={setModalOpen}
          primaryColor={
            config?.color_configurationConnection?.primary_color?.hex
          }
          tertiaryColor={
            config?.color_configurationConnection?.secondary_color?.hex
          }
        />
      );
  }, [coordinates]);

  useEffect(() => {
    const getPopularDrugs = async () => {
      const cacheExists = await caches.has(
        `${sessionStorage.getItem("clientId")}-${sessionStorage.getItem(
          "apiUrl"
        )}`
      );
      if (cacheExists) {
        try {
          const response = await API.get(
            `/DrugSearchApi/drugsearch/${
              config?.api_version_configurationConnection?.popular_drug_api_version?.toLowerCase() ||
              "v1"
            }/popularsearches`,
            { useCache: true }
          );
          setPopularDrugs(response.data?.data);
          setPopularDrugsError(false);
          setLoading(false);
        } catch (error) {
          // console.log(error)
          setPopularDrugsError(true);
          setLoading(false);
        }

        try {
          const response = await API.get(
            `/DrugSearchApi/drugsearch/${
              config?.api_version_configurationConnection?.popular_drug_api_version?.toLowerCase() ||
              "v1"
            }/popularsearches`,
            { useCache: false }
          );
          setPopularDrugs(response.data?.data);
          setPopularDrugsError(false);
          setLoading(false);
        } catch (error) {
          // console.log(error)
          setPopularDrugsError(true);
          setLoading(false);
        }
      } else {
        try {
          const response = await API.get(
            `/DrugSearchApi/drugsearch/${
              config?.api_version_configurationConnection?.popular_drug_api_version?.toLowerCase() ||
              "v1"
            }/popularsearches`,
            { useCache: true }
          );
          setPopularDrugs(response.data?.data);
          setPopularDrugsError(false);
          setLoading(false);
        } catch (error) {
          // console.log(error)
          setPopularDrugsError(true);
          setLoading(false);
        }
      }
    };
    getPopularDrugs();
  }, [config?.api_version_configurationConnection?.popular_drug_api_version]);

  useEffect(() => {
    document.title =
      config?.homepage_configurationConnection?.page_title || "Home";
  }, [config?.homepage_configurationConnection?.page_title]);

  return (
    <Box sx={{ backgroundColor: "rgb(246, 246, 246)", zIndex: "1" }}>
      <Box
        sx={{
          paddingTop: "35px",
          paddingBottom: "40px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {/* home page search box*/}
        <Container maxWidth="lg">
          <Box
            sx={{
              marginTop: { xs: "32px", md: "5%" },
              padding: "0px 8px 0px 8px",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{
                  padding: "8px",
                  zIndex: "5",
                  display: "flex",
                  alignItems: { xs: "flex-start", sm: "center" },
                  flexDirection: "column",
                }}
              >
                <Box>
                  <HomeHeader
                    homeHeaderText={
                      config?.homepage_configurationConnection?.heading
                    }
                    homeHeaderSubtext={
                      config?.homepage_configurationConnection?.subheading
                    }
                  />
                </Box>
              </Grid>
              {useCase !== "1" ? (
                <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "center" },
                  alignItems: "center",
                  backgroundColor: "rgb(246, 246, 246)",
                }}
              >
                <Box
                  onClick={() =>
                    setModal(
                      <ZipCodeModal
                        setModalOpen={setModalOpen}
                        primaryColor={
                          config?.color_configurationConnection?.primary_color
                            ?.hex
                        }
                        tertiaryColor={
                          config?.color_configurationConnection?.secondary_color
                            ?.hex
                        }
                      />
                    )
                  }
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <LocationOnOutlinedIcon />
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", md: "16px" },
                      fontWeight: 600,
                    }}
                  >
                    {zipcode ? zipcode : "Set location"}
                  </Typography>
                </Box>
              </Grid>
              ) : (
                <Fragment></Fragment>
              )}
              
              
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={2}
                  // alignItems="center"
                  justifyContent="center"
                  display="flex"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    lg={9.5}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        zIndex: "9",
                        position: "relative",
                        marginRight: { xs: "0px", md: "0px" },
                        // display: "flex",
                        // justifyContent: "space-between",
                        // flexDirection: "row"
                      }}
                    >
                      {/* autocomplete box here */}
                      <Fragment>
                        <DrugSearch
                          sbHeight={"3.5"}
                          searchButtonClick={searchButtonClick}
                          setSearchButtonClick={setSearchButtonClick}
                        />
                      </Fragment>
                    </Box>
                  </Grid>
                  <Grid
                    xs={12}
                    md={3}
                    lg={2.5}
                    item
                    sx={{
                      display: "flex",
                      justifyContent: { xs: "center", md: "flex-end" },
                      alignItems: "flex-end",
                    }}
                  >
                    <Box
                      sx={{
                        // display: "flex",
                        // alignItems: "flex-end",
                        marginTop: { xs: "16px", md: "0px" },
                      }}
                    >
                      <DrugSearchButton
                        primaryColor={
                          config?.color_configurationConnection?.primary_color
                            ?.hex
                        }
                        tertiaryColor={
                          config?.color_configurationConnection?.secondary_color
                            ?.hex
                        }
                        setSearchButtonClick={setSearchButtonClick}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {config?.marketing?.powered_by_image?.url ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                    alignItems: "center",
                    backgroundColor: "rgb(246, 246, 246)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      color: "rgba(117, 117, 147, 1)",
                      fontSize: { xs: "10px", md: "16px" },
                      // width: { xs: "116px", md: "153px" },
                      height: { xs: "22px", md: "30px" },
                      backgroundColor: "rgb(246, 246, 246)",
                      gap: "4px"
                    }}
                  >
                     {/* <Typography sx={{fontSize: "12px", fontWeight: 600, color: "#757593"}}>
                        Powered By
                    </Typography> */}
                    <img
                      src={config?.marketing?.powered_by_image?.url}
                      alt={`${config?.marketing?.powered_by_image?.alt_text}`}
                      styles={{
                        height: { xs: "22px", md: "30px" },
                        backgroundColor: "none",
                      }}
                    ></img>
                  </Box>
                </Grid>
              ) : (
                <Fragment></Fragment>
              )}
              <Grid item xs={12}>
                {popularDrugsError === true ||
                popularDrugs === null ||
                (popularDrugs?.length === 0 && loading === false) ? (
                  <Box
                    sx={{ width: "100%", height: { xs: "75px", md: "150px" } }}
                  ></Box>
                ) : (
                  <Box
                    sx={{
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    <Fragment>
                      <PopularDrugButton
                        popularDrugs={popularDrugs}
                        loading={loading}
                        primaryColor={
                          config?.color_configurationConnection?.primary_color
                            ?.hex
                        }
                        tertiaryColor={
                          config?.color_configurationConnection?.secondary_color
                            ?.hex
                        }
                        opacity={`rgba(${config?.color_configurationConnection?.primary_color?.rgb?.r}, ${config?.color_configurationConnection?.primary_color?.rgb?.g}, ${config?.color_configurationConnection?.primary_color?.rgb?.b}, 0.15)`}
                        hoverOpacity={`rgba(${config?.color_configurationConnection?.primary_color?.rgb?.r}, ${config?.color_configurationConnection?.primary_color?.rgb?.g}, ${config?.color_configurationConnection?.primary_color?.rgb?.b}, 0.05)`}
                        pressedOpacity={`rgba(${config?.color_configurationConnection?.primary_color?.rgb?.r}, ${config?.color_configurationConnection?.primary_color?.rgb?.g}, ${config?.color_configurationConnection?.primary_color?.rgb?.b}, 0.2)`}
                        focusedOpacity={`rgba(${config?.color_configurationConnection?.primary_color?.rgb?.r}, ${config?.color_configurationConnection?.primary_color?.rgb?.g}, ${config?.color_configurationConnection?.primary_color?.rgb?.b}, 0.15)`}
                        disabledOpacity={`rgba(${config?.color_configurationConnection?.primary_color?.rgb?.r}, ${config?.color_configurationConnection?.primary_color?.rgb?.g}, ${config?.color_configurationConnection?.primary_color?.rgb?.b}, 0.05)`}
                        setSearchInput={setSearchInput}
                      />
                    </Fragment>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Container>
        {/* <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              padding: { xs: "5% 0 3%", sm: "3% 6% 0" },
              justifyContent: { xs: "center", sm: "flex-start" },
            }}
          >
            <Typography
              color="subtitle"
              sx={{ fontSize: "24px", fontWeight: "600" }}
            >
              How Waltz works.
            </Typography>
          </Box>
        </Grid>
        <Box borderTop="1px solid rgb(0, 0, 0)" sx={{ width: "100%" }}>
          <Container maxWidth="lg">
            <Box>
              <Grid container spacing={2}>
                <Fragment>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {howWaltzWorksContent ? (
                      howWaltzWorksContent?.map((x, index) => {
                        console.log("X: ", x);
                        return (
                          <HowWaltzWorksCards
                            index={index}
                            content={x}
                          />
                        );
                      })
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </Grid>
                </Fragment>
              </Grid>
            </Box>
          </Container>
        </Box> */}
      </Box>
    </Box>
  );
};

export default Home;
