import React, { Fragment, useContext, useState } from "react";
import AppContext from "../../AppContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import SurveyButton from "../../Components/SurveyButton/SurveyButton";
import IconButton from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const HowItWorks2 = () => {
  const { config, setModal, setModalOpen } = useContext(AppContext);
  const header = config?.how_it_works_configurationConnection?.heading;
  const subheader = config?.how_it_works_configurationConnection?.subheading;
  const [showFaq, setShowFaq] = useState();


  const showSurvey = config?.pages?.filter(
    (y) => y.relative_path?.toLowerCase() === "/howitworks"
  )[0]?.show_nps_cta;
  document.title =
    config?.pages?.filter(
      (y) => y.relative_path?.toLowerCase() === "/howitworks"
    )[0]?.page_title ||
    config?.pages?.filter(
      (y) => y.relative_path?.toLowerCase() === "/howitworks"
    )[0]?.title ||
    "How It Works";
  return (
    <Box sx={{ backgroundColor: "rgb(246, 246, 246)" }}>
      <Container maxWidth="lg">
        <Grid container spacing={2} padding="16px">
          {header.length > 0 && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginTop: { xs: "32px", md: "48px" },
                padding: "0px !important",
              }}
            >
              <Typography
                color="title"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  fontWeight: 600,
                  fontSize: { xs: "24px", md: "32px" },
                }}
              >
                {header}
              </Typography>
            </Grid>
          )}
          {subheader.length > 0 && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                marginBottom: { xs: "32px", md: "48px" },
                padding: "0px !important",
              }}
            >
              <Typography
                color="title"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  fontWeight: 400,
                  fontSize: { xs: "24px", md: "32px" },
                }}
              >
                {subheader}
              </Typography>
            </Grid>
          )}
          <Fragment>
            {config?.how_it_works_configurationConnection?.top_section?.map(
              (x, index) => {
                return index % 2 === 0 ? (
                  <Fragment>
                    <Grid
                      item
                      xs={12}
                      sm={5.5}
                      md={5}
                      sx={{
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        padding: {
                          xs: "0px !important",
                          md: "0px 24px 0px 0px",
                        },
                      }}
                    >
                      <Typography
                        color="title"
                        sx={{
                          fontWeight: 600,
                          fontSize: { xs: "24px", md: "32px" },
                        }}
                      >
                        {x.header}
                      </Typography>
                      <Typography
                        color="title"
                        sx={{
                          fontWeight: 400,
                          fontSize: { xs: "14px", md: "16px" },
                        }}
                        dangerouslySetInnerHTML={{
                          __html: x?.text,
                        }}
                      ></Typography>
                    </Grid>
                    <Grid
                      item
                      xs={0}
                      sm={0.5}
                      sx={{ display: { xs: "none", sm: "flex" } }}
                    ></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6.5}
                      sx={{
                        display: "grid",
                        height: { xs: "228px", md: "396px" },
                        marginBottom: "16px",
                        paddingLeft: { xs: "0px !important", sm: "16px" },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          maxWidth: { xs: "342px", md: "592px" },
                          backgroundImage: `url(${x?.image?.url})`,
                          position: "relative",
                          backgroundSize: "cover",
                          backgroundColor: "rgba(0, 0, 0, 0.11)",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                        }}
                        alt={x?.image?.alt_text}
                      />
                    </Grid>
                  </Fragment>
                ) : index % 2 !== 0 ? (
                  <Fragment>
                    <Grid
                      item
                      xs={0}
                      sm={6}
                      md={6.5}
                      sx={{
                        display: { xs: "none", sm: "grid" },
                        height: { xs: "228px", md: "396px" },
                        marginBottom: "16px",
                        paddingLeft: "0px !important",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          maxWidth: { xs: "342px", md: "592px" },
                          backgroundImage: `url(${x?.image?.url})`,
                          position: "relative",
                          backgroundSize: "cover",
                          backgroundColor: "rgba(0, 0, 0, 0.11)",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                        }}
                        alt={x?.image?.alt_text}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={0}
                      sm={0.5}
                      sx={{ display: { xs: "none", sm: "flex" } }}
                    ></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5.5}
                      md={5}
                      sx={{
                        flexDirection: "column",
                        padding: "0px !important",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        color="title"
                        sx={{
                          fontWeight: 600,
                          fontSize: { xs: "24px", md: "32px" },
                        }}
                      >
                        {x.header}
                      </Typography>
                      <Typography
                        color="title"
                        sx={{
                          fontWeight: 400,
                          fontSize: { xs: "14px", md: "16px" },
                        }}
                        dangerouslySetInnerHTML={{
                          __html: x?.text,
                        }}
                      ></Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={0}
                      sx={{
                        display: { xs: "grid", sm: "none" },

                        height: { xs: "228px", md: "396px" },
                        padding: "0px !important",
                        marginBottom: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          maxWidth: { xs: "342px", md: "592px" },
                          backgroundImage: `url(${x?.image?.url})`,
                          position: "relative",
                          backgroundSize: "cover",
                          backgroundColor: "rgba(0, 0, 0, 0.11)",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center",
                        }}
                        alt={x?.image?.alt_text}
                      />
                    </Grid>
                  </Fragment>
                ) : (
                  <Fragment></Fragment>
                );
              }
            )}
          </Fragment>

          <Fragment>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                padding: "8px 0px 8px 0px !important",
              }}
            >
              <Typography
                color="title"
                sx={{ fontWeight: 600, fontSize: { xs: "24px", md: "32px" } }}
              >
                Frequently Asked Questions
              </Typography>
            </Grid>
            {config?.how_it_works_configurationConnection?.faqs?.map(
              (x, index) => {
                return (
                  <Fragment>
                    <Grid
                      key={x.question}
                      item
                      xs={12}
                      onClick={() => {
                        showFaq === index ? (
                          setShowFaq(undefined)
                        ) : (
                          setShowFaq(index)
                        )
                      
                    }}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        flexDirection: "row",
                        alignItems: "center",
                        borderTop: "1px solid #B9B6B6",
                        padding: {
                          xs:
                            showFaq !== index
                              ? "8px 0px 8px 0px !important"
                              : "8px 0px 4px 0px !important",
                          md:
                          showFaq !== index
                              ? "16px 0px 16px 0px !important"
                              : "16px 0px 8px 0px !important",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontWeight: 600,
                          fontSize: {
                            xs: "18px !important",
                            md: "24px !important",
                          },
                          textTransform: "none",
                        }}
                      >
                        {x.question}
                      </Typography>
                      <IconButton
                        onClick={() => {
                            showFaq === index ? (
                              setShowFaq(undefined)
                            ) : (
                              setShowFaq(index)
                            )
                          
                        }}
                        disableRipple
                        sx={{
                          "&:hover": {
                            background: "none",
                          },
                          "&:focus": {
                            background: "none",
                          },
                          "&:active": {
                            background: "none",
                          },
                        }}
                      >
                        {showFaq !== index ? (
                          <KeyboardArrowRightIcon
                            color="title"
                            sx={{
                              fontSize: {
                                xs: "18px !important",
                                md: "24px !important",
                              },
                            }}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            color="title"
                            sx={{
                              fontSize: {
                                xs: "18px !important",
                                md: "24px !important",
                              },
                            }}
                          />
                        )}
                      </IconButton>
                    </Grid>
                    {showFaq === index ? (
                      <Grid
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: "8px 0px 8px 0px !important",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: { xs: "16px", md: "20px" },
                            fontWeight: 400,
                            padding: "0px !important",
                          }}
                          dangerouslySetInnerHTML={{ __html: x.answer }}
                        ></Typography>
                      </Grid>
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </Fragment>
                );
              }
            )}
          </Fragment>
        </Grid>
        {showSurvey && (
          <SurveyButton
            setModal={setModal}
            setModalOpen={setModalOpen}
            config={config}
          />
        )}
      </Container>
    </Box>
  );
};

export default HowItWorks2;
