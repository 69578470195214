import React, { Fragment } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import ListSubheader from "@mui/material/ListSubheader";
import PricingFooter from "../PricingFooter/PricingFooter";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";


const Footer = (
  {
    backgroundColor,
    pricingTopText,
    topText,
    selectedIcon,
    selectedDescription,
    bottomText,
    footerLinks,
    config,
    logoAltText,
    disclaimerText,
    pricingFooterConfig
  },
  ...props
) => {

  const current = new Date().getFullYear();

  const location = useLocation();


  return (
    <Fragment>
      {location.pathname?.toLowerCase() === "/mysavings" || location.pathname?.toLowerCase() === "/findmedication" ? (
        <PricingFooter pricingFooterConfig={pricingFooterConfig}/> 
      ) : (
          <></>
        )}
    <Box
      sx={{
        marginTop: "auto",
        backgroundColor: backgroundColor,
        width: "100%",
        display: "block",
        fontSize: "14px",
        color: "rgb(255, 255, 255)",
        boxSizing: "border-box",
      }}
    >
      <Box sx={{ paddingTop: "40px", paddingBottom: "40px" }}>
        {" "}
        {/*optional branded footer*/}
        <Container
          maxWidth="lg"
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Grid container spacing={2} justifyContent="space-between">
            {config?.footer_configurationConnection?.socials?.facebook?.link
              ?.href.length > 0 ? (
              <Grid
                item
                xs={0}
                md={4}
                lg={3}
                sx={{
                  display: {xs: "none", md: "flex"},
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  // alignItems: "flex-start",
                  width: "100%",
                  // ml: "2%",
                  mb: "2%"
                }}
              >
                <Box>
                      <Box
                      sx={{
                        display: { xs: "none", md: "flex" },
                        justifyContent: "flex-start",
                        alignItems: "center",
                        maxWidth: "180px",
                        minHeight: "80px",
                        height: "80px",
                        backgroundImage: `url("${selectedIcon}")`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: 'contain',
                        backgroundPosition: 'left',
                      }}
                      alt={`${logoAltText}`}
                    />
                    {/* )
                   } */}
                  {!!config.footer_configurationConnection
                    .footer_mission_statement ? (
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "14px", marginBottom: "16px",
                      display: { xs: "none", md: "flex" },
                      justifyContent: "flex-end"
                     }}
                      dangerouslySetInnerHTML={{
                        __html:
                          config.footer_configurationConnection
                            .footer_mission_statement,
                      }}
                    ></Typography>
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
            ) : (
              <Fragment>
                <Grid
                  item
                  xs={0}
                  md={5}
                  lg={5}
                  sx={{
                    display: { xs: "none", md: "flex" },
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: { xs: "none", md: "flex" },
                      justifyContent: "flex-start",
                      alignItems: "center",
                      minWidth: "180px",
                      maxHeight: "80px",
                      minHeight: "80px",
                      backgroundImage: `url("${encodeURIComponent(selectedIcon)}")`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: 'contain',
                      backgroundPosition: 'left'
                    }}
                    alt={`${logoAltText}`}
                  />
                  {!!config.footer_configurationConnection
                    .footer_mission_statement ? (
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "14px",
                        marginBottom: "16px",
                        display: { xs: "none", md: "flex" },
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          config.footer_configurationConnection
                            .footer_mission_statement,
                        // props?.type === "pricing" ? pricingTopText : topText,
                      }}
                    ></Typography>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={0}
                  sx={{
                    display: { xs: "flex", md: "none" },
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {!!config.footer_configurationConnection
                    .footer_mission_statement ? (
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "14px", marginBottom: "16px" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          config.footer_configurationConnection
                            .footer_mission_statement,
                      }}
                    ></Typography>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Fragment>
            )}
            <Grid
              item
              xs={0}
              md={5}
              lg={5}
              sx={{ display: { xs: "none", md: "inline" } }}
            >

              <Box sx={{display: "flex", justifyContent: "flex-end", flexDirection: "column"}}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  display: "flex",
                  justifyContent: "flex-start",
                  color: "white",
                  ml: "3%"
                }}
              >
                Quick Links
              </Typography>
              <List
                sx={{
                  // columnGap: "20px",
                  columnCount: "2",
                  listStyleType: "None",
                  paddingLeft: "0px",
                }}
              >
                {config?.footer_configurationConnection?.footer_links[0]?.links?.map(
                  (x) => {
                    if (x.link.href.startsWith("https") === true) {
                      return (
                        <ListItem
                          key={x.link?.href}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Typography
                            variant="body2"
                            className="footer-link"
                            sx={{ fontSize: "14px", color: "white" }}
                            component={Link}
                            href={`${x.link.href}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {x.link?.title}
                          </Typography>
                      </ListItem>
                      );
                    } else {

                    return (
                      <ListItem
                        key={x.link?.href}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          variant="body2"
                          className="footer-link"
                          sx={{ fontSize: "14px", color: "white" }}
                          component={RouterLink}
                          to={`${x.link?.href}`}
                        >
                          {x.link?.title}
                        </Typography>
                      </ListItem>
                    );
                      }
                  }
                )}
                {config?.footer_configurationConnection?.footer_links[1]?.links?.map(
                  (x) => {
                    if (x.link.href.startsWith("https") === true) {
                      return (
                        <ListItem
                          key={x.link?.href}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Typography
                            variant="body2"
                            className="footer-link"
                            sx={{ fontSize: "14px", color: "white", textDecoration: "none", "&:hover": {
                              textDecoration: "underline"
                            } }}
                            component={Link}
                            href={`${x.link?.href}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {x.link?.title}
                          </Typography>
                        </ListItem>
                      );
                    } else {
                    return (
                      <ListItem
                        key={x.link?.href}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          variant="body2"
                          className="footer-link"
                          sx={{ fontSize: "14px", color: "white" }}
                          component={RouterLink}
                          to={`${x.link?.href}`}
                        >
                          {x.link?.title}
                        </Typography>
                      </ListItem>
                    );
                      }
                  }
                )}
              </List>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={0}
              sx={{
                display: { xs: "flex", md: "none" },
                flexDirection: "column",
                paddingLeft: { xs: "16px", md: "none" }
              }}
            >
              <Typography
                sx={{
                  // margin: "5px 0px 10px",
                  fontSize: "16px",
                  fontWeight: "600",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Quick Links
              </Typography>
              <List
                sx={{
                  // columnGap: "40px",
                  columnCount: "1",
                  listStyleType: "None",
                  paddingLeft: "0px",
                  color: "white",
                }}
              >
                {config?.footer_configurationConnection?.footer_links[0]?.links?.map(
                  (x) => {
                    if (x.link.href.startsWith("https") === true) {
                      return (
                        <ListItem
                          key={x.link?.href}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="body2"
                            className="footer-link"
                            sx={{ fontSize: "14px", color: "white" }}
                            component={Link}
                            href={`${x.link?.href}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {x.link?.title}
                          </Typography>
                        </ListItem>
                      );
                    } else {

                    return (
                      <ListItem
                        key={x.link?.href}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          className="footer-link"
                          sx={{ fontSize: "14px", color: "white" }}
                          component={RouterLink}
                          to={`${x.link?.href}`}
                        >
                          {x.link?.title}
                        </Typography>
                      </ListItem>
                    );
                      }
                  }
                )}
                {config?.footer_configurationConnection?.footer_links[1]?.links?.map(
                  (x) => {
                    if (x.link.href.startsWith("https") === true) {
                      return (
                        <ListItem
                          key={x.link?.href}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="body2"
                            className="footer-link"
                            sx={{ fontSize: "14px", color: "white" }}
                            component={Link}
                            href={`${x.link?.href}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {x.link.title}
                          </Typography>
                        </ListItem>
                      );
                    } else {
                    return (
                      <ListItem
                        key={x.link?.href}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="body2"
                          className="footer-link"
                          sx={{ fontSize: "14px", color: "white" }}
                          component={RouterLink}
                          to={`${x.link?.href}`}
                        >
                          {x.link?.title}
                        </Typography>
                      </ListItem>
                    );
                      }
                  }
                )}
              </List>
            </Grid>

            <Grid
              item
              xs={12}
              md={0}
              sx={{
                display: { xs: "flex", md: "none" },
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "180px",
                  height: "80px",
                  // minWidth: {xs: "60%", sm: "50%"},
                  // minHeight: {xs: "4rem", sm: "5rem"},
                  backgroundImage: `url("${selectedIcon}")`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: 'contain',
                  backgroundPosition: 'center'
                }}
                alt={`${logoAltText}`}
              />
            </Grid>
            {config?.footer_configurationConnection?.socials?.facebook?.link
              ?.href.length > 0 ? (
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box>
                  {config?.footer_configurationConnection?.socials?.facebook
                    ?.link?.href && (
                    <a
                      style={{ margin: "0 5px" }}
                      href={
                        config?.footer_configurationConnection?.socials
                          ?.facebook?.link?.href
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        style={{ width: "24px", height: "auto" }}
                        src={`/facebookLink.png`}
                        alt={"Facebook"}
                      ></img>
                    </a>
                  )}
                  {config?.footer_configurationConnection?.socials?.twitter
                    ?.link?.href && (
                    <a
                      style={{ margin: "0 5px" }}
                      href={
                        config?.footer_configurationConnection?.socials?.twitter
                          ?.link?.href
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        style={{ width: "24px", height: "auto" }}
                        src={`/twitterLink.png`}
                        alt={"Twitter"}
                      ></img>
                    </a>
                  )}
                  {config?.footer_configurationConnection?.socials?.linkedin
                    ?.link?.href && (
                    <a
                      style={{ margin: "0 5px" }}
                      href={
                        config?.footer_configurationConnection?.socials
                          ?.linkedin?.link?.href
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        style={{ width: "24px", height: "auto" }}
                        src={`/linkedInLink.png`}
                        alt={"LinkedIn"}
                      ></img>
                    </a>
                  )}
                  {config?.footer_configurationConnection?.socials?.pinterest
                    ?.link?.href && (
                    <a
                      style={{ margin: "0 5px" }}
                      href={
                        config?.footer_configurationConnection?.socials
                          ?.pinterest?.link?.href
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        style={{ width: "24px", height: "auto" }}
                        src={`/pinterestLink.png`}
                        alt={"Pinterest"}
                      ></img>
                    </a>
                  )}
                  {config?.footer_configurationConnection?.socials?.instagram
                    ?.link?.href && (
                    <a
                      style={{ margin: "0 5px" }}
                      href={
                        config?.footer_configurationConnection?.socials
                          ?.instagram?.link?.href
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        style={{ width: "24px", height: "auto" }}
                        src={`/instagramLink.png`}
                        alt={"Instagram"}
                      ></img>
                    </a>
                  )}
                  {config?.footer_configurationConnection?.socials?.youtube
                    ?.link?.href && (
                    <a
                      style={{ margin: "0 5px" }}
                      href={
                        config?.footer_configurationConnection?.socials?.youtube
                          ?.link?.href
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        style={{ width: "24px", height: "auto" }}
                        src={`/youtubeLink.png`}
                        alt={"YouTube"}
                      ></img>
                    </a>
                  )}
                </Box>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} sx={{mt: {xs: "3%", md: "5%"}}}>
              <Typography
                variant="body1"
                className={'footerDisclaimer'}
                // color="#fffff"
                sx={{
                  fontSize: "12px",
                  marginBottom: "16px",
                  letterSpacing: "0.00938em",
                  textDecoration: "none",
                  color: "#fffff",
                  
                }}
                dangerouslySetInnerHTML={{
                  __html: config.footer_configurationConnection.disclaimer,
                  // props?.type === "pricing" ? pricingTopText : topText,
                }}
              ></Typography>
              <Typography
                variant="body1"
                // color="#fffff"
                sx={{
                  fontSize: "12px",
                  marginBottom: "16px",
                  letterSpacing: "0.00938em",
                  textDecoration: "none",
                  color: "#fffff"
                }}
                dangerouslySetInnerHTML={{
                  __html: config.footer_configurationConnection.copyright_text?.replace("%%year%%", `${current}` || ""),
                  // props?.type === "pricing" ? pricingTopText : topText,
                }}
              ></Typography>
              {/* <Typography variant="body1" sx={{ fontSize: "12px" }}>
                  {bottomText}
                </Typography> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
    </Fragment>
  );
};

Footer.propTypes = {
  backgroundColor: PropTypes.string,
  pricingTopText: PropTypes.string,
  topText: PropTypes.string,
  selectedIcon: PropTypes.string,
  selectedDescription: PropTypes.string,
  selectedAddressLink: PropTypes.string,
  selectedAddress: PropTypes.string,
  selectedPhone: PropTypes.string,
  selectedEmail: PropTypes.string,
  bottomText: PropTypes.string,
  selectedName: PropTypes.string,
  footerLinks: PropTypes.array,
  config: PropTypes.object,
  logoAltText :  PropTypes.string,
  disclaimerText : PropTypes.string,
  pricingFooterConfig : PropTypes.string
};



export default Footer;
