import React, { useState, useEffect, useCallback } from "react";
import { Button, Box, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import API from "../../Services/API";
import SuccessModal from "../SuccessModal/SuccessModal";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import PropTypes from "prop-types";
import Checkbox from '@mui/material/Checkbox';


const ShareModal = ({
  // handleClose,
  quantityAndDose,
  // buttonColor,
  setModalOpen,
  name,
  form,
  strength,
  quantity,
  bin,
  pcn,
  group,
  memberId,
  discountCardName,
  price,
  primaryColor,
  tertiaryColor,
  config,
  setModal,
  smsDisclaimer,
  emailDisclaimer,
  shareModalApi
}) => {
  const [shareStatus, setShareStatus] = useState("Text");
  const [loading, setLoading] = useState(false);
  const [cellNumber, setCellNumber] = useState("");
  const [validatedCellNumber, setValidatedCellNumber] = useState(false);
  const [email, setEmail] = useState("");
  const [validatedEmail, setValidatedEmail] = useState(false);
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");
  const [submitChecked, setSubmitChecked] = useState(true)

  const [focused, setFocused] = useState(0);

  const handleClickText = () => {
    setShareStatus("Text");
    setFocused(0);
  };
  const handleClickEmail = () => {
    setShareStatus("Email");
    setFocused(1);
  };

  useEffect(() => {
    setValidatedEmail(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
    );
  }, [email]);

  useEffect(() => {
    //add regex validation here
    setValidatedCellNumber(
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(cellNumber)
    );
  }, [cellNumber]);

  const messageAgreement = useCallback(async () => {
      setLoading(true);
      if (shareStatus === "Text") {
        try {
          await API.post(`PricingAPI/reminder/${shareModalApi?.toLowerCase() || "v1"}`,
            {
              data: {
                phoneNumber: cellNumber,
              },
            }
          );
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      } else if(shareStatus === "Email") {
        try {
          await API.post(`PricingAPI/reminder/${shareModalApi?.toLowerCase() || "v1"}`,
            {
              data: {
                emailId: email,
              },
            }
          );
          
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellNumber, email, shareStatus]);

  const sendSms = useCallback(async () => {
    setLoading(true);
    try {
      await API.post(`/CouponAPI/coupon/sms/${config?.api_version_configurationConnection?.sharing_api_version?.toLowerCase() ||"v1"}`,
        {
          data: {
            memberId: memberId,
            drugName: name,
            cardName: discountCardName,
            transactionId: JSON.parse(sessionStorage.getItem("transactionId"))
              ?.transactionId,
            form,
            strength,
            quantity,
            bin,
            pcn,
            group,
            price,
            toMobile: `+1${cellNumber}`,
          },
        }
      );
      messageAgreement();
      setModalOpen(false);
      setLoading(false);
      setModal(
        <SuccessModal
          primaryColor={primaryColor}
          tertiaryColor={tertiaryColor}
          setModalOpen={setModalOpen}
        />
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [
    memberId,
    name,
    form,
    strength,
    quantity,
    bin,
    pcn,
    group,
    price,
    cellNumber,
    discountCardName,
    messageAgreement,
    config?.api_version_configurationConnection?.sharing_api_version, primaryColor, setModal, setModalOpen, tertiaryColor
  ]);

  const sendEmail = useCallback(async () => {
    setLoading(true);
    try {
      await API.post(
        `/CouponAPI/coupon/email/${
          config?.api_version_configurationConnection?.sharing_api_version?.toLowerCase() ||
          "v1"
        }`,
        {
          data: {
            memberId: memberId,
            // waltzMemberId:
            drugName: name,
            cardName: discountCardName,
            form,
            strength,
            quantity,
            bin,
            pcn,
            group,
            price,
            ToEmail: email,
          },
        }
      );

      messageAgreement();
      setModalOpen(false);
      setLoading(false);
      setModal(
        <SuccessModal
          primaryColor={primaryColor}
          tertiaryColor={tertiaryColor}
          setModalOpen={setModalOpen}
        />
      );
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, [
    memberId,
    name,
    form,
    strength,
    quantity,
    bin,
    pcn,
    group,
    price,
    email,
    discountCardName,
    messageAgreement,
    config?.api_version_configurationConnection?.sharing_api_version, primaryColor, setModal, setModalOpen, tertiaryColor
  ]);

  const handleEmailEnter = (e) => {
    if (e.keyCode === 13) {
      if (
        shareStatus === "Email" &&
        name &&
        form &&
        strength &&
        quantity &&
        bin &&
        pcn &&
        group &&
        price &&
        email &&
        validatedEmail
      ) {
        sendEmail();
      }
    }
  };

  const handleTextEnter = (e) => {
    if (e.keyCode === 13) {
      if (
        shareStatus === "Text" &&
        name &&
        form &&
        strength &&
        quantity &&
        bin &&
        pcn &&
        group &&
        price &&
        cellNumber &&
        validatedCellNumber
      ) {
        sendSms();
      }
    }
  };

  const formatPhoneNumber = (e) => {
    let formattedNumber;
    const { value } = e.target;
    const { length } = value;
    // Filter non numbers
    const regex = () => value.replace(/[^0-9]+/g, "");
    // Set area code with parenthesis around it
    const areaCode = () => `(${regex().slice(0, 3)})`;

    // Set formatting for first six digits
    const firstSix = () => `${areaCode()}-${regex().slice(3, 6)}`;

    // Dynamic trail as user types
    const trailer = (start) => `${regex().slice(start, regex().length)}`;
    if (length <= 3) {
      // First 3 digits
      formattedNumber = regex();
    } else if (length === 4) {
      // After area code
      formattedNumber = `${areaCode()}-${trailer(3)}`;
    } else if (length === 5) {
      // When deleting digits inside parenthesis
      formattedNumber = `${areaCode().replace(")", "")}`;
    } else if (length > 5 && length <= 9) {
      // Before dash
      formattedNumber = `${areaCode()}-${trailer(3)}`;
    } else if (length >= 10) {
      // After dash
      formattedNumber = `${firstSix()}-${trailer(6)}`;
    }
    setFormattedPhoneNumber(formattedNumber || "");
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: { xs: "45%", sm: "40%", md: "40%" },
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: { xs: "90%", sm: "75%", md: "50%" },
        bgcolor: "background.paper",
        borderRadius: "16px",
        boxShadow: 24,
        padding: "8px",
        display: "flex",
        alignItems: "space-between",
      }}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={10}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              alignSelf: "flex-end",
              fontWeight: "400",
              fontSize: "1.25em",
              padding: { xs: "16px 0px 0px 16px", md: "16px 0px 0px 24px" },
            }}
          >
            Share Coupon by {shareStatus}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            alignContent: "flex-start",
            justifyContent: "flex-end",
            padding: "0%",
          }}
        >
          <IconButton
            aria-label="close"
            sx={{ padding: "0", "&:hover": { background: "none" } }}
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon sx={{ display: "flex", alignSelf: "flex-start" }} />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignSelf: "flex-end",
            color: "#757593",
            fontSize: "14px",
            padding: 0,
          }}
        >
          {name && (
            <Typography
              sx={{
                padding: { xs: "0px 0px 0px 16px", md: "0px 0px 0px 24px" },
              }}
            >
              {name} | {quantityAndDose}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              borderRadius: "10px",
              backgroundColor: "rgba(234, 232, 232, 1)",
              boxShadow: "1px 2px 4px 1px rgba(0, 0, 0, 0.25)",
              margin: { xs: "0px 16px  8px 16px", md: "0px 24px 8px 24px" },
            }}
          >
            <Button
              onClick={() => handleClickText()}
              data-testid={"text-button"}
              sx={{
                textTransform: "none",
                width: "50%",
                color: focused === 0 ? "#504847" : "#504847",
                background: focused === 0 ? tertiaryColor : "none",
                fontSize: { xs: "14px", md: "16px" },
                alignItems: "center",
                margin: "1%",
                "&:hover": {
                  backgroundColor: tertiaryColor,
                  color: "#504847",
                  margin: "1%",
                },
                "&:focus": {
                  backgroundColor: tertiaryColor,
                  color: "#504847",
                  margin: "1%",
                },
              }}
            >
              <ChatOutlinedIcon />
              Text
            </Button>
            <Button
              onClick={() => handleClickEmail()}
              data-testid={"email-button"}
              sx={{
                textTransform: "none",
                width: "50%",
                color: focused === 1 ? "#504847" : "#504847",
                background: focused === 1 ? tertiaryColor : "none",
                fontSize: { xs: "14px", md: "16px" },
                alignItems: "center",
                margin: "1%",
                "&:hover": {
                  backgroundColor: tertiaryColor,
                  color: "#504847",
                  margin: "1%",
                },
                "&:focus": {
                  backgroundColor: tertiaryColor,
                  color: "#504847",
                  margin: "1%",
                },
              }}
            >
              <MailOutlineRoundedIcon />
              Email
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {shareStatus === "Text" ? (
            <Typography
              variant="subtitle1"
              data-testid={"text-message"}
              sx={{
                padding: { xs: "0px 0px 0px 16px", md: "0px 0px 0px 24px" },
              }}
            >
              Enter your phone number below to receive your coupon card:
            </Typography>
          ) : (
            <Typography
              variant="subtitle1"
              data-testid={"email-message"}
              sx={{
                padding: { xs: "0px 0px 0px 16px", md: "0px 0px 0px 24px" },
              }}
            >
              Enter your email address below to receive your coupon card:
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ margin: { xs: "0px 16px  8px 16px", md: "0px 24px 8px 24px" } }}
        >
          {shareStatus === "Email" ? (
            <TextField
              key="email"
              id="outlined-email-input"
              variant="outlined"
              label={"johnsmith@gmail.com"}
              sx={{
                "& fieldset": {
                  borderRadius: "40px !important",
                },
                width: "100%",
                "&:focus": { color: "black", fontWeight: 500 },
              }}
              value={email}
              onChange={(e) => setEmail(e?.target?.value?.trim())}
              onKeyDown={handleEmailEnter}
              error={!validatedEmail && email?.length > 0}
              helperText={
                !validatedEmail && email?.length > 0
                  ? "Please enter a valid email address"
                  : undefined
              }
            />
          ) : (
            <TextField
              key="cell"
              id="outlined-cell-input"
              variant="outlined"
              label={"(234)-567-8910"}
              sx={{
                "& fieldset": {
                  borderRadius: "40px !important",
                },
                width: "100%",
                "&:focus": { color: "black", fontWeight: 500 },
              }}
              value={formattedPhoneNumber}
              onKeyDown={handleTextEnter}
              onChange={(e) => {
                formatPhoneNumber(e);
                setCellNumber(
                  e?.target?.value
                    ?.trim()
                    ?.replace("-", "")
                    ?.replace("(", "")
                    ?.replace(")", "")
                    ?.replace(".", "")
                    ?.replace(" ", "")
                    ?.replace("-", "")
                );
              }}
              error={!validatedCellNumber && cellNumber?.length > 0}
              helperText={
                !validatedCellNumber && cellNumber?.length > 0
                  ? "Please enter a valid phone number"
                  : undefined
              }
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {shareStatus === "Text" ? (
            <Box sx={{display: "flex", flexDirection: "row"}}>
            <Checkbox 
              disableFocusRipple
              disableRipple
              disableTouchRipple
              checked={submitChecked} 
              onChange={() => {
                setSubmitChecked(submitChecked => !submitChecked)
              }} 
              sx={{display: "flex", alignItems: "flex-start"}}/>
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                color: "#757593",
                textDecoration: "none",
                padding: { xs: "0px 16px  8px 16px", md: "0px 24px 8px 24px" },
              }}
              dangerouslySetInnerHTML={{
                __html:
                  smsDisclaimer,
              }}
            />
            </Box>
          ) : (
            <Box sx={{display: "flex", flexDirection: "row"}}>
            <Checkbox 
              disableFocusRipple
              disableRipple
              disableTouchRipple
              checked={submitChecked} 
              onChange={() => {
                setSubmitChecked(submitChecked => !submitChecked)
              }} 
            sx={{display: "flex", alignItems: "flex-start"}}
            />
            <Typography
              variant="body1"
              sx={{
                fontSize: "12px",
                color: "#757593",
                textDecoration: "none",
                padding: { xs: "0px 16px  8px 16px", md: "0px 24px 8px 24px" },
              }}
              dangerouslySetInnerHTML={{
                __html:
                  emailDisclaimer,
              }}
            />
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            marginBottom: { xs: "8px", md: "16px" },
          }}
        >
          <Button
            onClick={() => setModalOpen(false)}
            variant="outlined"
            sx={{
              display: "flex",
              alignItems: "center",
              padding: { xs: "8px 24px 8px 24px", md: "10px 24px 10px 24px" },
              color: "black",
              borderRadius: "32px",
              width: "90%",
              borderColor: "black",
              textTransform: "none",
              fontSize: { xs: "14px", md: "20px" },
              height: { xs: "40px", md: "48px" },
              "&:hover": {
                color: primaryColor,
                borderColor: primaryColor,
              },
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            marginBottom: { xs: "8px", md: "16px" },
            // maxHeight: {xs: "35px", md: "48px"},
          }}
        >
          <Button
            variant="outlined"
            data-attribute={shareStatus === "Text" ? "Sms" : "Email"}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: { xs: "8px 24px 8px 24px", md: "10px 24px 10px 24px" },
              color: tertiaryColor,
              borderRadius: "32px",
              backgroundColor: primaryColor,
              fontSize: { xs: "14px", md: "20px" },
              textTransform: "none",
              width: "90%",
              height: { xs: "40px", md: "48px" },
              "&:hover": {
                color: primaryColor,
                borderColor: primaryColor,
              },
              "&:disabled": {
                backgroundColor: "#D4D4D4",
                color: "#AFAFAF",
              },
            }}
            onClick={
              shareStatus === "Text"
                ? sendSms
                : shareStatus === "Email"
                ? sendEmail
                : () => {}
            }
            disabled={
              shareStatus === "Text" &&
              name &&
              form &&
              strength &&
              quantity &&
              bin &&
              pcn &&
              group &&
              price &&
              formattedPhoneNumber &&
              submitChecked &&
              validatedCellNumber
                ? false
                : shareStatus === "Email" &&
                  name &&
                  form &&
                  strength &&
                  quantity &&
                  bin &&
                  pcn &&
                  group &&
                  price &&
                  email &&
                  submitChecked &&
                  validatedEmail
                ? false
                : loading
                ? false
                : true
            }
          >
            Send Coupon
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

ShareModal.propTypes = {
  handleClose: PropTypes.func,
  quantityAndDose: PropTypes.string,
  buttonColor: PropTypes.string,
  setModalOpen: PropTypes.func,

  name: PropTypes.string,
  form: PropTypes.string,
  strength: PropTypes.string,
  quantity: PropTypes.string,
  bin: PropTypes.string,
  pcn: PropTypes.string,
  group: PropTypes.string,
  memberId: PropTypes.string,
  discountCardName: PropTypes.string,
  price: PropTypes.string,
  primaryColor: PropTypes.string,
  tertiaryColor: PropTypes.string,
  config: PropTypes.object,
  setModal: PropTypes.func,
  smsDisclaimer: PropTypes.string,
  emailDisclaimer: PropTypes.string,
  shareModalApi: PropTypes.string,
};

export default ShareModal;
