// import Contentstack from "contentstack"
// import ContentstackLivePreview from "@contentstack/live-preview-utils";

export const callLivePreview = async (Stack) => {
    
  // const assets = await Stack.Assets().Query().toJSON().find();
  Stack.headers.access_token = "csd6b5210a03173fc6aa65d99a"
  const env_response = await Stack.ContentType('environment_configuration').Query().toJSON().find();
  const env = env_response[0][0]

  let livePreviewContentType = Stack.live_preview.content_type_uid ? Stack.live_preview.content_type_uid : 'page' // this or statement is for testing locally
  let livePreviewEtnryUID = Stack.live_preview.entry_uid ? Stack.live_preview.entry_uid : 'bltb338af3afca88842' // this or statement is for testing locally
  console.log(Stack)
  console.log(livePreviewContentType,livePreviewEtnryUID)
  // for pages to get the page group. A page is not linked to a portal
  if (livePreviewContentType === 'page') {
      const pageGroups = await Stack.ContentType('page_group_configuration').Query().toJSON().find();
      const pageGroup = pageGroups[0].filter((group) => {
        const {page} = group
        return page.some(obj => obj.uid ===livePreviewEtnryUID)
      })
      livePreviewContentType = 'page_group_configuration'
      livePreviewEtnryUID = pageGroup[0]['uid']
  } 
  // Stack.headers.access_token = "csd6b5210a03173fc6aa65d99a"
  // const event =  await Stack.ContentType(livePreviewContentType).Entry(livePreviewEtnryUID).toJSON().fetch()

  Stack.headers.access_token = "csd6b5210a03173fc6aa65d99a"
  // to get the portal we want
  const portals = await Stack.ContentType('portal').Query().where(livePreviewContentType==='pricing_coupon_configuration' ? 'pricing_coupon_page_configuration' : livePreviewContentType,[{'uid': livePreviewEtnryUID, '_content_type_uid' : livePreviewContentType}]).toJSON().find();
  // 
  console.log(portals)
  // to grab the portal. The second index is the portal: need to look into this for different portal
  const portal = portals[0][0]
  // to transform json to our config
  console.log('Portal', portal)
  const sub_content = await Promise.all(Object.entries(portal).map(async (entry) => {
    const key  = entry[0]
    const value = entry[1]
    const connection_key = key.concat('Connection')
    // the only get ones that have an id
    if (key.split('_').slice(-1)[0]==='configuration' && value.length >0 ) {
      const uid = value[0]['uid']
      const content_type = value[0]['_content_type_uid']
      Stack.headers.access_token = "csd6b5210a03173fc6aa65d99a"
      const sub_content_entry = await Stack.ContentType(content_type).Entry(uid).toJSON().fetch();
      
      if (key === 'page_group_configuration') {
        let pages = []
        if (Stack.live_preview.content_type_uid==='page') {
          const page = await Stack.ContentType(Stack.live_preview.content_type_uid).Entry(Stack.live_preview.entry_uid).toJSON().fetch();
          pages.push(page)
        } else {
        pages = await Promise.all(sub_content_entry['page'].map(async (page) => {
          Stack.headers.access_token = "csd6b5210a03173fc6aa65d99a"
          return await Stack.ContentType(page['_content_type_uid']).Entry(page['uid']).toJSON().fetch()
        })
        ) 
      } 
      
        
        return {
          'pages': pages
        }
      }
      if (key ==='coupon_configuration') {
        // need to bring up coupon configuration
        const coupon_config = sub_content_entry['coupon_configuration']
        const blocks = coupon_config['modular_blocks'].reduce((acum,currentVal) => {
          const entries = Object.entries(currentVal)[0]
          acum[entries[0]] = entries[1]
          return acum
        },{})
        const title = sub_content_entry['title']
        return {
          'coupon_configurationConnection': {'title': title, ...coupon_config, ...blocks}
        }
      }
      if (key ==='footer_configuration') {
        // to extract the socials into an object
        const socials = sub_content_entry['social_module'].reduce((acum,currentVal) => {
          const entries = Object.entries(currentVal)[0]
          acum[entries[0]] = entries[1]
          return acum
        }, {})
        return {
          [connection_key]: {...sub_content_entry, 'socials': socials}
        }
      }

      if (key ==='marketing_brand_configuration') {
        // to extract the file information
        const entries = Object.entries(sub_content_entry).reduce((acum,currentVal) => {
          const key = currentVal[0]
          const value = currentVal[1]
            if (value?.file) {
              acum[key] = {...value, ...value['file']}
            }
            else  {
              acum[key] = value
            }
            return acum
        }, {})
        
        return {'marketing' : {...entries}}
    }

    if (key === 'color_configuration') {
      const {accent_tertiary_color,primary_color,secondary_color} = sub_content_entry
      const newColorConfig = Object.entries(sub_content_entry).reduce((acum,currentVal) => {
          const key = currentVal[0]
          const value = currentVal[1]
          if (value ==='Primary') {
              acum[key] = primary_color
          }
          else if (value === 'Secondary') {
              acum[key] = secondary_color
          }
          else if (value === 'Accent/Tertiary') {
              acum[key] = accent_tertiary_color
          }
          else {
              acum[key] = value
          }
          return acum
      }, {})
      return {
          [connection_key] : newColorConfig
      }
    }


      
      return {
        [connection_key]: sub_content_entry
      }
    }
    else if (key.split('_').slice(-1)[0]==='configuration' && value.length ===0) {
      return {[connection_key]: value}
    }
    else {
      return {[key]: value}
    }
    
  }))

  const reduced_obj = sub_content.reduce((acum,currentVal) => {
      const entries = Object.entries(currentVal)[0]
      acum[entries[0]] = entries[1]
      return acum
  },{})

  // placeholders
  const placeholders = reduced_obj['placeholder_configurationConnection']['placeholders']
  let string_value = JSON.stringify(reduced_obj)
  if (placeholders) {
  for (let i=0; i <placeholders.length; i ++) {
    const placeholder = placeholders[i]
      string_value = string_value.replaceAll('%%'.concat(placeholder['key'],'%%'),placeholder['value']) 
  }
  }
  // to rename to page_content_module
  string_value = string_value.replaceAll('page_module:','page_content_module:').replaceAll('page_content:','page_content_module:').replaceAll('image_file','image')

  const config_obj = JSON.parse(string_value)

  const config_obj_with_env = {...config_obj , 'env': env}

  console.log(config_obj_with_env)

  return config_obj_with_env
};