import React, { useContext, Fragment, useEffect, useState } from "react";
import AppContext from "../../AppContext";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import API from "../../Services/API";
import uuid from "react-uuid";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { CancelToken, isCancel } from "axios";

const DrugSearch = ({
  sbHeight,
  searchButtonClick,
  setSearchButtonClick,
  setMenuOpen,
  onSelection,
  modalView = false,
  // useHelperTextStyles,
  searchInput
}) => {
  const {
    recentSearches,
    setRecentSearches,
    savedCoupons,
    memberId,
    sourceId,
    // transactionId,
    setTransactionId,
    config,
    setSearchInput,
    useCase,
    coordinates
    // searchInput
  } = useContext(AppContext);

  const navigate = useNavigate();
  // const helperTextStyles = useHelperTextStyles(); 

  const [autocompleteError, setAutocompleteError] = useState(false);
  const [borderRound, setBorderRound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState(searchInput || ""); //searchInput || ""
  const [localTransactionId, setLocalTransactionId] = useState(JSON.parse(sessionStorage.getItem('transactionId'))?.transactionId || uuid());
  const [options, setOptions] = useState([]);
  const [modifiedRecentSearches, setModifiedRecentSearches] = useState(
    recentSearches.map((x) => {
      return {
        name: x?.name,
        brandGeneric: x?.brandGeneric,
        displayName: x.alternateName, //
        navigation_ndc: x.ndc,
        quantity: x?.quantity,
        type: "Recent Searches",
      };
    })
  );

  useEffect(() => {
    // console.log(searchInput)
    setInputText(searchInput || "")
  },[searchInput])

  const errorMessage = () => {
    if(["2", "3"].includes(useCase) && coordinates === null){
      return "Please set your location";
    } else if(searchButtonClick === true && [``, null, ""].includes(inputText)){
      return "Please enter a drug name";
    } else if(searchButtonClick === true && inputText.length >= 1){
      return "Drug not found";
    } else if(autocompleteError === true){
      return "We encountered an issue. Please try again."
    } else {
      return undefined;
    }
  }

  useEffect(() => {
    const callAutocomplete = async (searchString, cancel) => {
      if (!!searchString && searchString !== "") {
        try {
          setLoading(true);
          const response = await API.post(
            `/DrugSearchAPI/drugsearch/${
              config?.api_version_configurationConnection?.autocomplete_api_version?.toLowerCase() ||
              "v11"
            }/autocomplete`,
            {
              data: {
                searchStr: searchString,
                transactionId: localTransactionId,
                memberId: memberId,
                sourceId: sourceId,
                gaId: "GA1.3.2029094371.1658794395",
                gaSessionId: "GA1.3.1644885013.1658794395",
              },
              conf: { cancelToken: cancel.token },
            }
          );
          setOptions(response?.data?.data);
          setLoading(false);
          setAutocompleteError(false);
        } catch (error) {
          if (isCancel(error)) {
            // console.log('Request canceled', error);
          } else {
            setLoading(false);
            setAutocompleteError(true);
            console.log(error);
          }
        }
      }
    };

    const cancel = CancelToken.source();
    if (memberId && sourceId && localTransactionId && inputText !== "") {
      callAutocomplete(inputText, cancel);
    } else if (inputText === "") {
      setOptions([]);
      setLoading(false);
    }
    return () => cancel.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputText, memberId, sourceId]);

  useEffect(() => {
    setModifiedRecentSearches(
      recentSearches.map((x) => {
        return {
          name: x?.name,
          brandGeneric: x?.brandGeneric,
          displayName: x.alternateName, //
          navigation_ndc: x.ndc,
          quantity: x?.quantity,
          type: "Recent Searches",
        };
      })
    );
  }, [recentSearches]);

  // const pickDisplay = () => {
  //   if(sbHeight === "3.5"){
  //     return(
  //       "flex"
  //     )
  //   }
  // }


  const autocompleteStyle =
    sbHeight === "2.5"
      ? {
          color: "rgba(80, 72, 71, 1)",
          alignContent: "center",
          border: "1px solid rgba(80, 72, 71, 1)",
          zIndex: "1",
          position: "relative",
          fontSize: "16px",
          background: "rgb(255, 255, 255)",
          textAlign: "left",
          borderRadius: borderRound && !([``, null, ""].includes(inputText) && modifiedRecentSearches?.length ===0) ? "32px 32px 0px 0px" : "32px",
          textTransform: "none",
          "&:active": {
            transition: "0.05s",
            textTransform: "none",
          },
          flex: "auto",
          maxWidth: "80%",
          marginLeft: "15px",
          marginRight: "15px",
        }
      : modalView
      ? {
          color: "rgb(102, 102, 102)",
          alignContent: "center",
          zIndex: "1",
          position: "relative",
          fontSize: "16px",
          background: "rgb(255, 255, 255)",
          textAlign: "left",
          textTransform: "none",
          marginTop: "80px",
          maxWidth: { xs: "100%", sm: "none" },
          "&:active": {
            transition: "0.05s",
            textTransform: "none",
          },
          "& .MuiAutocomplete-popper": {
            borderRadius: 0,
          },
        }
      : {
          color: "rgb(102, 102, 102)",
          alignContent: "center",
          border: "1px solid",
          borderColor: searchButtonClick === true && [``, null, ""].includes(inputText) ? "red": "rgb(102, 102, 102)",
          zIndex: "1",
          position: "relative",
          fontSize: "16px",
          background: "rgb(255, 255, 255)",
          textAlign: "left",
          borderRadius: borderRound && !(([``, null, ""].includes(inputText) && modifiedRecentSearches?.length ===0)) ? "32px 32px 0px 0px" : "32px",
          textTransform: "none",
          "&:active": {
            transition: "0.05s",
            textTransform: "none",
          },
        };

  return (
    <Autocomplete
      className="noBorderAutocomplete"
      sx={autocompleteStyle}
      // open={true}
      autoHighlight={true}
      onOpen={() => setBorderRound(true)}
      onClose={() => setBorderRound(false)}
      options={
        inputText === ""
          ? [...options, ...modifiedRecentSearches]
          : [...options]
      }
      groupBy={(option) => option.type}
      inputValue={[``, null, ""].includes(inputText) ? "" : inputText}
      // defaultValue={inputText}
      noOptionsText={[``, null, ""].includes(inputText) ? null : (loading ? 'Searching...': "No results found")}
      placeholder={`${config?.header_configurationConnection?.search_bar?.search_bar_placeholder_text}`}
      getOptionLabel={(option) =>
        option?.displayName?.includes("|")
          ? option?.displayName?.split("|")[0]?.trim()
          : (option?.name || '')
      }
      onChange={(e, d, r) => {
        // console.log('onChange',d,r);
        setSearchInput && setSearchInput(d?.displayName?.includes("|") ? d?.displayName?.split("|")[0]?.trim() : d?.name);
        localTransactionId && setTransactionId(localTransactionId);
        localTransactionId && sessionStorage.setItem("transactionId", JSON.stringify({transactionId: localTransactionId, date: new Date()}))
        try{
          window?.heap?.track('Search Result Shown', {transactionId: localTransactionId, waltz_memberid: memberId, sourceId, ndc: d?.navigation_ndc, quantity: d?.quantity, drugName: d?.displayName?.includes("|") ? d?.displayName?.split("|")[0]?.trim() : d?.name})
        } catch(error) {
          // console.log('heaperror',error)
        }
        if (!!d?.quantity && !!d?.navigation_ndc) {
          navigate(`/findMedication?id=${d.navigation_ndc}&qty=${d.quantity}`);
        } else if (!!d?.navigation_ndc) {
          navigate(`/findMedication?id=${d.navigation_ndc}`);
        }
        onSelection && onSelection();
        setMenuOpen && setMenuOpen(false);
      }}
      onInputChange={(e, d, r) => {
        // console.log('onInputChange',d,r);
        if(r === 'input') {
          // console.log('onInputChange',d,r);
          !!setSearchButtonClick && setSearchButtonClick(false)
          setInputText(d);          
        }
      }}
      onFocus={()=>setLocalTransactionId(uuid())}
      filterOptions={(x) => x}
      renderGroup={(params, index) => (
        <Grid container key={params.group+index}>
          {params.group && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "1%",
              }}
            >
              <Typography variant="subtitle1" sx={{ marginLeft: "1%" }}>
                {params.group}
              </Typography>
              <Button
                variant="text"
                // color="subtitle"
                onClick={
                  () => setRecentSearches([]) //&& setModifiedRecentSearches([])
                }
                sx={{
                  textTransform: "none",
                  color: "rgb(102, 102, 102)",
                  background: "none",
                  "&:hover": { background: "none" },
                }}
              >
                Clear All
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            {params.children}
          </Grid>
        </Grid>
      )}
      renderOption={(params, option, state) => {
        // console.log(params, option, state)
        return (
          <Grid
            container
            spacing={1}
            {...params}
            key={params.key + option.navigation_ndc + option.displayName}
          >
            <Grid
              item
              xs={12}
              sx={{
                marginBottom: "8px",
                flexDirection: "column",
                alignItems: "normal !important",
                // "&:hover": {
                //   backgroundColor:"rgba(237, 237, 237, 1)",
                //   color:  "rgba(80, 72, 71, 1)",
                // },
              }}
            >
              <Box
                variant="text"
                // onClick={() => {
                //   // if(!!option.quantity) {
                //   //   navigate(`/findMedication?id=${option.navigation_ndc}&qty=${option.quantity}`);
                //   // } else {
                //   //   navigate(`/findMedication?id=${option.navigation_ndc}`);
                //   // }
                //   // onSelection && onSelection();
                //   // setMenuOpen && setMenuOpen(false);
                // }}
                sx={{
                  width: "100%",
                  color: "black",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  maxHeight: "3em",
                  textTransform: "none",
                }}
              >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "left",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    {option?.displayName?.includes("|")
                      ? option.displayName?.split("|")[0]
                      : option?.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "left",
                      fontSize: ".875rem",
                      fontWeight: "400",
                    }}
                  >
                    {option?.displayName?.includes("|")
                      ? option.displayName?.split("|")[1]
                      : option?.displayName}
                  </Typography>
                </Box>
                {savedCoupons.filter(
                  (y) => y.navigation_ndc === option.navigation_ndc
                )?.length > 0 ? (
                  <BookmarkAddedOutlinedIcon />
                ) : (
                  <Fragment></Fragment>
                )}
              </Box>
            </Grid>
          </Grid>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          helperText={errorMessage()}
          placeholder={`${config?.header_configurationConnection?.search_bar?.search_bar_placeholder_text}`}
    //       FormHelperTextProps={{
    //         classes:{
    //             root: {
    //               color: "red"
    //             }
    //         }
    // }}
          sx={{
            height: `${sbHeight}rem`,
            dispay: "flex",
            flexDirection: "column",
            justifyContent: "center",
          display: sbHeight === "3.5" ? "block" : "flex",
          }}
          InputProps={{
            ...params.InputProps,
            'data-testid': 'mainsearchbar',
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : !!inputText && inputText !== "" ? (
                  <CloseIcon
                    onClick={() => {
                      setInputText("");
                      setOptions([]);
                    }}
                    sx={{
                      color:
                        config?.color_configurationConnection?.primary_color
                          ?.hex,
                      cursor: "pointer",
                    }}
                  />
                ) : null}
              </>
            ),
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  zIndex: "1000 !important",
                  color: "#B9B6B6",
                }}
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        ></TextField>
      )}
    />
  );
};

DrugSearch.propTypes = {
  sbHeight: PropTypes.string,

  options: PropTypes.array,

  // inputText: PropTypes.string,
  searchButtonClick : PropTypes.bool,
  setSearchButtonClick : PropTypes.func,
  setMenuOpen : PropTypes.func,
  onSelection : PropTypes.func,
  setInputText: PropTypes.func,
  modalView : PropTypes.bool,
  searchInput : PropTypes.string,
  loading: PropTypes.bool,
  // selectedClientConfig: PropTypes.arrayOf
};


export default DrugSearch;
