import React, { useEffect, useRef } from "react";
import { Button, Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import PropTypes from 'prop-types'


const SuccessModal = ({ setModalOpen, primaryColor, tertiaryColor }) => {

  const buttonRef = useRef();

  useEffect(() => {
    buttonRef.current.focus();
  },[])

  return (
    <Box
      sx={{
        position: "absolute",
        top: "45%", //{ xs: "35%", sm: "30%", md: "40%" },
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: { xs: "60%", sm: "30%", md: "30%", lg: "25%" },
        // height: { xs: "15%", sm: "15%", md: "15%", lg: "15%" },
        // maxHeight: { xs: "350px", sm: "350px", md: "350px", xl: "350px" },
        bgcolor: "background.paper",
        border: "0.5px solid #000",
        borderRadius: "16px",
        boxShadow: 24,
        p: 4,
        padding: { xs: "2%", sm: "2%" },
        display: "flex",
        alignItems: "space-between",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={10}>
          <Typography color="title" sx={{ display: "flex", alignItems: "flex-end", fontSize: "20px", fontWeight: 600 }}>
            Thank you!
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          }}
        >
          {/* <IconButton onClick={() => setModalOpen(false)}>
            <CloseIcon color="title" />
          </IconButton> */}
        </Grid>
        <Grid item xs={12}>
          <Typography color="title" sx={{ fontSize: "16px", fontWeight: 400 }}>
            You're all set!
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{display: "flex", alignItems: "flex-end", justifyContent: "flex-end"}}>
          <Button
            ref={buttonRef}
            disableFocusRipple
            sx={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: primaryColor,
              color: tertiaryColor,
              borderRadius: "24px",
              width: "30%", 
              textTransform: "none",
              "&:hover": {
                color: primaryColor,
                backgroundColor: tertiaryColor,
                border: `1px solid ${primaryColor}`,
              },
              "&:focus": {
                color: primaryColor,
                backgroundColor: tertiaryColor,
                border: `1px solid ${primaryColor}`,
              },
            }}
            onClick={() => setModalOpen(false)}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

SuccessModal.propTypes = {
  setModalOpen : PropTypes.func,
  primaryColor : PropTypes.string,
  tertiaryColor : PropTypes.string 
}

export default SuccessModal;
