import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from "@mui/material/IconButton";

const Dropdown = ({
  label,
  setOption,
  selectedOption,
  availableOptions,
  disabled,
  primaryColor,
  tertiaryColor,
  isCustom,
  setIsCustom,
  scrollWhenOpened
}) => {
  const [roundInput, setRoundInput] = useState(false);
  const [customInput, setCustomInput] = useState("");
  const [customInputValidated, setCustomInputValidated] = useState(true);
  const [acceptCustomInput, setAcceptCustomInput] = useState(false);
  const [closeOnCustom, setCloseOnCustom] = useState(1);

  const selectRef = useRef();
  const scrollRef = useRef();

  useEffect(() => {
    //add validation here for number sizes - no decimals, no gigantic numbers
    if (customInput !== "") {
      if (!/^\d+$/.test(customInput)) {
        setCustomInputValidated(false);
      } else {
        if (Number(customInput) >= 1.0 && Number(customInput) <= 10000.0) {
          setCustomInputValidated(true);
        } else {
          setCustomInputValidated(false);
        }
      }
    }
  }, [customInput]);

  useEffect(() => {
    //add validation here for number sizes - no decimals, no gigantic numbers
    if (customInput !== "" && acceptCustomInput && customInputValidated) {
      setOption(customInput);
      setAcceptCustomInput(false);
      
      setCustomInput("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customInput, acceptCustomInput, customInputValidated]);

  const handleEnter = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        if (customInput !== "" && customInputValidated) {
          setAcceptCustomInput(true);
          setRoundInput(false);
        }
      }
    },
    [customInput, customInputValidated]
  );

  return (
    <Box
      sx={{
        color: "rgb(75, 75, 75)",
        textAlign: "left",
        marginBottom: "12px",
        // maxWidth: "500px",
      }}
      // key={label === "Quantity" ? selectedOption : label}
    >
      <FormControl fullWidth size="small">
        <InputLabel
          ref={scrollRef}
          sx={{
            color: "rgb(75, 75, 75) !important",
            position: "relative",
            fontSize: "1rem",
            transform: "none",
            fontWeight: "600",
            marginBottom: "0.25rem",
          }}
        >
          {label}
        </InputLabel>
        <Select
          ref={selectRef}
          // inputRef={selectRef}
          // open={true}
          // autoWidth={true}
          key={label === "Quantity" ? selectedOption + closeOnCustom : label}
          IconComponent={KeyboardArrowDownIcon}
          color="body"
          sx={{
            borderColor: "rgb(102, 102, 102)",
            borderRadius: roundInput ? "24px 24px 0px 0px" : "24px",
            fontSize: "14px",
            backgroundColor: "#FFFFFF",
            '.MuiSvgIcon-root ': {
              display: availableOptions?.length < 2 || disabled ? 'none' : undefined
            }
          }}
          MenuProps={{
            PaperProps: { sx: { maxHeight: {xs:210, md: 345}, color: "#757593" , width: selectRef?.current?.style?.width} },
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "#EDEDED",
              },
              "&& .Mui-selected:hover": {
                backgroundColor: "#EDEDED",
              },
              // maxWidth:'100%'
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            // getContentAnchorEl: null
          }}
          onOpen={() => {
            setRoundInput(true); 
            scrollWhenOpened && scrollRef?.current?.scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
          }}
          onClose={() => setRoundInput(false)}
          onChange={(e) => setOption(e?.target?.value)}
          value={selectedOption}
          renderValue={() => {
            return(
            isCustom === true ? "Custom" : selectedOption
            )
          }
          }
          disabled={(availableOptions?.length < 2 && label !== "Quantity") || disabled}
          onKeyDownCapture={(e, d) => {
            label === "Quantity" &&
              ![37, 38, 39, 40, 13].includes(e.keyCode) &&
              e.stopPropagation();
          }}
          // variant="outlined"
          // iconOutlined
        >
          {availableOptions?.map((x, index) => {
            return (
              <MenuItem value={x.value} key={x.key} sx={{whiteSpace:'pre-wrap'}}>
                {x.text}
              </MenuItem>
            );
          })}
          {label === "Quantity" && (
            <Box>
              <Box
                // component="form"
                sx={{
                  display: { xs: "none", md: "flex" },
                  flexDirection: "column",
                  justifyContent: "space-between",
                  borderTop: "1px solid #B9B6B6",
                }}
              >
                <MenuItem
                  key="customQuantityDesktop"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                  value={customInput}
                >
                  Custom
                  <TextField
                    placeholder="Enter Quantity"
                    variant="standard"
                    // type="search"
                    color="body"
                    value={customInput}
                    sx={{
                      maxWidth: "7rem",
                      mb: "5%",
                      "&:focus": {
                        backgroundColor: "#FFFFFF",
                      },
                      color: "#757593",
                      // "&:after": {
                      //   borderColor: "purple!important"
                      // }
                    }}
                    onKeyDown={handleEnter}
                    onChange={(e) => setCustomInput(e?.target?.value)}
                    InputProps={{
                      endAdornment: (
                        <IconButton size="small" onClick={() => setCustomInput("")} sx={{background: "none", "&:hover": { background: "none"}}}>
                         {customInput > 0 ? <ClearIcon size="small" sx={{background: "none", height: "1rem", width: "1rem"}}/> : ''}
                        </IconButton>
                      )
                    }}
                    error={!customInputValidated}
                    helperText={
                      !customInputValidated && customInput?.length > 0
                        ? "Enter a valid quantity"
                        : undefined
                    }
                  />
                  <Button
                    onClick={() => {
                      setAcceptCustomInput(true);
                      setRoundInput(false);
                    }}
                    sx={{
                      display: "flex",
                      borderRadius: "30px",
                      justifyContent: { xs: "center", md: "center" },
                      alignSelf: "center",
                      backgroundColor: primaryColor,
                      color: tertiaryColor,
                      width: "75%",
                      "&:hover": {
                        backgroundColor: tertiaryColor,
                        color: primaryColor,
                        border: `1px solid ${primaryColor}`,
                      },
                      "&.Mui-disabled": {
                        color: "#fff",
                      },
                    }}
                    disabled={customInput === "" || !customInputValidated}
                  >
                    Update
                  </Button>
                </MenuItem>
              </Box>
              <Box
                // component="form"
                sx={{
                  display: { xs: "flex", md: "none" },
                  flexDirection: "column",
                  justifyContent: "space-between",
                  borderTop: "1px solid #B9B6B6",
                }}
              >
                <MenuItem
                  key="customQuantityMobile"
                  color="body"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                  value={customInput}
                  onClick={() => {
                    setIsCustom(true);
                    setCloseOnCustom((x) => x + 1);
                    setRoundInput(false);
                  }}
                >
                  {/* <Button
                    variant="text"
                
                    sx={{ textTransform: "none" }}
                  > */}
                    Custom
                  {/* </Button> */}
                </MenuItem>
              </Box>
            </Box>
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  setOption: PropTypes.func,
  selectedOption: PropTypes.string,
  availableOptions: PropTypes.array,
  disabled : PropTypes.bool,
  primaryColor : PropTypes.string,
  tertiaryColor : PropTypes.string,
  isCustom : PropTypes.bool,
  setIsCustom : PropTypes.func,
  scrollWhenOpened: PropTypes.bool
};

Dropdown.defaultProps = {
  label: "Quantity",
  setOption: () => {},
  availableOptions: [
    { value: "10", key: "10", text: "10" },
    { value: "15", key: "15", text: "15" },
    { value: "30", key: "30", text: "30" },
    { value: "60", key: "60", text: "60" },
  ],
};
export default Dropdown;
