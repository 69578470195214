import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SurveyModal from "../../Components/SurveyModal/SurveyModal";
import PropTypes from 'prop-types'

  
  const SurveyButton = ({setModal, setModalOpen, config}) => {
   return(
    <Box
    // item
    // xs={12}
    sx={{ padding: "8px !important", zIndex: "20", justifyContent: "flex-end" }}
  >
    <Button
      variant="contained"
      sx={{
        zIndex: 99,
        bottom: "5%",
        right: "5%",
        width: "8rem",
        backgroundColor:
          config?.color_configurationConnection?.primary_color?.hex,
        color:
          config?.color_configurationConnection?.secondary_color
            ?.hex, 
            position: "fixed",
        textTransform: "none",
        border: `1px solid ${config?.color_configurationConnection?.secondary_color
          ?.hex}`,
          borderRadius: "25px",
        "&:hover": {
          backgroundColor: config?.color_configurationConnection?.secondary_color
          ?.hex,
        color: config?.color_configurationConnection?.primary_color?.hex,
        border: `1px solid ${config?.color_configurationConnection?.primary_color?.hex}`,
        }
      }}
      onClick={() =>
        setModal(
          <SurveyModal
            setModalOpen={setModalOpen}
            primaryColor={
              config?.color_configurationConnection?.primary_color
                ?.hex
            }
            tertiaryColor={
              config?.color_configurationConnection?.secondary_color
                ?.hex
            }
            surveyUrl={config?.nps_survey_url}
          />
        )
      }
    >
      Got a sec?
    </Button>
  </Box>
   )
  }

  SurveyButton.propTypes = {
    setModal : PropTypes.func,
    setModalOpen : PropTypes.func, 
    config : PropTypes.object,
  }
  export default SurveyButton;