import React, { Fragment, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import PropTypes from "prop-types";

const RetailCoupon = ({
  claimPrice,
  discountCardName,
  index,
  open,
  openThisCard,
  lowestPrice,
  retailBodyText,
  retailCardDisclaimer,
  closeThisCard,
}) => {
  const scrollRef = useRef();

  // const updateSize = () => {
  //   if (index === 0) {
  //     return "5rem";
  //   } else {
  //     return "4rem";
  //   }
  // };

  return (
    <Fragment>
      {open === false ? (
        <Box
          ref={scrollRef}
          onClick={() => {
            openThisCard();
            scrollRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
          }}
          sx={{
            color: "rgba(255, 255, 255, 1)",
            fontSize: "12px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
            display: "flex",
            //   justifyContent: "center",
            alignItems: "center",
            padding: { xs: "16px 24px 16px 24px", md: "16px 24px 32px 24px" },
            borderRadius: "15px",
            // height: updateSize,
            maxWidth: "100%",
            backgroundColor: "rgba(255, 255, 255, 1)",
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              sm={7}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                color="#504847"
                sx={{ fontWeight: 600, fontSize: { xs: "16px", md: "20px" } }}
              >
                {discountCardName}
              </Typography>
              {index === 0 ? (
                //Checking if it is the cheapest price
                <Box
                  sx={{
                    // width: { xs: "90px", md: "103px" },
                    maxHeight: {xs: "22px", md: "25px"},
                    backgroundColor: "rgba(3, 167, 127, 0.2)",
                    borderRadius: "22px",
                    display: "flex",
                    justifyContent: "center",
                    padding: "2px 8px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "rgba(2, 100, 58, 1)",
                      fontSize: { xs: "12px", md: "14px" },
                    }}
                  >
                    Lowest Price
                  </Typography>
                </Box>
              ) : (
                <Fragment></Fragment>
              )}
            </Grid>
            <Grid
              item
              xs={6}
              sm={5}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="text"
                sx={{
                  color: "rgba(80, 72, 71, 1)",
                  background: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  "&:hover": {
                    color: "rgba(80, 72, 71, 1)",
                    background: "none",
                  },
                  "&:active": {
                    color: "rgba(80, 72, 71, 1)",
                    background: "none",
                  },
                  "&:focus": {
                    color: "rgba(80, 72, 71, 1)",
                    background: "none",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "flex-end",
                  }}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      fontSize: { xs: "12px", md: "16px" },
                      fontWeight: "600",
                      color: lowestPrice,
                    }}
                  >
                    RETAIL PRICE
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "20px", md: "24px" },
                      fontWeight: "700",
                      display: "flex",
                      justifyContent: "flex-end",
                      color: lowestPrice,
                    }}
                  >
                    $
                    {claimPrice !== null && !isNaN(Number(claimPrice))
                      ? claimPrice.toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : claimPrice}
                  </Typography>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                  <KeyboardArrowRightOutlinedIcon />
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            marginBottom: "16px !important",
          }}
          className="printView"
        >
          <Box
            sx={{
              color: "rgba(255, 255, 255, 1)",
              boxShadow: "1px 2px 4px 1px rgba(0, 0, 0, 0.25)",
              display: "flex",
              justifyContent: "center",
              alignItems: "space-between",
              padding: { xs: "16px 24px 16px 24px", md: "16px 24px 32px 24px" },
              borderRadius: "15px",
              backgroundColor: "rgba(255, 255, 255, 1)",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={5}
                sm={7}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <Typography
                  color="#504847"
                  sx={{ fontWeight: 600, fontSize: { xs: "16px", md: "20px" } }}
                >
                  {discountCardName}
                </Typography>
                {index === 0 ? (
                  //Checking if it is the cheapest price
                  <Box
                    sx={{
                      // width: { xs: "90px", md: "103px" },
                      maxHeight: {xs: "22px", md: "25px"},
                      backgroundColor: "rgba(3, 167, 127, 0.2)",
                      borderRadius: "22px",
                      display: "flex",
                      justifyContent: "center",
                      padding: "2px 8px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color: "rgba(2, 100, 58, 1)",
                        fontSize: { xs: "12px", md: "14px" },
                      }}
                    >
                      Lowest Price
                    </Typography>
                  </Box>
                ) : (
                  <Fragment></Fragment>
                )}
              </Grid>
              <Grid
                item
                xs={7}
                sm={5}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignSelf: "flex-start",
                  //   flexDirection: "column"
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color: "rgba(80, 72, 71, 1)",
                    background: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",

                    marginBottom: "16px",
                    "&:hover": {
                      color: "rgba(80, 72, 71, 1)",
                      background: "none",
                    },
                    "&:active": {
                      color: "rgba(80, 72, 71, 1)",
                      background: "none",
                    },
                    "&:focus": {
                      color: "rgba(80, 72, 71, 1)",
                      background: "none",
                    },
                  }}
                  onClick={closeThisCard}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        fontSize: { xs: "12px", md: "16px" },
                        fontWeight: "600",
                        color: lowestPrice,
                      }}
                    >
                      RETAIL PRICE
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "20px", md: "24px" },
                        fontWeight: "700",
                        display: "flex",
                        justifyContent: "flex-end",
                        color: lowestPrice,
                      }}
                    >
                      $
                      {claimPrice !== null && !isNaN(Number(claimPrice))
                        ? claimPrice.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : claimPrice}
                    </Typography>
                  </Box>
                  <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                    <KeyboardArrowRightOutlinedIcon />
                  </Box>
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  borderBottom: "1px solid #B9B6B6",
                  //   margin: "0px 2%",
                  maxWidth: "100%",
                  display: "flex",
                  justifyContent: "center",
                  maxHeight: "1px",
                }}
              ></Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "flex-start",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: "32px 16px 16px 32px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "14px", md: "16px" },
                    color: "#504847",
                    fontWeight: 500,
                    marginBottom: "32px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: retailBodyText,
                  }}
                ></Typography>

                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "14px", md: "16px" },
                    color: "#757593",
                    fontWeight: 500,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: retailCardDisclaimer,
                  }}
                ></Typography>
              </Grid>
              {/* <Grid
                item
                xs={0.2}
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignContent: "center",
                  borderLeft: "1px solid #B9B6B6",
                  maxHeight: "100%",
                }}
              ></Grid>
              <Grid
                item
                xs={4.8}
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "10px", md: "12px" },
                    marginBottom: "16px",
                    color: "#757593",
                    fontWeight: 400,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: savingsClubDisclaimer,
                  }}
                ></Typography>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={0}
                    md={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Box
                      sx={{
                        backgroundImage: `url("${imgLink}")`,
                        display: "flex",
                        minWidth: { xs: "0", md: "50%", lg: "50%" },
                        // width: { xs: "80%", sm: "40%", md: "35%", lg: "30%" },
                        // maxWidth: "100%",
                        minHeight: "3rem",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        position: "relative",
                        flexDirection: "row",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                      }}
                      alt={imgAltText}
                    />
                  </Grid>
                </Grid>
              </Grid> */}
              {/* <Grid container spacing={1}> */}
              <Grid
                item
                xs={12}
                sx={{
                  display: { xs: "flex", md: "none" },
                  padding: "16px 0px 16px 0px",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "14px", md: "16px" },
                    marginBottom: "16px",
                    color: "#504847",
                    fontWeight: 500,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: retailBodyText,
                  }}
                ></Typography>

                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { xs: "10px", md: "12px" },
                    //   marginBottom: "16px",
                    color: "#757593",
                    fontWeight: 400,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: retailCardDisclaimer,
                  }}
                ></Typography>
              </Grid>
              {/* </Grid> */}
            </Grid>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};

RetailCoupon.propTypes = {
  claimPrice : PropTypes.number,
  discountCardName : PropTypes.string,
  index : PropTypes.number,
  open : PropTypes.bool,
  openThisCard : PropTypes.func,
  lowestPrice : PropTypes.func,
  retailBodyText : PropTypes.string,
  retailCardDisclaimer : PropTypes.string,
  closeThisCard : PropTypes.func
}

export default RetailCoupon;
