import React, { useContext, useState, useEffect, Fragment } from "react";
import AppContext from "../../AppContext";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CouponCard from "../../Components/CouponCard/CouponCard";
import SavedCouponUse23 from "../../Components/SavedCouponUse23/SavedCouponUse23";
import Container from "@mui/material/Container";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderRounded";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import API from "../../Services/API";
import Skeleton from "@mui/material/Skeleton";
import SurveyButton from "../../Components/SurveyButton/SurveyButton";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";

const MySavings = () => {
  const {
    savedCoupons,
    setSavedCoupons,
    transactionId,
    memberId,
    ncpdpId,
    sourceId,
    clientId,
    config,
    setModal,
    setModalOpen,
    useCase
  } = useContext(AppContext);

  const CouponComponent = ["2", "3"].includes(useCase) ? SavedCouponUse23 : CouponCard;
  const [freshCoupons, setFreshCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState({});
  const [sortOrder, setSortOrder] = useState("recent");

  const [savedCouponsFailed, setSavedCouponsFailed] = useState(false);

  const formattedDate = new Date().toJSON().slice(0, 10);

  // const primaryColor = config?.color_configurationConnection?.primary_color?.hex;
  // const tertiaryColor = config?.color_configurationConnection?.tertiary_color?.hex;
  useEffect(() => {
    const getUpdatedPrices = async () => {
      setLoading(true);
      const responses = await Promise.all(
        savedCoupons.map(async (x) => {
          try {
            const freshPrice = await API.post(
              `/PricingAPI/pricing/${
                config?.api_version_configurationConnection?.multisavings_api_version?.toLowerCase() ||
                "v1"
              }/multisaving`,
              {
                data: {
                  brand_generic: x.brand_generic,
                  brand_name: x.brand_name === "" ? "N/A" : x.brand_name,
                  brand_name_code: x.brand_name_code,
                  dosage: x.dosage,
                  drug_name: x.drug_name,
                  form: x.form,
                  gaId: "GA1.3.1589059411.1673969703",
                  gaSessionId: "GA1.3.1112055632.1659047944",
                  generic_name: x.generic_name,
                  gpi14: x.gpi14,
                  memberId: memberId,
                  mscode: x.mscode,
                  ncpdpid: x.ncpdp || ncpdpId,
                  ndc: x.ndc,
                  packageSize: x.packageSize || null,
                  quantity: x.quantity,
                  resp_count: "20",
                  sourceId: sourceId,
                  transactionId: transactionId,
                },
              }
            );

            setSavedCouponsFailed(false);
            return x.timeSaved === "" || !x.timeSaved
              ? { ...x, ...freshPrice?.data?.data, timeSaved: "01/02/2022" }
              : { ...x, ...freshPrice?.data?.data };
          } catch (error) {
            console.log(error);
            setSavedCouponsFailed(true);
            return {};
          }
        })
      );
      setLoading(false);
      setFreshCoupons(responses);
    };
    if (
      savedCoupons?.length > 0 &&
      transactionId &&
      sourceId &&
      memberId
      // &&
      // ncpdpId
    ) {
      getUpdatedPrices();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId, memberId, ncpdpId]);

  const deleteCoupon = (navigation_ndc, quantity, array) => {
    setFreshCoupons(
      array.filter(
        (item) =>
          !(
            item.navigation_ndc === navigation_ndc && item.quantity === quantity
          )
      )
    );
    setSavedCoupons(
      array.filter(
        (item) =>
          !(
            item.navigation_ndc === navigation_ndc && item.quantity === quantity
          )
      )
    );
  };

  const trackCoupon = (data = {}) => {
    try {
      API.post(
        `PricingAPI/coupon/${
          config?.api_version_configurationConnection?.coupon_api_version?.toLowerCase() ||
          "v3"
        }`,
        { data: data }
      );
      if (!!window?.heap) {
        try {
          window?.heap?.track("Coupon Clicked (MySavings)", data);
        } catch (error) {
          // console.log('heaperror',error)
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleChange = (event) => {
    setSortOrder(event.target.value);
    handleCloseUserMenu();
    console.log("Sort Order: ", event.target.value);
  };

  // const sortCoupons = () => {
  //   if (sortOrder === "alphabetically") {
  //     return (a, b) => (a.drug_name > b.drug_name ? 1 : -1);
  //   } else if (sortOrder === "old") {
  //     return (a, b) => b.navigation_ndc - a.navigation_ndc;
  //   } else {
  //     return (a, b) => a.timeSaved - b.timeSaved;
  //   }
  // };

  useEffect(() => {
    document.title = "My Savings";
  }, []);

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: "white",
          borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ marginTop: { xs: "10%", sm: "5%", lg: "3%" } }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: { xs: "24px", md: "32px" },
                  fontWeight: 600,
                  justifyContent: "space-between",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <BookmarkBorderOutlinedIcon
                  color="primary"
                  sx={{ fontSize: { xs: "24px", md: "32px" }, fontWeight: 600 }}
                />
                My Savings
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: "3%" }}>
              <Typography
                sx={{ fontWeight: 400, fontSize: { xs: "14px", md: "16px" } }}
              >
                Save commonly searched prescription drug coupons here for easy
                access. Refresh between uses to see the lowest priced coupon
                available.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "rgb(246, 246, 246)" }}>
        <Container maxWidth="lg">
          {savedCouponsFailed === true ? (
            <Box
              sx={{
                width: "100%",
                height: { xs: "600px", md: "680px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <Typography
                variant="title"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 400,
                  fontSize: { xs: "14px", md: "16px" },
                  padding: "16px",
                }}
              >
                We encountered an error. Try refreshing the page or try again
                later.
              </Typography>
              <Button
                component={RouterLink}
                to="/"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: { xs: "4px 16px", md: "10px 24px" },
                  borderRadius: "24px",
                  width: { xs: "98px", md: "142px" },
                  height: { xs: "29px", md: "48px" },
                  textTransform: "none",
                  backgroundColor:
                    config?.color_configurationConnection?.primary_color?.hex,
                  color:
                    config?.color_configurationConnection?.secondary_color?.hex,
                  border: `1px solid ${config?.color_configurationConnection?.primary_color?.hex}`,
                  "&:hover": {
                    color:
                      config?.color_configurationConnection?.primary_color?.hex,
                    backgroundColor:
                      config?.color_configurationConnection?.secondary_color
                        ?.hex,
                  },
                  "&:active": {
                    color:
                      config?.color_configurationConnection?.primary_color?.hex,
                    backgroundColor:
                      config?.color_configurationConnection?.secondary_color
                        ?.hex,
                  },
                  "&:focus": {
                    color:
                      config?.color_configurationConnection?.primary_color?.hex,
                    backgroundColor:
                      config?.color_configurationConnection?.secondary_color
                        ?.hex,
                  },
                }}
              >
                Go Home
              </Button>
            </Box>
          ) : (
            <Fragment>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    onClick={handleOpenUserMenu}
                    sx={{ background: "none", textTransform: "none" }}
                  >
                    <FilterListRoundedIcon />
                  </IconButton>
                  <Menu
                    sx={{ mt: "45px" }}
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <RadioGroup
                      aria-labelledby="select-saved-coupons-sort"
                      name="saved-coupons-sort"
                      value={sortOrder}
                      onChange={(e) => handleChange(e)}
                      sx={{
                        padding: "8px",
                        borderColor: "#B9B6B6",
                        "&:hover": {
                          borderColor: "#292933",
                        },
                        "&:focus": {
                          borderColor: "#292933",
                          color: "#292933",
                        },
                        "&:active": {
                          borderColor: "#292933",
                          color: "#292933",
                        },
                      }}
                    >
                      <FormControlLabel
                        value="alphabetically"
                        control={
                          <Radio
                            sx={{
                              borderColor: "#B9B6B6",
                              "&:hover": {
                                borderColor: "#292933",
                              },
                              "&:focus": {
                                borderColor: "#292933",
                                color: "#292933",
                              },
                              "&:active": {
                                borderColor: "#292933",
                                color: "#292933",
                              },
                            }}
                          />
                        }
                        label="Alphabetically (A - Z)"
                        color="#292933"
                      />
                      <FormControlLabel
                        value="recent"
                        control={
                          <Radio
                            sx={{
                              borderColor: "#B9B6B6",
                              "&:hover": {
                                borderColor: "#292933",
                              },
                              "&:focus": {
                                borderColor: "#292933",
                                color: "#292933",
                              },
                              "&:active": {
                                borderColor: "#292933",
                                color: "#292933",
                              },
                            }}
                          />
                        }
                        label="Recent - Old"
                        color="#292933"
                      />
                      <FormControlLabel
                        value="old"
                        control={
                          <Radio
                            sx={{
                              borderColor: "#B9B6B6",
                              "&:hover": {
                                borderColor: "#292933",
                              },
                              "&:focus": {
                                borderColor: "#292933",
                                color: "#292933",
                              },
                              "&:active": {
                                borderColor: "#292933",
                                color: "#292933",
                              },
                            }}
                          />
                        }
                        label="Old - Recent"
                        color="#292933"
                      />
                    </RadioGroup>
                  </Menu>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexFlow: "column",
                  }}
                >
                  <Grid container spacing={1}>
                    {loading ? (
                      [1, 2].map((x) => {
                        return (
                          <Grid item xs={12} md={6} key={x}>
                            <Box
                              sx={{
                                color: "rgba(255, 255, 255, 1)",
                                fontSize: "12px",
                                boxShadow:
                                  "1px 2px 4px 1px rgba(0, 0, 0, 0.25)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "space-between",
                                padding: "24px 24px 16px 24px",
                                borderRadius: "15px",
                                height: { xs: "8rem" },
                              }}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xs={8}
                                  sm={9}
                                  md={9}
                                  lg={10}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Skeleton
                                      animation="pulse"
                                      sx={{
                                        color: "rgba(255, 255, 255, 1)",
                                        fontSize: "12px",
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: { xs: "3%", sm: "1%" },
                                        borderRadius: "5px",
                                        width: {
                                          xs: "12rem",
                                          sm: "20rem",
                                          // md: "30rem",
                                        },
                                        height: { xs: "15px", sm: "20px" },
                                        backgroundColor: "grey",
                                        transition: "2s",
                                        transformOrigin: "0",
                                        transform: "none",
                                      }}
                                    ></Skeleton>
                                    <Skeleton
                                      animation="pulse"
                                      sx={{
                                        marginTop: "10px",
                                        color: "rgba(255, 255, 255, 1)",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "space-between",
                                        padding: { xs: "3%", sm: "1%" },
                                        borderRadius: "5px",
                                        width: {
                                          xs: "10rem",
                                          sm: "18rem",
                                          // md: "25rem",
                                        },
                                        height: { xs: "10px", sm: "15px" },
                                        backgroundColor: "grey",
                                        transition: "2s",
                                        transformOrigin: "0",
                                        transform: "none",
                                      }}
                                    ></Skeleton>
                                    <Skeleton
                                      animation="pulse"
                                      sx={{
                                        marginTop: {
                                          xs: "20px",
                                          sm: "30px",
                                          // md: "20px",
                                        },
                                        color: "rgba(255, 255, 255, 1)",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "space-between",
                                        padding: { xs: "3%", sm: "1%" },
                                        borderRadius: "5px",
                                        width: {
                                          xs: "7rem",
                                          sm: "16rem",
                                          lg: "20rem",
                                          // lg: "50rem",
                                        },
                                        height: { xs: "10px", sm: "15px" },
                                        backgroundColor: "grey",
                                        transition: "2s",
                                        transformOrigin: "0",
                                        transform: "none",
                                      }}
                                    ></Skeleton>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={4}
                                  sm={3}
                                  md={3}
                                  lg={2}
                                  sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Skeleton
                                    animation="pulse"
                                    sx={{
                                      color: "rgba(255, 255, 255, 1)",
                                      fontSize: "12px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "space-between",
                                      padding: { xs: "3%", sm: "1%" },
                                      borderRadius: "5px",
                                      width: {
                                        xs: "5rem",
                                        sm: "10rem",
                                        md: "100%",
                                        // lg: "15rem",
                                      },
                                      height: { xs: "37px", sm: "46px" },
                                      backgroundColor: "grey",
                                      transition: "2s",
                                      transformOrigin: "0",
                                      transform: "none",
                                    }}
                                  ></Skeleton>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        );
                      })
                    ) : !loading && freshCoupons?.length > 0 ? (
                      <Fragment>
                        <Grid
                          item
                          xs={0}
                          md={6}
                          sx={{
                            display: { xs: "none", md: "flex" },
                            flexDirection: "column",
                          }}
                        >
                          {(sortOrder === "old"
                            ? freshCoupons
                                ?.sort((a, b) =>
                                  a.quantity > b.quantity ? 1 : -1
                                )
                                ?.sort((a, b) =>
                                  a.drug_name > b.drug_name ? 1 : -1
                                )
                                ?.sort((a, b) =>
                                  a.timeSaved > b.timeSaved ? 1 : -1
                                )
                            : sortOrder === "alphabetically"
                            ? freshCoupons
                                ?.sort((a, b) =>
                                  a.quantity > b.quantity ? 1 : -1
                                )
                                ?.sort((a, b) =>
                                  a.drug_name > b.drug_name ? 1 : -1
                                )
                            : sortOrder === "recent"
                            ? freshCoupons
                                .sort((a, b) =>
                                  a.quantity > b.quantity ? 1 : -1
                                )
                                ?.sort((a, b) =>
                                  a.drug_name > b.drug_name ? 1 : -1
                                )
                                ?.sort((a, b) =>
                                  a.timeSaved > b.timeSaved ? -1 : 1
                                )
                            : freshCoupons
                                .sort((a, b) =>
                                  a.quantity > b.quantity ? 1 : -1
                                )
                                ?.sort((a, b) =>
                                  a.drug_name > b.drug_name ? 1 : -1
                                )
                                ?.sort((a, b) =>
                                  a.timeSaved > b.timeSaved ? -1 : 1
                                )
                          )?.map((coupon, index) => {
                            return (
                              index % 2 === 0 && (
                                <CouponComponent
                                  key={index}
                                  claimPrice={coupon?.claim_price}
                                  discountCardName={coupon?.discount_card_name}
                                  memberId={coupon?.memberId}
                                  bin={coupon?.bin}
                                  pcn={coupon?.pcn}
                                  group={coupon?.group}
                                  navigationNdc={coupon?.navigation_ndc}
                                  name={coupon?.drug_name}
                                  genericName={coupon?.generic_name}
                                  brandName={coupon?.brand_name}
                                  quantityAndDose={`${coupon?.quantity} ${coupon?.form} | ${coupon?.dosage}`}
                                  pharmacyDisplayName={
                                    coupon?.pharmacy_display_name
                                  }
                                  form={coupon?.form}
                                  dosage={coupon?.dosage}
                                  quantity={coupon?.quantity}
                                  brandGeneric={coupon?.brand_generic}
                                  deleteCoupon={deleteCoupon}
                                  savedCoupons={freshCoupons}
                                  open={
                                    (coupon?.navigation_ndc || coupon?.ndc) ===
                                      open?.navigation_ndc &&
                                    coupon?.quantity === open?.quantity
                                      ? true
                                      : false
                                  }
                                  setModal={setModal}
                                  setModalOpen={setModalOpen}
                                  openThisCard={() => {
                                    setOpen({
                                      navigation_ndc:
                                        coupon?.navigation_ndc || coupon?.ndc,
                                      quantity: coupon?.quantity,
                                      isOpen: true,
                                    });
                                    trackCoupon({
                                      pharmacyId: "",
                                      pharmacyName:
                                        coupon?.pharmacy_display_name,
                                      drugName: coupon?.drug_name,
                                      ndc: coupon?.ndc,
                                      gpi14: coupon?.gpi14,
                                      genericName: coupon?.generic_name,
                                      brandName: coupon?.brand_name,
                                      ncpdpId: coupon?.ncpdp || ncpdpId,
                                      mscode: coupon?.mscode,
                                      bin: coupon?.bin,
                                      pcn: coupon?.pcn,
                                      group: coupon?.group,
                                      type: coupon?.form,
                                      strength: coupon?.dosage,
                                      quantity: coupon?.quantity,
                                      network_type: coupon?.network_type,
                                      discount_card_name:
                                        coupon?.discount_card_name,
                                      currency: "USD",
                                      amount: `${coupon?.claim_price}`,
                                      transactionId: transactionId,
                                      memberId: coupon.memberId,
                                      sourceId: sourceId,
                                      createdOn: formattedDate,
                                      clientId: clientId,
                                      packageSize: coupon?.packageSize || "",
                                      rowNumber: index + 1,
                                      waltz_memberid: memberId,
                                    });
                                  }}                                  
                                  closeThisCard={() => {
                                    setOpen({
                                    navigation_ndc: 0,
                                    isOpen: false
                                  })
                                  }}
                                  primaryColor={
                                    config?.color_configurationConnection
                                      ?.primary_color?.hex
                                  }
                                  tertiaryColor={
                                    config?.color_configurationConnection
                                      ?.secondary_color?.hex
                                  }
                                  couponDisclaimer={
                                    config?.coupon_configurationConnection
                                      ?.coupon_card_disclaimer
                                  }
                                  networkType={coupon?.network_type}
                                  couponInstructions={
                                    config?.coupon_configurationConnection
                                      ?.coupon_header
                                  }
                                  cardEmail={coupon.cashCardEmailId}
                                  cardPhoneNumber={coupon.cashCardPhoneNumber}
                                  termsAndConditionsURL={
                                    coupon.cashCardTermsAndConditionsURL
                                  }
                                  smsDisclaimer={
                                    config?.coupon_configurationConnection?.sms?.disclaimer
                                  }
                                  emailDisclaimer={
                                    config?.coupon_configurationConnection?.email?.disclaimer
                                  }
                                  shareModalApi={config?.api_version_configurationConnection?.sms_api_version}
                                  isOpen={open?.isOpen}
                                />
                              )
                            );
                          })}
                        </Grid>

                        <Grid
                          item
                          xs={0}
                          md={6}
                          sx={{
                            display: { xs: "none", md: "flex" },
                            flexDirection: "column",
                          }}
                        >
                          {(sortOrder === "old"
                            ? freshCoupons
                                ?.sort((a, b) =>
                                  a.quantity > b.quantity ? 1 : -1
                                )
                                ?.sort((a, b) =>
                                  a.drug_name > b.drug_name ? 1 : -1
                                )
                                ?.sort((a, b) =>
                                  a.timeSaved > b.timeSaved ? 1 : -1
                                )
                            : sortOrder === "alphabetically"
                            ? freshCoupons
                                ?.sort((a, b) =>
                                  a.quantity > b.quantity ? 1 : -1
                                )
                                ?.sort((a, b) =>
                                  a.drug_name > b.drug_name ? 1 : -1
                                )
                            : sortOrder === "recent"
                            ? freshCoupons
                                .sort((a, b) =>
                                  a.quantity > b.quantity ? 1 : -1
                                )
                                ?.sort((a, b) =>
                                  a.drug_name > b.drug_name ? 1 : -1
                                )
                                ?.sort((a, b) =>
                                  a.timeSaved > b.timeSaved ? -1 : 1
                                )
                            : freshCoupons
                                .sort((a, b) =>
                                  a.quantity > b.quantity ? 1 : -1
                                )
                                ?.sort((a, b) =>
                                  a.drug_name > b.drug_name ? 1 : -1
                                )
                                ?.sort((a, b) =>
                                  a.timeSaved > b.timeSaved ? -1 : 1
                                )
                          )?.map((coupon, index) => {
                            return (
                              index % 2 !== 0 && (
                                <CouponComponent
                                  key={index}
                                  claimPrice={coupon?.claim_price}
                                  discountCardName={coupon?.discount_card_name}
                                  memberId={coupon?.memberId}
                                  bin={coupon?.bin}
                                  pcn={coupon?.pcn}
                                  group={coupon?.group}
                                  navigationNdc={coupon?.navigation_ndc}
                                  name={coupon?.drug_name}
                                  genericName={coupon?.generic_name}
                                  brandName={coupon?.brand_name}
                                  quantityAndDose={`${coupon?.quantity} ${coupon?.form} | ${coupon?.dosage}`}
                                  pharmacyDisplayName={
                                    coupon?.pharmacy_display_name
                                  }
                                  form={coupon?.form}
                                  dosage={coupon?.dosage}
                                  quantity={coupon?.quantity}
                                  brandGeneric={coupon?.brand_generic}
                                  deleteCoupon={deleteCoupon}
                                  savedCoupons={freshCoupons}
                                  open={
                                    (coupon?.navigation_ndc || coupon?.ndc) ===
                                      open?.navigation_ndc &&
                                    coupon?.quantity === open?.quantity
                                      ? true
                                      : false
                                  }
                                  setModal={setModal}
                                  setModalOpen={setModalOpen}
                                  openThisCard={() => {
                                    setOpen({
                                      navigation_ndc:
                                        coupon?.navigation_ndc || coupon?.ndc,
                                      quantity: coupon?.quantity,
                                      isOpen: true,
                                    });
                                    trackCoupon({
                                      pharmacyId: "",
                                      pharmacyName:
                                        coupon?.pharmacy_display_name,
                                      drugName: coupon?.drug_name,
                                      ndc: coupon?.ndc,
                                      gpi14: coupon?.gpi14,
                                      genericName: coupon?.generic_name,
                                      brandName: coupon?.brand_name,
                                      ncpdpId: coupon?.ncpdp || ncpdpId,
                                      mscode: coupon?.mscode,
                                      bin: coupon?.bin,
                                      pcn: coupon?.pcn,
                                      group: coupon?.group,
                                      type: coupon?.form,
                                      strength: coupon?.dosage,
                                      quantity: coupon?.quantity,
                                      network_type: coupon?.network_type,
                                      discount_card_name:
                                        coupon?.discount_card_name,
                                      currency: "USD",
                                      amount: `${coupon?.claim_price}`,
                                      transactionId: transactionId,
                                      memberId: coupon.memberId,
                                      sourceId: sourceId,
                                      createdOn: formattedDate,
                                      clientId: clientId,
                                      packageSize: coupon?.packageSize || "",
                                      rowNumber: index + 1,
                                      waltz_memberid: memberId,
                                    });
                                  }}
                                  closeThisCard={() => {
                                    setOpen({
                                    navigation_ndc: 0,
                                    isOpen: false
                                  })
                                  }}
                                  primaryColor={
                                    config?.color_configurationConnection
                                      ?.primary_color?.hex
                                  }
                                  tertiaryColor={
                                    config?.color_configurationConnection
                                      ?.secondary_color?.hex
                                  }
                                  couponDisclaimer={
                                    config?.coupon_configurationConnection
                                      ?.coupon_card_disclaimer
                                  }
                                  networkType={coupon?.network_type}
                                  couponInstructions={
                                    config?.coupon_configurationConnection
                                      ?.coupon_header
                                  }
                                  cardEmail={coupon.cashCardEmailId}
                                  cardPhoneNumber={coupon.cashCardPhoneNumber}
                                  termsAndConditionsURL={
                                    coupon.cashCardTermsAndConditionsURL
                                  }
                                  smsDisclaimer={
                                    config?.coupon_configurationConnection?.sms?.disclaimer
                                  }
                                  emailDisclaimer={
                                    config?.coupon_configurationConnection?.email?.disclaimer
                                  }
                                  shareModalApi={config?.api_version_configurationConnection?.sms_api_version}
                                  isOpen={open?.isOpen}
                                />
                              )
                            );
                          })}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={0}
                          sx={{
                            display: { xs: "flex", md: "none" },
                            flexDirection: "column",
                          }}
                        >
                          {(sortOrder === "old"
                            ? freshCoupons.sort((a, b) =>
                                a.timeSaved > b.timeSaved ? 1 : -1
                              )
                            : sortOrder === "alphabetically"
                            ? freshCoupons.sort((a, b) =>
                                a.drug_name > b.drug_name ? 1 : -1
                              )
                            : sortOrder === "recent"
                            ? freshCoupons.sort((a, b) =>
                                a.timeSaved > b.timeSaved ? -1 : 1
                              )
                            : freshCoupons.sort((a, b) =>
                                a.timeSaved > b.timeSaved ? -1 : 1
                              )
                          )?.map((coupon, index) => {
                            return (
                              <CouponComponent
                                key={index}
                                claimPrice={coupon?.claim_price}
                                discountCardName={coupon?.discount_card_name}
                                memberId={coupon?.memberId}
                                bin={coupon?.bin}
                                pcn={coupon?.pcn}
                                group={coupon?.group}
                                navigationNdc={coupon?.navigation_ndc}
                                name={coupon?.drug_name}
                                genericName={coupon?.generic_name}
                                brandName={coupon?.brand_name}
                                quantityAndDose={`${coupon?.quantity} ${coupon?.form} | ${coupon?.dosage}`}
                                pharmacyDisplayName={
                                  coupon?.pharmacy_display_name
                                }
                                form={coupon?.form}
                                dosage={coupon?.dosage}
                                quantity={coupon?.quantity}
                                brandGeneric={coupon?.brand_generic}
                                deleteCoupon={deleteCoupon}
                                savedCoupons={freshCoupons}
                                open={
                                  (coupon?.navigation_ndc || coupon?.ndc) ===
                                    open?.navigation_ndc &&
                                  coupon?.quantity === open?.quantity
                                    ? true
                                    : false
                                }
                                setModal={setModal}
                                setModalOpen={setModalOpen}
                                openThisCard={() => {
                                  setOpen({
                                    navigation_ndc:
                                      coupon?.navigation_ndc || coupon?.ndc,
                                    quantity: coupon?.quantity,
                                  });
                                  trackCoupon({
                                    pharmacyId: "",
                                    pharmacyName: coupon?.pharmacy_display_name,
                                    drugName: coupon?.drug_name,
                                    ndc: coupon?.ndc,
                                    gpi14: coupon?.gpi14,
                                    genericName: coupon?.generic_name,
                                    brandName: coupon?.brand_name,
                                    ncpdpId: coupon?.ncpdp || ncpdpId,
                                    mscode: coupon?.mscode,
                                    bin: coupon?.bin,
                                    pcn: coupon?.pcn,
                                    group: coupon?.group,
                                    type: coupon?.form,
                                    strength: coupon?.dosage,
                                    quantity: coupon?.quantity,
                                    network_type: coupon?.network_type,
                                    discount_card_name:
                                      coupon?.discount_card_name,
                                    currency: "USD",
                                    amount: `${coupon?.claim_price}`,
                                    transactionId: transactionId,
                                    memberId: coupon.memberId,
                                    sourceId: sourceId,
                                    createdOn: formattedDate,
                                    clientId: clientId,
                                    packageSize: coupon?.packageSize || "",
                                    rowNumber: index + 1,
                                    waltz_memberid: memberId,
                                  });
                                }}
                                closeThisCard={() => {
                                  setOpen({
                                  navigation_ndc: 0,
                                  isOpen: false
                                })
                                }}
                                primaryColor={
                                  config?.color_configurationConnection
                                    ?.primary_color?.hex
                                }
                                tertiaryColor={
                                  config?.color_configurationConnection
                                    ?.secondary_color?.hex
                                }
                                couponDisclaimer={
                                  config?.coupon_configurationConnection
                                    ?.coupon_card_disclaimer
                                }
                                networkType={coupon?.network_type}
                                couponInstructions={
                                  config?.coupon_configurationConnection
                                    ?.coupon_header
                                }
                                cardEmail={coupon.cashCardEmailId}
                                cardPhoneNumber={coupon.cashCardPhoneNumber}
                                termsAndConditionsURL={
                                  coupon.cashCardTermsAndConditionsURL
                                }
                                smsDisclaimer={
                                  config?.coupon_configurationConnection?.sms?.disclaimer
                                }
                                emailDisclaimer={
                                  config?.coupon_configurationConnection?.email?.disclaimer
                                }
                                shareModalApi={config?.api_version_configurationConnection?.sms_api_version}
                                isOpen={open?.isOpen}
                              />
                            );
                          })}
                        </Grid>
                      </Fragment>
                    ) : loading === false && freshCoupons?.length === 0 ? (
                      <Grid item xs={12} sx={{ minHeight: "350px" }}>
                        <Typography
                          sx={{
                            mt: "2%",
                            fontSize: "1.5rem",
                            fontWeight: "400",
                          }}
                        >
                          You have not saved any searches yet. Start by
                          searching for a medication.
                        </Typography>
                      </Grid>
                    ) : (
                      <></>
                    )}

                    {/* survey goes here */}
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ margin: "2%" }}></Grid>
              </Grid>
              <SurveyButton
                setModal={setModal}
                setModalOpen={setModalOpen}
                config={config}
              />
            </Fragment>
          )}
        </Container>
      </Box>
    </Fragment>
  );
};

export default MySavings;
